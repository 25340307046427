import { useCallback } from 'react';

import type { Store } from '@hooks';

import { getStoreDetailsPayload } from './getStoreDetailsPayload';
import { trackEvent } from './trackEvent';
import { EventName } from './types';
import { useBreadcrumbPayload } from './useBreadcrumbPayload';
import { useGetBaseEventPayload } from './useGetBaseEventPayload';

export const useTrackSignUp = (store: Store) => {
  const breadcrumb = useBreadcrumbPayload();
  const getBaseEventPayload = useGetBaseEventPayload();

  return useCallback(
    async (distance?: number) => {
      const baseEventPayload = await getBaseEventPayload({
        element: 'button',
        component: 'Request a Quote',
        label: 'Request a Quote',
      });
      if (baseEventPayload) {
        trackEvent(EventName.SignUp, {
          ...breadcrumb,
          ...baseEventPayload,
          ...getStoreDetailsPayload(store, distance),
          'user type': 'BUYER',
        });
      }
    },
    [breadcrumb, getBaseEventPayload, store]
  );
};
