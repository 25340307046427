import { useEffect } from 'react';

import { ScreenName, trackMixpanelEvent } from '@packages/event-tracking';

import { InvoiceEventName, RejectInvoiceAttributes } from './types';
import { InvoiceDetailsVM } from '../useInvoiceDetails';

export const rejectInvoicePubSub = {
  publish: (screen: ScreenName) => {
    PubSub.publish(InvoiceEventName.RejectInvoice, screen);
  },
  subscribe: (fn: (data: ScreenName) => void) =>
    PubSub.subscribe(InvoiceEventName.RejectInvoice, (_, payload) => fn(payload)),
};

export const useTrackRejectInvoice = (invoice?: InvoiceDetailsVM) => {
  useEffect(() => {
    const rejectInvoiceUnsubscribeToken = rejectInvoicePubSub.subscribe((screen) => {
      const payload: RejectInvoiceAttributes = {
        module: 'Invoice Detail',
        isWeb: true,
        screen,
        component: 'Invoice',
        element: 'button',
        action: 'press',
        eventType: 'Reject invoice',

        invoiceId: invoice?.id!,
        buyerName: invoice?.buyer.displayName!,
        businessName: invoice?.storefront.businessName!,
        sellerName: invoice?.storefront.owner.displayName!,
        isDeposit: invoice?.isDeposit!,
        orderAmount: invoice?.amount!,
        additionalCosts: invoice?.additionalCost!,
      };
      trackMixpanelEvent('Invoice Details', payload);
    });

    return () => {
      PubSub.unsubscribe(rejectInvoiceUnsubscribeToken);
    };
  }, [invoice]);
};
