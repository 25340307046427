import { useEffect } from 'react';

import { ScreenName, trackMixpanelEvent } from '@packages/event-tracking';

import { DeleteCreditCardEventPayload, InvoiceEventName } from './types';

export const deleteCreditCardPubSub = {
  publish: (screen: ScreenName) => {
    const deleteCreditCardAttributes: DeleteCreditCardEventPayload = {
      action: 'press',
      component: 'Credit Card',
      element: 'button',
      eventType: 'Delete Credit Card',
      isWeb: true,
      module: 'Invoice Detail',
      screen,
    };
    PubSub.publish(InvoiceEventName.DeleteCreditCard, deleteCreditCardAttributes);
  },
  subscribe: (fn: (data: DeleteCreditCardEventPayload) => void) =>
    PubSub.subscribe(InvoiceEventName.DeleteCreditCard, (_, payload) => fn(payload)),
};

export const useTrackDeleteCreditCard = () => {
  useEffect(() => {
    const deleteCreditCardUnsubscribeToken = deleteCreditCardPubSub.subscribe((data) => {
      trackMixpanelEvent('Invoice Details', data);
    });

    return () => {
      PubSub.unsubscribe(deleteCreditCardUnsubscribeToken);
    };
  }, []);
};
