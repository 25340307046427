import { Col, Form, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';

import { UserInfoFormGroupVM } from './constants';
import { PhoneNumberField } from './PhoneNumberField';

export const UserInfoFormGroup = ({ noFieldDescription }: { noFieldDescription?: boolean }) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<{ user: UserInfoFormGroupVM }>();

  return (
    <>
      <Row className="gap-3 gap-sm-0">
        <Col xs={12} sm={6}>
          <Form.FloatingLabel label="First name">
            <Form.Control
              isInvalid={!!errors.user?.firstName}
              {...register('user.firstName')}
              placeholder="First name"
            />
            <Form.Control.Feedback type="invalid">
              {errors.user?.firstName?.message}
            </Form.Control.Feedback>
          </Form.FloatingLabel>
        </Col>
        <Col xs={12} sm={6}>
          <Form.FloatingLabel label="Last name">
            <Form.Control
              isInvalid={!!errors.user?.lastName}
              {...register('user.lastName')}
              placeholder="Last name"
            />
            <Form.Control.Feedback type="invalid">
              {errors.user?.lastName?.message}
            </Form.Control.Feedback>
          </Form.FloatingLabel>
        </Col>
      </Row>
      <Form.FloatingLabel label="Email address">
        <Form.Control
          {...register('user.email')}
          isInvalid={!!errors.user?.email}
          placeholder="Email address"
        />
        <Form.Control.Feedback type="invalid">{errors.user?.email?.message}</Form.Control.Feedback>
      </Form.FloatingLabel>
      <Row className="gap-3 gap-sm-0">
        <Col xs={12} sm={6}>
          <Form.FloatingLabel label="Mobile number">
            <PhoneNumberField
              control={control}
              name="user.phoneNumber"
              isInvalid={!!errors.user?.phoneNumber}
              label="Mobile number"
            />
            <Form.Control.Feedback type="invalid">
              {errors.user?.phoneNumber?.message}
            </Form.Control.Feedback>
            {noFieldDescription ? null : (
              <Form.Text className="fs-xs">For customer service only</Form.Text>
            )}
          </Form.FloatingLabel>
        </Col>
        <Col xs={12} sm={6}>
          <Form.FloatingLabel label="Zip code">
            <Controller
              control={control}
              name="user.zip"
              render={({ field: { onChange, name, value } }) => (
                <Form.Control
                  as={PatternFormat}
                  isInvalid={!!errors.user?.zip}
                  format="#####"
                  mask="_"
                  placeholder="Zip code"
                  onChange={onChange}
                  name={name}
                  value={value}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.user?.zip?.message}
            </Form.Control.Feedback>
            {noFieldDescription ? null : (
              <Form.Text className="fs-xs">For business recommendations only</Form.Text>
            )}
          </Form.FloatingLabel>
        </Col>
      </Row>
    </>
  );
};
