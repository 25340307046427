import { useEffect } from 'react';

import { ScreenName } from '@packages/event-tracking';

import { useCurrentScreen } from './useCurrentScreen';

export const useTrackCurrentScreen = (currentScreen: ScreenName) => {
  const { setScreen } = useCurrentScreen();
  useEffect(() => {
    setScreen(currentScreen);
  }, [currentScreen, setScreen]);
};
