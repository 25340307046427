import { create } from 'zustand';

import type { ScreenName } from '@packages/event-tracking/types';

// TODO: investigate session storage problem
// useSessionStorage<ScreenName | null>('previousScreen', null);
export const usePreviousScreen = create<{
  previousScreen?: ScreenName;
  setPreviousScreen: (screen?: ScreenName) => void;
}>((set) => ({
  previousScreen: undefined,
  setPreviousScreen: (screen) => set({ previousScreen: screen }),
}));
