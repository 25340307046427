import { ClientError, GraphQLClient } from 'graphql-request';

export const graphqlClient = new GraphQLClient(process.env.NEXT_PUBLIC_API_URL);

const originalRequest = graphqlClient.request;

graphqlClient.request = async (...args: any[]) => {
  try {
    // @ts-ignore
    return await originalRequest.apply(graphqlClient, args);
  } catch (e: any) {
    // our GraphQL api returns "errors" field with empty array.
    // `graphql-request` library see this as failed request and throws error
    // so we have to recover in this way
    if (e instanceof ClientError && e.response.errors?.length === 0) {
      return e.response.data;
    }

    throw e;
  }
};
