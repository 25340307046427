import { create } from 'zustand';

type InvoiceDetailsModalState = {
  opened?: boolean;
  invoiceHashId?: string;
  id?: number;
  open: (invoice: { id: number } | { invoiceHashId: string }) => void;
  close: () => void;
};

export const useInvoiceDetailsModal = create<InvoiceDetailsModalState>((set) => ({
  opened: false,
  id: undefined,
  invoiceHashId: undefined,
  open: (invoice: { id: number } | { invoiceHashId: string }) => set({ opened: true, ...invoice }),
  close: () => set({ opened: false, id: undefined, invoiceHashId: undefined }),
}));
