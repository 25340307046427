import { event } from 'nextjs-google-analytics';
export const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID;

// Mirror Meta for 1:1 comparisons
export const searchEvent = (options: { customProperties: unknown; search_string: string }) => {
  event('Search', options);
};

export const initiateCheckoutEvent = (options: { customProperties: unknown }) => {
  event('InitiateCheckout', options);
};

export const addToCartEvent = (options: {
  currency: 'USD';
  num_items: number;
  customProperties: object;
  value: number;
}) => {
  event('AddToCart', options);
};

export const purchaseEvent = (options: {
  currency: 'USD';
  value: number;
  num_items: number;
  customProperties: unknown;
}) => {
  event('Purchase', options);
};

export const contactEvent = (options = {}) => {
  event('Contact', options);
};
