import { ReactNode } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { OverlayChildren } from 'react-bootstrap/esm/Overlay';

import { Circle } from '@components/Circle';

import { useIsFullscreenMessagingAndInvoicing } from '../MessagingAndInvoicingContent/useIsFullscreenMessagingAndInvoicing';

type ConversationItemProps = {
  onClick: () => void;
  tooltip?: ReactNode;
  withBorder?: boolean;
  withNotificationBadge?: boolean;
  className?: string;
  activeNotificationBadgeColor: string;
  imageContainerSize?: number;
  color: string;
  image: ReactNode;
};
export const ConversationItem = ({
  withBorder,
  tooltip,
  onClick,
  withNotificationBadge,
  className,
  imageContainerSize = 56,
  image,
  activeNotificationBadgeColor,
  color,
}: ConversationItemProps) => {
  const isFullScreen = useIsFullscreenMessagingAndInvoicing();
  const renderTooltip: OverlayChildren = (props) =>
    !isFullScreen && tooltip ? (
      <Tooltip id="button-tooltip" className="fw-bolder" {...props}>
        {tooltip}
      </Tooltip>
    ) : (
      <></>
    );

  return (
    <OverlayTrigger placement="right" delay={{ show: 250, hide: 200 }} overlay={renderTooltip}>
      <div className="position-relative">
        <div
          style={{ transition: '.3s', width: imageContainerSize, height: imageContainerSize }}
          className={`d-flex align-items-center justify-content-center shadow rounded rounded-${
            isFullScreen ? 'circle' : '3'
          } border-2 border cursor-pointer bg-${color} ${
            withBorder ? `border-${color} ` : 'border-white'
          } overflow-hidden ${className ?? ''}`}
          onClick={onClick}
        >
          {image}
        </div>
        {withNotificationBadge ? (
          <Circle
            radius={9}
            className={`border border-2 border-white position-absolute bg-${activeNotificationBadgeColor}`}
            style={isFullScreen ? { left: 0, top: 0 } : { left: -9, top: -7 }}
          />
        ) : null}
      </div>
    </OverlayTrigger>
  );
};
