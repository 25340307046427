import Link from 'next/link';

import { isSuperCategory, useSuperCategories } from '@api';

export const CategoriesAllMenu = () => {
  const { data: superCategories } = useSuperCategories();

  return (
    <>
      {superCategories?.categoryTaxonomy?.map((category, index) =>
        isSuperCategory(category) && category.categoryChildren?.length ? (
          <div key={index}>
            <Link
              href={`/browse/${category.uriSlug}`}
              style={{ color: '#DDFF7B', textDecoration: 'none' }}
              className="display-6 d-block font-title my-2"
            >
              {category.title}
            </Link>
            <div className="ms-3 my-3">
              {category.categoryChildren.map((subCategory, subIndex) => (
                <Link
                  key={subIndex}
                  href={{
                    pathname: `/browse/category/${subCategory.uriSlug}`,
                    query: { parentCategory: category.taxonomyId },
                  }}
                  style={{ color: '#DDFF7B', textDecoration: 'none' }}
                  className="fs-lg d-block my-2"
                >
                  {subCategory.title}
                </Link>
              ))}
            </div>
          </div>
        ) : (
          <Link
            href={`/browse/category/${category.uriSlug}`}
            key={index}
            style={{ color: '#DDFF7B', textDecoration: 'none' }}
            className="display-6 d-block font-title my-2"
          >
            {category.title}
          </Link>
        )
      )}
    </>
  );
};
