import axios, { AxiosRequestHeaders } from 'axios';

import { auth } from '@hooks/useAuth';

export const makeGqlCall =
  <TRes, TVars = void>(query: string) =>
  async (variables: TVars, headers?: AxiosRequestHeaders) => {
    if (auth?.currentUser) {
      headers = {
        Authorization: `Bearer ${await auth.currentUser.getIdToken(true)}`,
        ...headers,
      };
    }

    const res = await axios.post<{ data: TRes }>(
      process.env.NEXT_PUBLIC_API_URL,
      { query, variables },
      {
        headers,
      }
    );

    return res.data.data;
  };
