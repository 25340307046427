import { Stack } from 'react-bootstrap';

import { Circle, StoreOwnerImage } from '@components';
import { truncateAndAddEllipsis } from '@utils/truncateAndAddEllipsis';

type ConversationListItemProps = {
  onClick: () => void;
  avatar: string;
  title: string;
  subtitle?: string;
  isActive: boolean;
};

export const ConversationListItem = ({
  onClick,
  avatar,
  title,
  subtitle,
  isActive,
}: ConversationListItemProps) => {
  return (
    <Stack gap={3} direction="horizontal" className="cursor-pointer" onClick={onClick}>
      <Circle
        radius={5}
        className={`${isActive ? 'bg-primary' : 'bg-light'} flex-shrink-0 border-white border`}
      />
      <StoreOwnerImage
        border={isActive ? 'primary' : 'secondary'}
        rounded={3}
        src={avatar}
        size={50}
        alt={title ?? 'storeowner image'}
      />
      <Stack direction="vertical" className="align-items-start">
        <h6 className="font-serif mb-0 text-dark fw-bolder text-break">
          {truncateAndAddEllipsis(title, 55)}
        </h6>
        <span className="fst-italic fw-bolder font-serif text-brownsugar">
          {truncateAndAddEllipsis(subtitle ?? '', 40)}
        </span>
      </Stack>
    </Stack>
  );
};
