import { create } from 'zustand';

import type { ScreenName } from '@packages/event-tracking';

export const useCurrentScreen = create<{
  screen?: ScreenName;
  setScreen: (screen?: ScreenName) => void;
}>((set) => ({
  screen: undefined,
  setScreen: (screen) => set({ screen: screen }),
}));
