import { makeGqlCall, UserType } from '@api';

const readConversationMessagesQuery = /* GraphQL */ `
  mutation ($conversation: ReadConversationMessageInput!, $appusertype: UserType!) {
    readConversationMessage(conversation: $conversation, appusertype: $appusertype) {
      unreadMessageCount
      countOfMessagesUpdated
      conversationId
      relatedConversationId
    }
  }
`;

type ReadConversationMessagesResponse = {
  readConversationMessage: {
    unreadMessageCount: number;
    countOfMessagesUpdated: number;
    conversationId: number;
    relatedConversationId?: number;
  };
};

type ReadConversationMessagesVariables = {
  conversation: {
    conversationId: number;
    updateAll: boolean;
    twilioMessageSid?: string;
    isUnread?: boolean;
  };
  appusertype: UserType;
};

export const readConversationMessages = makeGqlCall<
  ReadConversationMessagesResponse,
  ReadConversationMessagesVariables
>(readConversationMessagesQuery);
