import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date scalar type */
  Date: any;
  SiteMapBrowseType: any;
  SiteMapCatalogType: any;
  SiteMapCategoryType: any;
  SiteMapShopType: any;
  Upload: any;
  adminCategoryJSON: any;
  attributionJSON: any;
  cartOrderJSON: any;
  catalogItemRequestDetailJSON: any;
  catalogItemSellerInputJSON: any;
  catalogSortOrderJSON: any;
  catalogSortOrderJSONB: any;
  categoryJSON: any;
  contemporaryCatalogDetailJSON: any;
  fulfillmentJSON: any;
  fulfillmentMethodJSON: any;
  measuredStatistics: any;
  mediaSortOrderJSON: any;
  messageStatusByUserJSON: any;
  meta: any;
  payloadJSON: any;
  profileJSON: any;
  restrictUseJSON: any;
  sellerFulfillmentDetailJSON: any;
  twilioPayloadJSON: any;
  usageMessageJSON: any;
  visualTagsJSONB: any;
}

export interface AdminCsConversationList {
  __typename?: 'AdminCSConversationList';
  adminStatus: Maybe<ConversationAdminStatus>;
  conversationId: Scalars['Int'];
  created_at: Scalars['Date'];
  lastMessage: Maybe<ConversationMessage>;
  otherUser: ConversationUser;
  storefrontJSON: Maybe<ConversationListStorefront>;
  twilioConversationSid: Maybe<Scalars['String']>;
  unReadMessageCountByUser: Scalars['Int'];
}

export interface AdminCategory {
  __typename?: 'AdminCategory';
  active: Maybe<Scalars['Boolean']>;
  canStorefrontAssign: Scalars['Boolean'];
  description: Maybe<Scalars['String']>;
  icon: Scalars['String'];
  id: Scalars['Int'];
  isMarketing: Scalars['Boolean'];
  isSeasonal: Scalars['Boolean'];
  isVirtual: Scalars['Boolean'];
  rank: Scalars['Int'];
  resultTitle: Scalars['String'];
  seasonEndDate: Maybe<Scalars['Date']>;
  seasonStartDate: Maybe<Scalars['Date']>;
  title: Scalars['String'];
  uri: Scalars['String'];
}

export interface AdminConversationDetail {
  __typename?: 'AdminConversationDetail';
  conversationHeader: AdminConversationDetailHeader;
  conversationMessages: Maybe<Array<Maybe<AdminConversationDetailMessage>>>;
  conversationParticipants: AdminConversationParticipants;
}

export interface AdminConversationDetailHeader {
  __typename?: 'AdminConversationDetailHeader';
  conversationId: Scalars['Int'];
  created_at: Scalars['Date'];
  twilioConversationSid: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['Date']>;
}

export interface AdminConversationDetailMessage {
  __typename?: 'AdminConversationDetailMessage';
  adminStatus: Maybe<ConversationAdminStatus>;
  body: Maybe<Scalars['String']>;
  conversationMessageId: Scalars['Int'];
  created_at: Scalars['Date'];
  hasMedia: Scalars['Boolean'];
  messageIndex: Scalars['Int'];
  messageStatusByUserJSON: Maybe<Scalars['messageStatusByUserJSON']>;
  outReachType: Maybe<Scalars['Int']>;
  relatedTwilioMessageSid: Maybe<Scalars['String']>;
  sentByUserId: Scalars['ID'];
  twilioMessageSid: Scalars['String'];
  twilioPayload: Maybe<Scalars['twilioPayloadJSON']>;
}

export interface AdminConversationList {
  __typename?: 'AdminConversationList';
  adminHasConversationWithBuyer: Maybe<HasConversationWithAdmin>;
  adminHasConversationWithSeller: Maybe<HasConversationWithAdmin>;
  adminStatus: Maybe<ConversationAdminStatus>;
  buyerUser: User;
  conversationAdminUserStatistics: Maybe<AdminConversationStatistics>;
  conversationId: Scalars['Int'];
  created_at: Scalars['Date'];
  lastMessageBuyer: Maybe<AdminConversationDetailMessage>;
  lastMessageSeller: Maybe<AdminConversationDetailMessage>;
  lastOutReach: Maybe<AdminConversationDetailMessage>;
  outReachStatistics: Maybe<AdminOutReachStatistics>;
  sellerUser: ConversationUserWithStorefront;
  twilioConversationSid: Maybe<Scalars['String']>;
  updated_at: Scalars['Date'];
}

export interface AdminConversationListCommon {
  __typename?: 'AdminConversationListCommon';
  adminStatus: Maybe<ConversationAdminStatus>;
  conversationAdminUserStatistics: Maybe<AdminConversationStatistics>;
  conversationId: Scalars['Int'];
  created_at: Scalars['Date'];
  twilioConversationSid: Maybe<Scalars['String']>;
  updated_at: Scalars['Date'];
}

export enum AdminConversationOrderBy {
  Adminstatus = 'ADMINSTATUS',
  Lastmessagedate = 'LASTMESSAGEDATE',
  Lastoutreachdate = 'LASTOUTREACHDATE',
  Lastsellermessagedate = 'LASTSELLERMESSAGEDATE',
  Outreachstatisticstotal = 'OUTREACHSTATISTICSTOTAL'
}

export enum AdminConversationOrderByOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export interface AdminConversationParticipants {
  __typename?: 'AdminConversationParticipants';
  conversationOtherUser: UserAdmin;
  conversationOwnerUser: UserAdmin;
}

export interface AdminConversationStatistics {
  __typename?: 'AdminConversationStatistics';
  unreadMessageCount: Maybe<Scalars['Int']>;
}

export interface AdminConversationUpdateStatus {
  adminConversationStatus?: InputMaybe<ConversationAdminStatus>;
  conversationId: Scalars['Int'];
}

export interface AdminConversationUser {
  __typename?: 'AdminConversationUser';
  city: Maybe<Scalars['String']>;
  conversationUserType: UserType;
  created_at: Scalars['Date'];
  currentappviewtype: Maybe<UserType>;
  displayName: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  favorites: Maybe<Array<Maybe<Storefront>>>;
  firstName: Maybe<Scalars['String']>;
  isAnonymous: Scalars['Boolean'];
  isBlocked: Maybe<Scalars['Boolean']>;
  lastName: Maybe<Scalars['String']>;
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
  phoneNumber: Maybe<Scalars['String']>;
  photoURL: Maybe<Scalars['String']>;
  referralCode: Maybe<Scalars['String']>;
  settings: Settings;
  state: Maybe<Scalars['String']>;
  streetAddress: Maybe<Scalars['String']>;
  uid: Scalars['ID'];
  unReadMessageCountByUser: Maybe<Scalars['Int']>;
  zip: Maybe<Scalars['String']>;
}

export interface AdminDeleteUser {
  __typename?: 'AdminDeleteUser';
  deletedStorefrontId: Maybe<Scalars['Int']>;
  deletedUserId: Scalars['ID'];
}

export interface AdminDeleteUserInput {
  uid: Scalars['String'];
}

export interface AdminDiscountCode {
  __typename?: 'AdminDiscountCode';
  ActiveStatus: Scalars['String'];
  activeFrom: Scalars['Date'];
  activeTo: Maybe<Scalars['Date']>;
  adminNotes: Maybe<Scalars['String']>;
  code: Scalars['String'];
  created_at: Scalars['Date'];
  discountCodeId: Scalars['Int'];
  discountType: DiscountType;
  discountValue: Scalars['Float'];
  isActive: Scalars['Boolean'];
  isAdmin: Scalars['Boolean'];
  isReferral: Scalars['Boolean'];
  maximumDiscountAmount: Maybe<Scalars['Float']>;
  orderMinimumAmount: Scalars['Float'];
  prefix: Scalars['String'];
  restrictUseJSON: Maybe<Scalars['restrictUseJSON']>;
  updated_at: Scalars['Date'];
}

export interface AdminDiscountCodeInput {
  activeFrom: Scalars['Date'];
  activeTo?: InputMaybe<Scalars['Date']>;
  adminNotes?: InputMaybe<Scalars['String']>;
  discountType: DiscountType;
  discountValue: Scalars['Float'];
  isReferral?: InputMaybe<Scalars['Boolean']>;
  maximumDiscountAmount?: InputMaybe<Scalars['Float']>;
  orderMinimumAmount: Scalars['Float'];
  prefix: Scalars['String'];
  restrictUseJSON?: InputMaybe<Scalars['restrictUseJSON']>;
  usageMessageJSON?: InputMaybe<Scalars['usageMessageJSON']>;
}

export interface AdminDiscountCodeUpdateInput {
  activeFrom: Scalars['Date'];
  activeTo?: InputMaybe<Scalars['Date']>;
  adminNotes?: InputMaybe<Scalars['String']>;
  discountCodeId: Scalars['Int'];
}

export interface AdminNotificationCenter {
  __typename?: 'AdminNotificationCenter';
  cohort: Maybe<Scalars['String']>;
  countOfUsers: Scalars['Int'];
  created_at: Scalars['Date'];
  deletedAt: Maybe<Scalars['Date']>;
  expiry: Maybe<Scalars['Date']>;
  notificationId: Scalars['Int'];
  payload: Scalars['payloadJSON'];
  type: NotificationType;
  updated_at: Maybe<Scalars['Date']>;
  userType: Maybe<UserType>;
}

export interface AdminNotificationInput {
  cohortId?: InputMaybe<Scalars['Int']>;
  destination?: InputMaybe<Scalars['Int']>;
  payload: Scalars['payloadJSON'];
  type: NotificationType;
  userId?: InputMaybe<Scalars['String']>;
}

export interface AdminOutReachListBuyer {
  __typename?: 'AdminOutReachListBuyer';
  adminStatus: Maybe<ConversationAdminStatus>;
  conversationAdminUserStatistics: Maybe<AdminConversationStatistics>;
  conversationId: Scalars['Int'];
  conversationOtherUser: AdminConversationUser;
  created_at: Scalars['Date'];
  lastMessage: Maybe<AdminConversationDetailMessage>;
  lastMessageOtherUser: Maybe<AdminConversationDetailMessage>;
  otherUser: User;
  twilioConversationSid: Maybe<Scalars['String']>;
  updated_at: Scalars['Date'];
}

export interface AdminOutReachListSeller {
  __typename?: 'AdminOutReachListSeller';
  adminStatus: Maybe<ConversationAdminStatus>;
  conversationAdminUserStatistics: Maybe<AdminConversationStatistics>;
  conversationId: Scalars['Int'];
  conversationOtherUser: AdminConversationUser;
  created_at: Scalars['Date'];
  lastMessage: Maybe<AdminConversationDetailMessage>;
  lastMessageOtherUser: Maybe<AdminConversationDetailMessage>;
  otherUser: User;
  twilioConversationSid: Maybe<Scalars['String']>;
  updated_at: Scalars['Date'];
}

export interface AdminOutReachStatistics {
  __typename?: 'AdminOutReachStatistics';
  totalOutReachCount: Maybe<Scalars['Int']>;
}

export interface AdminStorefront {
  __typename?: 'AdminStorefront';
  address: Scalars['String'];
  adminCategoryJSON: Maybe<Scalars['adminCategoryJSON']>;
  approvalDate: Maybe<Scalars['Date']>;
  businessName: Scalars['String'];
  catalog: Maybe<Array<Maybe<Catalog>>>;
  catalogSortOrder: Maybe<Scalars['catalogSortOrderJSONB']>;
  catalogSummary: Maybe<Array<Maybe<CatalogSummary>>>;
  category: Maybe<Category>;
  categoryJSON: Maybe<Scalars['categoryJSON']>;
  chatrooms: Array<AdminStorefrontChatroom>;
  city: Maybe<Scalars['String']>;
  created_at: Scalars['Date'];
  description: Scalars['String'];
  detail: Maybe<Scalars['String']>;
  distance: Maybe<Scalars['Float']>;
  dynamicLink: Maybe<Scalars['String']>;
  endPauseDate: Maybe<Scalars['Date']>;
  fulfillmentJSON: Maybe<Scalars['fulfillmentJSON']>;
  geofenceRadius: Scalars['Float'];
  hasPaymentSetup: Scalars['Boolean'];
  id: Scalars['Int'];
  instaAccessToken: Maybe<Scalars['String']>;
  instaUserId: Maybe<Scalars['String']>;
  instagramPhotos: Array<Maybe<InstagramPhoto>>;
  isFavorite: Maybe<Scalars['Boolean']>;
  lastInstaTokenUpdate: Maybe<Scalars['Date']>;
  measuredStatistics: Maybe<Scalars['measuredStatistics']>;
  outsideGeofenceRadius: Maybe<Scalars['Boolean']>;
  owner: User;
  photos: StorefrontStorageInfo;
  priceHigh: Maybe<Scalars['Float']>;
  priceLow: Scalars['Float'];
  priceType: PriceType;
  startPauseDate: Maybe<Scalars['Date']>;
  state: Maybe<Scalars['String']>;
  statistics: StorefrontStatistics;
  status: StorefrontStatus;
  storefrontTags: Maybe<Array<Maybe<AdminStorefrontTag>>>;
  tagline: Scalars['String'];
  /** Whether the thank you notes for this storefront are enabled */
  thankYouNotesEnabled: Scalars['Boolean'];
  visualTagsJSON: Maybe<Scalars['visualTagsJSONB']>;
  zipCode: Scalars['String'];
}


export interface AdminStorefrontDistanceArgs {
  filter: InputMaybe<DistanceStorefrontFilter>;
}


export interface AdminStorefrontMeasuredStatisticsArgs {
  filter: InputMaybe<StatisticsFilter>;
}

export interface AdminStorefrontChatroom {
  __typename?: 'AdminStorefrontChatroom';
  id: Scalars['Int'];
  link: Maybe<Scalars['String']>;
  otherUser: UserAdmin;
}

export interface AdminStorefrontPrimaryImageInput {
  id: Scalars['Int'];
  primaryImage: Scalars['String'];
}

export interface AdminStorefrontTag {
  __typename?: 'AdminStorefrontTag';
  isAutoTag: Maybe<Scalars['Boolean']>;
  tag: Scalars['String'];
  tagId: Scalars['Int'];
}

export interface AdminTag {
  __typename?: 'AdminTag';
  categories: Maybe<Array<Maybe<AdminTagCategory>>>;
  storefronts: Maybe<Array<Maybe<AdminTagStorefront>>>;
  tag: Scalars['String'];
  tagId: Scalars['Int'];
}

export interface AdminTagCategory {
  __typename?: 'AdminTagCategory';
  categoryId: Scalars['Int'];
  categoryTitle: Scalars['String'];
}

export interface AdminTagStorefront {
  __typename?: 'AdminTagStorefront';
  businessName: Scalars['String'];
  isAutoTag: Maybe<Scalars['Boolean']>;
  storefrontId: Scalars['Int'];
}

export interface AdminTagSummary {
  __typename?: 'AdminTagSummary';
  categories: Maybe<Array<Maybe<AdminTagCategory>>>;
  countOfStorefronts: Scalars['Int'];
  tag: Scalars['String'];
  tagId: Scalars['Int'];
}

export interface ApplyDiscountCodetoInvoiceInput {
  discountCode: Scalars['String'];
  discountCodeId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
}

export interface BuyerOrder {
  __typename?: 'BuyerOrder';
  acceptedAt: Maybe<Scalars['Date']>;
  additionalNotes: Maybe<Scalars['String']>;
  buyer: User;
  buyerOrderId: Scalars['Int'];
  created_at: Scalars['Date'];
  declinedAt: Maybe<Scalars['Date']>;
  deletedAt: Maybe<Scalars['Date']>;
  fulfillmentMethod: FulfillmentMethod;
  neededBy: Maybe<Scalars['String']>;
  productJSON: Array<Maybe<BuyerOrderProductJson>>;
  seller: User;
  status: BuyerOrderStatus;
  storefront: Storefront;
  submittedAt: Maybe<Scalars['Date']>;
  updated_at: Scalars['Date'];
}

export interface BuyerOrderProductJson {
  __typename?: 'BuyerOrderProductJSON';
  comments: Maybe<Scalars['String']>;
  fulfillmentMethod: FulfillmentMethod;
  name: Scalars['String'];
  price: Scalars['Float'];
  priceType: CatalogPriceType;
  productId: Scalars['Int'];
  productMedia: Maybe<Array<Maybe<Media>>>;
  quantity: Scalars['Int'];
}

export enum BuyerOrderStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Saved = 'SAVED',
  Submitted = 'SUBMITTED'
}

export enum CartOrderFlowStatus {
  AwaitingBuyerRequest = 'AWAITING_BUYER_REQUEST',
  Declined = 'DECLINED',
  Fulfilled = 'FULFILLED',
  Invoiced = 'INVOICED',
  Paid = 'PAID',
  Requested = 'REQUESTED'
}

export interface CartOrderFulfillmentInput {
  cartorderId: Scalars['Int'];
  fulfillmentDate: Scalars['Date'];
  fulfillmentMethod?: InputMaybe<FulfillmentMethod>;
  fulfillmentTracking?: InputMaybe<Scalars['String']>;
}

export interface CartOrderHeader {
  __typename?: 'CartOrderHeader';
  cartOrderId: Scalars['Int'];
  created_at: Scalars['Date'];
  displayCartOrderId: Scalars['Int'];
  isRequested: Scalars['Boolean'];
  updated_at: Maybe<Scalars['Date']>;
}

export interface CartOrderItem {
  __typename?: 'CartOrderItem';
  cartOrderItemId: Scalars['Int'];
  cartOrderStorefrontId: Scalars['Int'];
  catalogItemRequestDetailJSON: Scalars['catalogItemRequestDetailJSON'];
  catalogItemSellerInputJSON: Maybe<Scalars['catalogItemSellerInputJSON']>;
  contemporaryCatalogDetailJSON: Scalars['contemporaryCatalogDetailJSON'];
  created_at: Scalars['Date'];
  sellerFulfillmentDetail: Maybe<SellerFulfillmentDetail>;
  sellerFulfillmentDetailJSON: Maybe<Scalars['sellerFulfillmentDetailJSON']>;
  updated_at: Maybe<Scalars['Date']>;
}

export interface CartOrderItemRequestInput {
  additionalRequest?: InputMaybe<Scalars['String']>;
  catalogId: Scalars['Int'];
  fulfillmentLocation?: InputMaybe<Scalars['String']>;
  fulfillmentMethod?: InputMaybe<FulfillmentMethod>;
  quantity: Scalars['Int'];
  timeframe?: InputMaybe<Scalars['Date']>;
}

export interface CartOrderStorefrontInput {
  cartItems: Array<CartOrderItemRequestInput>;
  storefrontId: Scalars['Int'];
}

export interface Catalog {
  __typename?: 'Catalog';
  allowSelectQuantity: Scalars['Boolean'];
  catalogId: Scalars['Int'];
  created_at: Scalars['Date'];
  deletedAt: Maybe<Scalars['Date']>;
  description: Scalars['String'];
  fulfillmentMethodJSON: Maybe<Scalars['fulfillmentMethodJSON']>;
  media: Maybe<Array<Maybe<Media>>>;
  mediaSortOrder: Maybe<Scalars['mediaSortOrderJSON']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  priceType: CatalogPriceType;
  pricingDescription: Maybe<Scalars['String']>;
  status: CatalogStatus;
  storefrontId: Scalars['Int'];
  updated_at: Scalars['Date'];
}

export interface CatalogMediaInput {
  catalogId: Scalars['Int'];
  mediaId: Scalars['Int'];
  status: MediaStatus;
  storefrontId: Scalars['Int'];
}

export enum CatalogPriceType {
  Fixed = 'FIXED',
  Hourly = 'HOURLY'
}

export interface CatalogSortOrderInput {
  catalogSortOrder: Scalars['catalogSortOrderJSON'];
}

export enum CatalogStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Inactive = 'INACTIVE',
  Saved = 'SAVED'
}

export interface CatalogSummary {
  __typename?: 'CatalogSummary';
  catalogStatus: CatalogStatus;
  count: Scalars['Int'];
}

export interface Category {
  __typename?: 'Category';
  active: Maybe<Scalars['Boolean']>;
  canStorefrontAssign: Scalars['Boolean'];
  description: Maybe<Scalars['String']>;
  icon: Scalars['String'];
  id: Scalars['Int'];
  isVirtual: Scalars['Boolean'];
  rank: Scalars['Int'];
  resultTitle: Scalars['String'];
  storefronts: Array<Storefront>;
  title: Scalars['String'];
  uri: Scalars['String'];
}

export interface CategoryTaxonomy {
  __typename?: 'CategoryTaxonomy';
  categoryChildren: Maybe<Array<Maybe<CategoryTaxonomyChildren>>>;
  categoryId: Maybe<Scalars['Int']>;
  description: Scalars['String'];
  family: Scalars['String'];
  icon: Maybe<Scalars['String']>;
  image: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  level: Scalars['Int'];
  longDescription: Scalars['String'];
  meta: Maybe<Scalars['meta']>;
  metaTags: Scalars['String'];
  taxonomyId: Scalars['Int'];
  title: Scalars['String'];
  uriSlug: Scalars['String'];
}

export interface CategoryTaxonomyCategoryDetail {
  __typename?: 'CategoryTaxonomyCategoryDetail';
  id: Scalars['Int'];
  title: Scalars['String'];
  uriSlug: Scalars['String'];
}

export interface CategoryTaxonomyChildren {
  __typename?: 'CategoryTaxonomyChildren';
  icon: Scalars['String'];
  id: Scalars['Int'];
  meta: Maybe<Scalars['meta']>;
  title: Scalars['String'];
  uriSlug: Scalars['String'];
}

export interface ChatRoom {
  __typename?: 'ChatRoom';
  firstDisplayName: Scalars['String'];
  firstUser: User;
  id: Scalars['Int'];
  secondDisplayName: Scalars['String'];
  secondUser: User;
}

export interface Cohort {
  __typename?: 'Cohort';
  cohort: Scalars['String'];
  cohortDescription: Scalars['String'];
  cohortId: Scalars['Int'];
  created_at: Scalars['Date'];
  userType: Maybe<UserType>;
}

export interface Comment {
  __typename?: 'Comment';
  body: Scalars['String'];
  conversation: Maybe<CommentConversation>;
  created_at: Scalars['Date'];
  didIReact: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  order: Maybe<CommentOrder>;
  orderId: Maybe<Scalars['Int']>;
  parent: Maybe<Comment>;
  rating: Maybe<Scalars['Float']>;
  reactions: Maybe<Scalars['Int']>;
  sellerResponse: Maybe<Scalars['String']>;
  storefront: Storefront;
  updated_at: Scalars['Date'];
  user: User;
}


export interface CommentDidIReactArgs {
  filter: InputMaybe<ReactionsFilter>;
}


export interface CommentReactionsArgs {
  filter: InputMaybe<ReactionsFilter>;
}

export interface CommentConversation {
  __typename?: 'CommentConversation';
  conversationId: Maybe<Scalars['Int']>;
}

export interface CommentOrder {
  __typename?: 'CommentOrder';
  serviceName: Maybe<Scalars['String']>;
}

export interface CompleteOrderInput {
  additionalCost?: InputMaybe<Scalars['Float']>;
  amount?: InputMaybe<Scalars['Float']>;
  extraUnits?: InputMaybe<Scalars['Float']>;
  id: Scalars['Int'];
  note?: InputMaybe<Scalars['String']>;
  pricingDetail?: InputMaybe<Scalars['String']>;
  serviceName: Scalars['String'];
}

export interface Contact {
  __typename?: 'Contact';
  email: Scalars['String'];
  telephone: Scalars['String'];
}

export enum ConversationAdminStatus {
  Closed = 'CLOSED',
  Open = 'OPEN'
}

export interface ConversationCreated {
  __typename?: 'ConversationCreated';
  conversationId: Scalars['Int'];
  relatedConversationId: Maybe<Scalars['Int']>;
}

export interface ConversationList {
  __typename?: 'ConversationList';
  conversationId: Scalars['Int'];
  created_at: Scalars['Date'];
  lastMessage: Maybe<ConversationMessage>;
  otherUser: ConversationUser;
  storefrontJSON: Maybe<ConversationListStorefront>;
  twilioConversationSid: Maybe<Scalars['String']>;
  unReadMessageCountByUser: Scalars['Int'];
}

export interface ConversationListStorefront {
  __typename?: 'ConversationListStorefront';
  businessName: Scalars['String'];
  id: Scalars['Int'];
  uriSlug: Scalars['String'];
}

export interface ConversationMessage {
  __typename?: 'ConversationMessage';
  body: Maybe<Scalars['String']>;
  created_at: Scalars['Date'];
  hasMedia: Scalars['Boolean'];
  messageIndex: Scalars['Int'];
  sentByUserId: Scalars['ID'];
  twilioMessageSid: Scalars['String'];
}

export enum ConversationType {
  Adminbuyer = 'ADMINBUYER',
  Adminseller = 'ADMINSELLER',
  Buyerbuyer = 'BUYERBUYER',
  Buyerseller = 'BUYERSELLER',
  Sellerbuyer = 'SELLERBUYER',
  Sellerseller = 'SELLERSELLER'
}

export interface ConversationUpdated {
  __typename?: 'ConversationUpdated';
  conversationId: Scalars['Int'];
  countOfMessagesUpdated: Scalars['Int'];
  relatedConversationId: Maybe<Scalars['Int']>;
  unreadMessageCount: Scalars['Int'];
}

export interface ConversationUser {
  __typename?: 'ConversationUser';
  city: Maybe<Scalars['String']>;
  conversationUserType: Scalars['String'];
  created_at: Scalars['Date'];
  currentappviewtype: Maybe<UserType>;
  displayName: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  favorites: Maybe<Array<Maybe<Storefront>>>;
  firstName: Maybe<Scalars['String']>;
  isAnonymous: Scalars['Boolean'];
  isBlocked: Maybe<Scalars['Boolean']>;
  lastName: Maybe<Scalars['String']>;
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
  phoneNumber: Maybe<Scalars['String']>;
  photoURL: Maybe<Scalars['String']>;
  referralCode: Maybe<Scalars['String']>;
  settings: Settings;
  state: Maybe<Scalars['String']>;
  streetAddress: Maybe<Scalars['String']>;
  uid: Scalars['ID'];
  zip: Maybe<Scalars['String']>;
}

export interface ConversationUserWithStorefront {
  __typename?: 'ConversationUserWithStorefront';
  city: Maybe<Scalars['String']>;
  conversationUserType: Scalars['String'];
  created_at: Scalars['Date'];
  currentappviewtype: Maybe<UserType>;
  displayName: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  favorites: Maybe<Array<Maybe<Storefront>>>;
  firstName: Maybe<Scalars['String']>;
  isAnonymous: Scalars['Boolean'];
  isBlocked: Maybe<Scalars['Boolean']>;
  lastName: Maybe<Scalars['String']>;
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
  phoneNumber: Maybe<Scalars['String']>;
  photoURL: Maybe<Scalars['String']>;
  referralCode: Maybe<Scalars['String']>;
  settings: Settings;
  state: Maybe<Scalars['String']>;
  storefront: Maybe<Storefront>;
  streetAddress: Maybe<Scalars['String']>;
  uid: Scalars['ID'];
  zip: Maybe<Scalars['String']>;
}

export interface Create1to1ConversationInput {
  conversationOtherUserId: Scalars['ID'];
  conversationType: ConversationType;
  newMessage?: InputMaybe<Scalars['String']>;
  relatedMessage?: InputMaybe<Scalars['String']>;
  withinContext: MessageWithinContext;
}

export interface CreateAndReviseOrderInput {
  additionalCost?: InputMaybe<Scalars['Float']>;
  amount: Scalars['Float'];
  buyerId: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  pricingDetail?: InputMaybe<Scalars['String']>;
  selectedDate: Scalars['Date'];
  /** Hours, Products or none */
  units?: InputMaybe<Scalars['Int']>;
}

export interface CreateCartOrderRequestInput {
  emailMarketingOptIn: Scalars['Boolean'];
  storefronts: Array<CartOrderStorefrontInput>;
}

export interface CreateCategoryInput {
  description?: InputMaybe<Scalars['String']>;
  icon: Scalars['String'];
  isVirtual: Scalars['Boolean'];
  rank: Scalars['Int'];
  title: Scalars['String'];
}

export interface CreateChatRoomInput {
  firstUserType?: InputMaybe<UserType>;
  secondUserId: Scalars['ID'];
  secondUserType?: InputMaybe<UserType>;
  withinContext?: InputMaybe<MessageWithinContext>;
}

export interface CreateCommentInput {
  body: Scalars['String'];
  parentId?: InputMaybe<Scalars['Int']>;
  storefrontId: Scalars['Int'];
}

/** https://projects.invisionapp.com/d/main#/console/16585436/348681909/preview#project_console */
export interface CreateOrderInput {
  amount?: InputMaybe<Scalars['Float']>;
  note?: InputMaybe<Scalars['String']>;
  preferredDateEnd: Scalars['Date'];
  preferredDateStart: Scalars['Date'];
  storefrontId: Scalars['Int'];
}

export interface CreateOrderInputV2 {
  amount?: InputMaybe<Scalars['Float']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  note?: InputMaybe<Scalars['String']>;
  preferredDateEnd: Scalars['Date'];
  preferredDateStart: Scalars['Date'];
  storefrontId: Scalars['Int'];
}

export interface CreateReviewInput {
  body: Scalars['String'];
  orderId?: InputMaybe<Scalars['Int']>;
  rating: Scalars['Float'];
  storefrontId: Scalars['Int'];
}

export interface CreateReviewResponseInput {
  commentId: Scalars['Int'];
  sellerResponse: Scalars['String'];
}

export interface CreateStorefrontInput {
  address: Scalars['String'];
  businessName: Scalars['String'];
  categoryId?: InputMaybe<Scalars['Int']>;
  categoryJSON?: InputMaybe<Scalars['categoryJSON']>;
  city: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  detail?: InputMaybe<Scalars['String']>;
  dynamicLink?: InputMaybe<Scalars['String']>;
  fulfillmentJSON?: InputMaybe<Scalars['fulfillmentJSON']>;
  geofenceRadius?: InputMaybe<Scalars['Float']>;
  priceHigh?: InputMaybe<Scalars['Float']>;
  priceLow: Scalars['Float'];
  priceType: PriceType;
  state?: InputMaybe<Scalars['String']>;
  tagline: Scalars['String'];
  zipCode: Scalars['String'];
}

export interface CreateStorefrontInputV2 {
  address: Scalars['String'];
  businessName: Scalars['String'];
  categoryId?: InputMaybe<Scalars['Int']>;
  categoryJSON?: InputMaybe<Scalars['categoryJSON']>;
  city: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  detail?: InputMaybe<Scalars['String']>;
  dynamicLink?: InputMaybe<Scalars['String']>;
  fulfillmentJSON?: InputMaybe<Scalars['fulfillmentJSON']>;
  geofenceRadius?: InputMaybe<Scalars['Float']>;
  instaAccessToken?: InputMaybe<Scalars['String']>;
  instaUserId?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  priceHigh?: InputMaybe<Scalars['Float']>;
  priceLow: Scalars['Float'];
  priceType: PriceType;
  /** Uri or file name */
  primaryImage?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  tagline: Scalars['String'];
  uploadPhotos?: InputMaybe<Array<Scalars['Upload']>>;
  zipCode: Scalars['String'];
}

export interface CreateTagInput {
  category?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  tag: Scalars['String'];
}

export interface CreateUserInput {
  city?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  photo?: InputMaybe<Scalars['Upload']>;
  streetAddress?: InputMaybe<Scalars['String']>;
  type: UserType;
  welcomeEmail?: InputMaybe<Scalars['Boolean']>;
  zip: Scalars['String'];
}

export interface CreatedCartOrderDetail {
  __typename?: 'CreatedCartOrderDetail';
  buyer: User;
  orderDetail: Array<CreatedCartOrderStorefront>;
  orderHeader: CartOrderHeader;
}

export interface CreatedCartOrderStorefront {
  __typename?: 'CreatedCartOrderStorefront';
  cartOrderFlowStatus: CartOrderFlowStatus;
  cartOrderItems: Array<CartOrderItem>;
  cartOrderSequence: Scalars['String'];
  sellerOrderId: Maybe<Scalars['Int']>;
  storefront: Storefront;
}

export interface CreditCardInfo {
  __typename?: 'CreditCardInfo';
  brand: Scalars['String'];
  expiry: Scalars['String'];
  name: Scalars['String'];
  number: Scalars['String'];
}

export interface CurrentStorefront {
  __typename?: 'CurrentStorefront';
  adminCategoryJSON: Maybe<Scalars['adminCategoryJSON']>;
  approvalDate: Maybe<Scalars['Date']>;
  balance: StorefrontBalance;
  businessName: Scalars['String'];
  catalog: Maybe<Array<Maybe<Catalog>>>;
  catalogSortOrder: Maybe<Scalars['catalogSortOrderJSONB']>;
  catalogSummary: Maybe<Array<Maybe<CatalogSummary>>>;
  category: Maybe<Category>;
  categoryJSON: Maybe<Scalars['categoryJSON']>;
  city: Maybe<Scalars['String']>;
  created_at: Scalars['Date'];
  description: Scalars['String'];
  detail: Maybe<Scalars['String']>;
  distance: Maybe<Scalars['Float']>;
  dynamicLink: Maybe<Scalars['String']>;
  fulfillmentJSON: Maybe<Scalars['fulfillmentJSON']>;
  geofenceRadius: Scalars['Float'];
  hasPaymentSetup: Scalars['Boolean'];
  id: Scalars['Int'];
  instaAccessToken: Maybe<Scalars['String']>;
  instaUserId: Maybe<Scalars['String']>;
  instagramPhotos: Array<Maybe<InstagramPhoto>>;
  isFavorite: Maybe<Scalars['Boolean']>;
  measuredStatistics: Maybe<Scalars['measuredStatistics']>;
  outsideGeofenceRadius: Maybe<Scalars['Boolean']>;
  owner: User;
  photos: StorefrontStorageInfo;
  priceHigh: Maybe<Scalars['Float']>;
  priceLow: Scalars['Float'];
  priceType: PriceType;
  state: Maybe<Scalars['String']>;
  statistics: StorefrontStatistics;
  status: StorefrontStatus;
  stripeStatus: StripeStatus;
  tagline: Scalars['String'];
  /** Whether the thank you notes for this storefront are enabled */
  thankYouNotesEnabled: Scalars['Boolean'];
  visualTagsJSON: Maybe<Scalars['visualTagsJSONB']>;
}


export interface CurrentStorefrontDistanceArgs {
  filter: InputMaybe<DistanceStorefrontFilter>;
}


export interface CurrentStorefrontMeasuredStatisticsArgs {
  filter: InputMaybe<StatisticsFilter>;
}

export interface CurrentUser {
  __typename?: 'CurrentUser';
  city: Maybe<Scalars['String']>;
  created_at: Scalars['Date'];
  currentappviewtype: Maybe<UserType>;
  displayName: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  favorites: Maybe<Array<Maybe<Storefront>>>;
  firstName: Maybe<Scalars['String']>;
  isAnonymous: Scalars['Boolean'];
  isBlocked: Maybe<Scalars['Boolean']>;
  isRegistrationComplete: Maybe<Scalars['Boolean']>;
  lastName: Maybe<Scalars['String']>;
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
  phoneNumber: Maybe<Scalars['String']>;
  photoURL: Maybe<Scalars['String']>;
  profileJSON: Scalars['profileJSON'];
  referralCode: Maybe<Scalars['String']>;
  settings: Settings;
  state: Maybe<Scalars['String']>;
  storefront: Maybe<CurrentStorefront>;
  streetAddress: Maybe<Scalars['String']>;
  type: UserType;
  uid: Scalars['ID'];
  zip: Maybe<Scalars['String']>;
}

export interface DashboardStat {
  __typename?: 'DashboardStat';
  icon: Scalars['String'];
  title: Scalars['String'];
  type: DashboardStatType;
  value: Scalars['Float'];
}

export enum DashboardStatType {
  Currency = 'CURRENCY',
  Numeric = 'NUMERIC'
}

export interface DiscountCodeRemoved {
  __typename?: 'DiscountCodeRemoved';
  removed: Scalars['Boolean'];
}

export enum DiscountType {
  Fixed = 'FIXED',
  Percent = 'PERCENT'
}

export interface DistanceFilterInput {
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
}

export interface DistanceStorefrontFilter {
  geofence?: InputMaybe<Scalars['Boolean']>;
  geofenceRadius?: InputMaybe<Scalars['Float']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
}

export enum FeeType {
  FlatBuyer = 'FLAT_BUYER',
  FlatSeller = 'FLAT_SELLER',
  PercentBuyer = 'PERCENT_BUYER',
  PercentSeller = 'PERCENT_SELLER',
  Subscription = 'SUBSCRIPTION'
}

export interface FilterAdminConversationListInput {
  adminStatus?: InputMaybe<ConversationAdminStatus>;
  conversationId?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AdminConversationOrderBy>;
  orderByOrder?: InputMaybe<AdminConversationOrderByOrder>;
  userId?: InputMaybe<Scalars['ID']>;
  userType?: InputMaybe<UserType>;
}

export interface FilterAdminDiscountCodeInput {
  code?: InputMaybe<Scalars['String']>;
  discountCodeId?: InputMaybe<Scalars['Int']>;
  discountType?: InputMaybe<DiscountType>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  isReferral?: InputMaybe<Scalars['Boolean']>;
  prefix?: InputMaybe<Scalars['String']>;
}

export interface FilterAdminOrderInput {
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<OrderStatus>;
}

export interface FilterAdminStorefrontInput {
  Storefront?: InputMaybe<StorefrontIdAdminFilter>;
  businessName?: InputMaybe<Scalars['String']>;
  businessNameOrStorefrontOwnerName?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['Int']>;
  date?: InputMaybe<Sorting>;
  hasPaymentSetup?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  priceType?: InputMaybe<PriceType>;
  status?: InputMaybe<StorefrontStatus>;
}

export interface FilterCatalogInput {
  catalogId?: InputMaybe<Scalars['Int']>;
  storefrontId?: InputMaybe<Scalars['Int']>;
}

export interface FilterChatRoomInput {
  id?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
}

export interface FilterCommentInput {
  id?: InputMaybe<Scalars['Int']>;
  storefrontId?: InputMaybe<Scalars['Int']>;
}

export interface FilterConversationListInput {
  conversationId?: InputMaybe<Scalars['Int']>;
  conversationUserType: UserType;
  otherUserId?: InputMaybe<Scalars['ID']>;
}

export interface FilterNotificationCenterInput {
  appviewtype?: InputMaybe<UserType>;
}

export interface FilterOrderInput {
  id?: InputMaybe<Scalars['Int']>;
  invoiceHashId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<FilterOrderStatus>;
}

export enum FilterOrderStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Requested = 'REQUESTED'
}

export interface FilterOrderV2Input {
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<FilterOrderV2Status>;
}

export enum FilterOrderV2Status {
  Open = 'OPEN',
  Paid = 'PAID'
}

export interface FilterOrderV3Input {
  dueOver?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  invoiceHashId?: InputMaybe<Scalars['String']>;
  isNotReviewed?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<FilterOrderV2Status>;
  storefrontId?: InputMaybe<Scalars['Int']>;
}

export interface FilterStorefrontInput {
  categoryId?: InputMaybe<Scalars['Int']>;
  distance?: InputMaybe<DistanceStorefrontFilter>;
  favorite?: InputMaybe<Scalars['Boolean']>;
  fulfillmentMethod?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id?: InputMaybe<Scalars['Int']>;
  priceHigh?: InputMaybe<Scalars['Float']>;
  priceLow?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<StorefrontSort>;
  userId?: InputMaybe<Scalars['ID']>;
}

export interface FilterUserAdminInput {
  role?: InputMaybe<UserType>;
  search?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['ID']>;
}

export interface FilterUserInput {
  email?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<UserType>;
  uid?: InputMaybe<Scalars['ID']>;
}

export enum FulfillmentMethod {
  Local = 'LOCAL',
  Onsite = 'ONSITE',
  Pickup = 'PICKUP',
  Shipping = 'SHIPPING',
  Virtual = 'VIRTUAL'
}

/**
 * input CreateOrderInputV3 {
 * storefrontId: Int!
 * latitude: Float
 * longitude: Float
 * preferredDateStart: Date!
 * preferredDateEnd: Date!
 * note: String
 * amount: Float!
 * marketplaceCommission: Float!
 * buyerPaysMarketplaceCommission: Boolean!
 * paymentProcessingFee: Float!
 * }
 */
export interface GetInTouchInput {
  deliveryMethod?: InputMaybe<Scalars['String']>;
  isQuickShop?: InputMaybe<Scalars['Boolean']>;
  note: Scalars['String'];
  storefrontId: Scalars['Int'];
  timeframe?: InputMaybe<Scalars['String']>;
}

export enum GetInTouchVersion {
  V1 = 'V1',
  V2 = 'V2',
  V3 = 'V3'
}

export interface GlobalNotificationWidget {
  __typename?: 'GlobalNotificationWidget';
  created_at: Scalars['Date'];
  expiry: Maybe<Scalars['Date']>;
  notificationId: Scalars['Int'];
  payload: Maybe<Scalars['payloadJSON']>;
  type: NotificationType;
  userType: Maybe<UserType>;
}

export enum GroupNotificationFilter {
  All = 'ALL',
  AllBuyers = 'ALL_BUYERS',
  AllSellers = 'ALL_SELLERS'
}

export interface HasConversationWithAdmin {
  __typename?: 'HasConversationWithAdmin';
  conversationId: Maybe<Scalars['Int']>;
}

export interface InitiateOrderInput {
  additionalCost?: InputMaybe<Scalars['Float']>;
  amount: Scalars['Float'];
  buyerId: Scalars['String'];
  buyerPaysMarketplaceCommission?: InputMaybe<Scalars['Boolean']>;
  buyerPaysProcessingFee?: InputMaybe<Scalars['Boolean']>;
  cartOrderId?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  isDeposit?: InputMaybe<Scalars['Boolean']>;
  marketplaceCommission?: InputMaybe<Scalars['Float']>;
  note?: InputMaybe<Scalars['String']>;
  paymentProcessingFee?: InputMaybe<Scalars['Float']>;
  pricingDetail?: InputMaybe<Scalars['String']>;
  salesTax?: InputMaybe<Scalars['Float']>;
  selectedDate: Scalars['Date'];
  serviceName: Scalars['String'];
  totalToBePaid?: InputMaybe<Scalars['Float']>;
}

export interface InitiateOutsideOrderInput {
  additionalCost?: InputMaybe<Scalars['Float']>;
  amount: Scalars['Float'];
  isDeposit?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['String']>;
  outsideUserJSON?: InputMaybe<OutsideOrderUserInput>;
  pricingDetail?: InputMaybe<Scalars['String']>;
  salesTax?: InputMaybe<Scalars['Float']>;
  selectedDate: Scalars['Date'];
  serviceName: Scalars['String'];
}

export interface InstagramPhoto {
  __typename?: 'InstagramPhoto';
  caption: Maybe<Scalars['String']>;
  id: Scalars['String'];
  mediaUrl: Scalars['String'];
}

export interface IsBlocked {
  __typename?: 'IsBlocked';
  byUserId: Maybe<Scalars['ID']>;
  isBlocked: Scalars['Boolean'];
}

export interface Media {
  __typename?: 'Media';
  accessToken: Maybe<Scalars['String']>;
  caption: Maybe<Scalars['String']>;
  catalogCardURL: Maybe<Scalars['String']>;
  catalogId: Maybe<Scalars['Int']>;
  catalogThumbnailURL: Maybe<Scalars['String']>;
  cloudfrontURL: Maybe<Scalars['String']>;
  contentType: Scalars['String'];
  created_at: Scalars['Date'];
  deletedAt: Maybe<Scalars['Date']>;
  entityType: MediaEntityType;
  fileName: Maybe<Scalars['String']>;
  heroURL: Maybe<Scalars['String']>;
  lightboxZoomURL: Maybe<Scalars['String']>;
  mediaId: Scalars['Int'];
  mediaURL: Scalars['String'];
  provider: Scalars['String'];
  providerBucket: Scalars['String'];
  size: Scalars['Int'];
  status: MediaStatus;
  storefrontCardURL: Maybe<Scalars['String']>;
  storefrontId: Scalars['Int'];
  tags: Maybe<Scalars['String']>;
  updated_at: Scalars['Date'];
}

export enum MediaEntityType {
  Catalog = 'CATALOG',
  Storefront = 'STOREFRONT'
}

export enum MediaStatus {
  Active = 'ACTIVE',
  AwaitingUpload = 'AWAITING_UPLOAD',
  Deleted = 'DELETED',
  Inactive = 'INACTIVE',
  NotApproved = 'NOT_APPROVED'
}

export enum MessageWithinContext {
  Cartorder = 'CARTORDER',
  Csoutreach = 'CSOUTREACH',
  GetInTouch = 'GET_IN_TOUCH',
  NewMessage = 'NEW_MESSAGE',
  Obi = 'OBI',
  QuickshopRequest = 'QUICKSHOP_REQUEST',
  Userid = 'USERID'
}

export interface Mutation {
  __typename?: 'Mutation';
  /** Buyer & Seller */
  acceptOrder: Maybe<Order>;
  activateStorefront: Maybe<Storefront>;
  addFavorite: Maybe<Scalars['Boolean']>;
  addReaction: Scalars['Boolean'];
  adminConversationUpdateStatus: Maybe<AdminConversationList>;
  adminCreateDiscountCode: Maybe<AdminDiscountCode>;
  adminCreateNotificationCenter: Maybe<AdminNotificationCenter>;
  adminCreateTag: Maybe<AdminTag>;
  adminCreateUser: CurrentUser;
  adminDeleteTag: Scalars['Boolean'];
  adminDeleteUser: Maybe<AdminDeleteUser>;
  adminDiscountCodeUpdate: Maybe<AdminDiscountCode>;
  adminResyncTwilioMessages: Scalars['Boolean'];
  adminUpdateStorefrontPrimaryImage: Storefront;
  adminUpdateStorefrontTag: Maybe<Array<Maybe<AdminStorefrontTag>>>;
  adminUpdateTag: Maybe<AdminTag>;
  applyDiscountCodetoInvoice: Maybe<OrderDiscountCodeRemoved>;
  approveStorefront: Maybe<Storefront>;
  /** Messaging related */
  blockUser: Maybe<UserBlacklist>;
  /** Buyer or Seller */
  cancelOrder: Maybe<Order>;
  cancelStorefront: Maybe<Scalars['Boolean']>;
  changeMainPhotoV2: Scalars['Boolean'];
  /** Seller */
  completeOrder: Maybe<Order>;
  /** Seller - previous two steps merged together */
  createAndReviseOrder: Maybe<Order>;
  createCartOrder: Maybe<CreatedCartOrderDetail>;
  createCategory: Maybe<Category>;
  /** legacy twilio Chat */
  createChatRoomV3: Scalars['String'];
  createComment: Maybe<Comment>;
  /** uses twilio Conversations */
  createConversation: ConversationCreated;
  createMyReferralCode: Maybe<Scalars['String']>;
  /**
   * ##### ORDER FLOW V1:
   *  Buyer
   */
  createOrderV2: Maybe<Order>;
  createPaymentMethod: Maybe<Scalars['Boolean']>;
  createReview: Maybe<Comment>;
  createReviewResponse: Maybe<Comment>;
  /** @deprecated for old version - use v2 for new one */
  createStorefront: Maybe<Storefront>;
  createStorefrontV2: Maybe<Storefront>;
  createStripeAccount: Maybe<Scalars['Boolean']>;
  /** @deprecated for old version - use v2 for new one */
  createUser: CurrentUser;
  createUserV2: CurrentUser;
  deactivateStorefront: Maybe<Storefront>;
  declineCartOrder: CreatedCartOrderDetail;
  deleteCategory: Scalars['Boolean'];
  deleteComment: Scalars['Boolean'];
  deleteStorefront: Maybe<Scalars['Boolean']>;
  deleteStorefrontMediaItem: Maybe<Scalars['Boolean']>;
  deleteUser: Maybe<Scalars['Boolean']>;
  /**
   * While user is browsing anonymously
   * gathers the favorites locally and then
   * flushes them after signing up using this mutation:
   */
  flushFavorites: Maybe<Scalars['Boolean']>;
  fulfillOrder: Array<Maybe<Order>>;
  getInTouch: Scalars['String'];
  /** sends a message to seller and returns a chatroom id */
  getInTouchV3: ConversationCreated;
  /** creates order entity in database (ready to be paid) */
  initiateOrder: Maybe<Order>;
  initiateOutsideOrder: Maybe<Order>;
  notificationReadReceipt: Scalars['Boolean'];
  /** Admin: */
  notify: Scalars['Boolean'];
  pauseStorefront: Maybe<Storefront>;
  pauseStorefrontwithDate: Maybe<Storefront>;
  /** Buyer */
  payOrder: Maybe<Order>;
  readConversationMessage: ConversationUpdated;
  /** @deprecated use createUser and updateUser in new versions */
  registerAddress: Maybe<User>;
  registerSharedLead: RegistrationStatus;
  /** Web */
  registerUserContact: RegisteredUserContact;
  registeredUserUpdate: RegistrationStatus;
  rejectStorefront: Maybe<Storefront>;
  removeAllFavorites: Maybe<Scalars['Boolean']>;
  removeCurrentUser: Scalars['Boolean'];
  removeFavorite: Maybe<Scalars['Boolean']>;
  removePaymentMethod: Scalars['Boolean'];
  removeReaction: Scalars['Boolean'];
  reportComment: Scalars['Boolean'];
  reportStorefront: Scalars['Boolean'];
  requestStorefront: Maybe<Storefront>;
  resendOutsideOrder: Maybe<Scalars['Boolean']>;
  /** Seller */
  reviseOrder: Maybe<Order>;
  unblockUser: Maybe<Scalars['Boolean']>;
  unpauseStorefront: Maybe<Storefront>;
  updateCategory: Maybe<Category>;
  updateComment: Maybe<Comment>;
  /** Buyer */
  updateOrder: Maybe<Order>;
  updatePaymentMethod: Maybe<Scalars['Boolean']>;
  updateSettings: Maybe<Settings>;
  /** @deprecated for old version - use v2 for new one */
  updateStorefront: Maybe<Storefront>;
  updateStorefrontCatalogSortOrder: Maybe<Scalars['Boolean']>;
  updateStorefrontDynamicLink: Maybe<Storefront>;
  updateStorefrontV2: Maybe<Storefront>;
  /** @deprecated for old version - use v2 for new one */
  updateUser: CurrentUser;
  updateUserCurrentAppViewType: Scalars['Boolean'];
  updateUserRegistrationStatus: RegistrationStatus;
  updateUserType: Scalars['Boolean'];
  updateUserTypeV2: CurrentUser;
  updateUserV2: CurrentUser;
  /** Seller */
  uploadImages: Maybe<Scalars['Boolean']>;
  upsertStorefrontCatalogItem: Maybe<Catalog>;
}


export interface MutationAcceptOrderArgs {
  orderId: Scalars['Int'];
}


export interface MutationActivateStorefrontArgs {
  id: Scalars['Int'];
}


export interface MutationAddFavoriteArgs {
  storefrontId: Scalars['Int'];
}


export interface MutationAddReactionArgs {
  commentId: Scalars['Int'];
  type: InputMaybe<ReactionType>;
}


export interface MutationAdminConversationUpdateStatusArgs {
  conversation: AdminConversationUpdateStatus;
}


export interface MutationAdminCreateDiscountCodeArgs {
  discountcode: AdminDiscountCodeInput;
}


export interface MutationAdminCreateNotificationCenterArgs {
  notification: AdminNotificationInput;
}


export interface MutationAdminCreateTagArgs {
  tag: CreateTagInput;
}


export interface MutationAdminCreateUserArgs {
  user: CreateUserInput;
}


export interface MutationAdminDeleteTagArgs {
  tagId: Scalars['Int'];
}


export interface MutationAdminDeleteUserArgs {
  user: AdminDeleteUserInput;
}


export interface MutationAdminDiscountCodeUpdateArgs {
  discountcode: AdminDiscountCodeUpdateInput;
}


export interface MutationAdminResyncTwilioMessagesArgs {
  twilioConversationSid: Scalars['String'];
}


export interface MutationAdminUpdateStorefrontPrimaryImageArgs {
  storefront: AdminStorefrontPrimaryImageInput;
}


export interface MutationAdminUpdateStorefrontTagArgs {
  tag: UpdateStorefrontTagInput;
}


export interface MutationAdminUpdateTagArgs {
  tag: UpdateTagInput;
}


export interface MutationApplyDiscountCodetoInvoiceArgs {
  appusertype: InputMaybe<UserType>;
  order: ApplyDiscountCodetoInvoiceInput;
  remove: InputMaybe<Scalars['Boolean']>;
}


export interface MutationApproveStorefrontArgs {
  id: Scalars['Int'];
}


export interface MutationBlockUserArgs {
  id: Scalars['ID'];
}


export interface MutationCancelOrderArgs {
  appusertype: InputMaybe<UserType>;
  orderId: Scalars['Int'];
}


export interface MutationCancelStorefrontArgs {
  id: Scalars['Int'];
}


export interface MutationChangeMainPhotoV2Args {
  uid: Scalars['String'];
}


export interface MutationCompleteOrderArgs {
  order: CompleteOrderInput;
}


export interface MutationCreateAndReviseOrderArgs {
  order: CreateAndReviseOrderInput;
}


export interface MutationCreateCartOrderArgs {
  order: CreateCartOrderRequestInput;
}


export interface MutationCreateCategoryArgs {
  category: CreateCategoryInput;
}


export interface MutationCreateChatRoomV3Args {
  appusertype: InputMaybe<UserType>;
  chatroom: CreateChatRoomInput;
}


export interface MutationCreateCommentArgs {
  comment: CreateCommentInput;
}


export interface MutationCreateConversationArgs {
  appusertype: InputMaybe<UserType>;
  conversation: Create1to1ConversationInput;
}


export interface MutationCreateOrderV2Args {
  order: CreateOrderInputV2;
}


export interface MutationCreatePaymentMethodArgs {
  cardToken: Scalars['String'];
}


export interface MutationCreateReviewArgs {
  review: CreateReviewInput;
}


export interface MutationCreateReviewResponseArgs {
  reviewResponse: CreateReviewResponseInput;
}


export interface MutationCreateStorefrontArgs {
  storefront: CreateStorefrontInput;
}


export interface MutationCreateStorefrontV2Args {
  storefront: CreateStorefrontInputV2;
}


export interface MutationCreateStripeAccountArgs {
  authorizationCode: Scalars['String'];
}


export interface MutationCreateUserArgs {
  user: CreateUserInput;
}


export interface MutationCreateUserV2Args {
  user: CreateUserInput;
}


export interface MutationDeactivateStorefrontArgs {
  id: Scalars['Int'];
}


export interface MutationDeclineCartOrderArgs {
  cartOrder: SellerDeclineCartOrderInput;
}


export interface MutationDeleteCategoryArgs {
  id: Scalars['Int'];
}


export interface MutationDeleteCommentArgs {
  id: Scalars['Int'];
}


export interface MutationDeleteStorefrontArgs {
  id: Scalars['Int'];
}


export interface MutationDeleteStorefrontMediaItemArgs {
  isCatalogMedia: InputMaybe<Scalars['Boolean']>;
  mediaId: Scalars['Int'];
}


export interface MutationDeleteUserArgs {
  uid: Scalars['String'];
}


export interface MutationFlushFavoritesArgs {
  storefrontIds: Array<Scalars['Int']>;
}


export interface MutationFulfillOrderArgs {
  orderFulfillment: SellerOrderFulfillmentInput;
}


export interface MutationGetInTouchArgs {
  appusertype: InputMaybe<UserType>;
  order: GetInTouchInput;
  version: InputMaybe<GetInTouchVersion>;
}


export interface MutationGetInTouchV3Args {
  appusertype: InputMaybe<UserType>;
  isWeb: InputMaybe<Scalars['Boolean']>;
  order: GetInTouchInput;
  version: InputMaybe<GetInTouchVersion>;
}


export interface MutationInitiateOrderArgs {
  order: InitiateOrderInput;
  version: InputMaybe<OrderVersion>;
}


export interface MutationInitiateOutsideOrderArgs {
  order: InitiateOutsideOrderInput;
  version: InputMaybe<OrderVersion>;
}


export interface MutationNotificationReadReceiptArgs {
  notificationId: Scalars['Int'];
}


export interface MutationNotifyArgs {
  input: NotifyInput;
}


export interface MutationPauseStorefrontArgs {
  id: Scalars['Int'];
}


export interface MutationPauseStorefrontwithDateArgs {
  storefront: PauseStorefrontwithDate;
}


export interface MutationPayOrderArgs {
  appusertype: InputMaybe<UserType>;
  order: PayOrderInput;
}


export interface MutationReadConversationMessageArgs {
  appusertype: UserType;
  conversation: ReadConversationMessageInput;
}


export interface MutationRegisterAddressArgs {
  address: RegisterUserAddressInput;
}


export interface MutationRegisterSharedLeadArgs {
  contactId: Scalars['Int'];
}


export interface MutationRegisterUserContactArgs {
  register: RegisterUserContactInput;
}


export interface MutationRegisteredUserUpdateArgs {
  user: UpdateUserRegistrationInput;
}


export interface MutationRejectStorefrontArgs {
  id: Scalars['Int'];
}


export interface MutationRemoveFavoriteArgs {
  storefrontId: Scalars['Int'];
}


export interface MutationRemoveReactionArgs {
  commentId: Scalars['Int'];
  type: InputMaybe<ReactionType>;
}


export interface MutationReportCommentArgs {
  id: Scalars['Int'];
}


export interface MutationReportStorefrontArgs {
  id: Scalars['Int'];
}


export interface MutationRequestStorefrontArgs {
  id: Scalars['Int'];
}


export interface MutationResendOutsideOrderArgs {
  orderId: Scalars['Int'];
}


export interface MutationReviseOrderArgs {
  appusertype: InputMaybe<UserType>;
  order: ReviseOrderInput;
}


export interface MutationUnblockUserArgs {
  id: Scalars['ID'];
}


export interface MutationUnpauseStorefrontArgs {
  id: Scalars['Int'];
}


export interface MutationUpdateCategoryArgs {
  category: UpdateCategoryInput;
}


export interface MutationUpdateCommentArgs {
  comment: UpdateCommentInput;
}


export interface MutationUpdateOrderArgs {
  order: UpdateOrderInput;
}


export interface MutationUpdatePaymentMethodArgs {
  cardToken: Scalars['String'];
}


export interface MutationUpdateSettingsArgs {
  settings: InputMaybe<UpdateSettingsInput>;
}


export interface MutationUpdateStorefrontArgs {
  storefront: UpdateStorefrontInput;
}


export interface MutationUpdateStorefrontCatalogSortOrderArgs {
  catalogSortOrder: Scalars['catalogSortOrderJSON'];
}


export interface MutationUpdateStorefrontDynamicLinkArgs {
  storefront: InputMaybe<Scalars['String']>;
}


export interface MutationUpdateStorefrontV2Args {
  storefront: UpdateStorefrontInputV2;
}


export interface MutationUpdateUserArgs {
  user: UpdateUserInput;
}


export interface MutationUpdateUserCurrentAppViewTypeArgs {
  user: UpdateUserViewTypeInput;
}


export interface MutationUpdateUserRegistrationStatusArgs {
  login: UpdateUserRegistrationStatusInput;
}


export interface MutationUpdateUserTypeArgs {
  user: UpdateUserTypeInput;
}


export interface MutationUpdateUserTypeV2Args {
  user: UpdateUserTypeInput;
}


export interface MutationUpdateUserV2Args {
  user: UpdateUserInput;
}


export interface MutationUploadImagesArgs {
  input: UploadFilesInput;
}


export interface MutationUpsertStorefrontCatalogItemArgs {
  catalog: StorefrontCatalogInput;
}

export interface Note {
  __typename?: 'Note';
  author: User;
  body: Scalars['String'];
  created_at: Scalars['Date'];
  id: Scalars['Int'];
  seenAt: Scalars['Date'];
  updated_at: Scalars['Date'];
}

export interface NotesFilterInput {
  authorId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['Int']>;
}

export enum NotificationType {
  Category = 'CATEGORY',
  Notification = 'NOTIFICATION',
  Ordercancelled = 'ORDERCANCELLED',
  Paymentreceived = 'PAYMENTRECEIVED',
  Paymentrequest = 'PAYMENTREQUEST',
  Storefront = 'STOREFRONT',
  Tynreceived = 'TYNRECEIVED',
  Tynrequest = 'TYNREQUEST'
}

export interface NotifyInput {
  body: Scalars['String'];
  groupFilter?: InputMaybe<GroupNotificationFilter>;
  link?: InputMaybe<Scalars['String']>;
  uids?: InputMaybe<Array<Scalars['String']>>;
}

export interface Order {
  __typename?: 'Order';
  /** Additional costs like shipping */
  additionalCost: Maybe<Scalars['Float']>;
  amount: Maybe<Scalars['Float']>;
  buyer: User;
  buyerPaysMarketplaceCommission: Scalars['Boolean'];
  /** v4 */
  buyerPaysProcessingFee: Scalars['Boolean'];
  /** when buyer accepted the order */
  buyerSignedAt: Maybe<Scalars['Date']>;
  canceledAt: Maybe<Scalars['Date']>;
  canceledByUser: Maybe<User>;
  /** when buyer's money are transferred */
  chargedAt: Maybe<Scalars['Date']>;
  commentId: Maybe<Scalars['Int']>;
  /** when seller hits Complete Order */
  completedAt: Maybe<Scalars['Date']>;
  /** When the order was firstly placed */
  created_at: Scalars['Date'];
  discountCode: Maybe<OrderDiscountCode>;
  /**
   * Calculated distance
   * between buyer and seller
   */
  distance: Scalars['Float'];
  /** when the buyer will be charged automatically */
  dueDate: Maybe<Scalars['Date']>;
  /**
   * after completing order
   * - seller might have worked more than expected
   */
  extraUnits: Maybe<Scalars['Float']>;
  fulfillmentDate: Maybe<Scalars['Date']>;
  fulfillmentMethod: Maybe<OrderFulfillmentMethod>;
  fulfillmentStatus: Maybe<OrderFulfillmentStatus>;
  fulfillmentTracking: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  invoiceHashId: Maybe<Scalars['String']>;
  invoiceShareUrl: Maybe<Scalars['String']>;
  isDeposit: Maybe<Scalars['Boolean']>;
  isOutsideBuyer: Scalars['Boolean'];
  /** v3 with marketplaceCommission and paymentProcessingFee fields */
  marketplaceCommission: Maybe<Scalars['Int']>;
  /**
   * text that seller and buyer have exchanged
   * during the order initialization
   */
  notes: Array<Note>;
  /** When buyer is outside of geofencing radius */
  outsideGeofenceRadius: Scalars['Boolean'];
  /** outside order */
  outsideUserJSON: Maybe<OutsideUser>;
  paymentProcessingFee: Maybe<Scalars['Int']>;
  paymentStatus: Maybe<OrderPaymentStatus>;
  /** When buyer is available */
  preferredDateEnd: Scalars['Date'];
  /** When buyer is available */
  preferredDateStart: Scalars['Date'];
  /** Details about pricing */
  pricingDetail: Maybe<Scalars['String']>;
  /** hourly rate or productly rate */
  rate: Maybe<Scalars['Float']>;
  referenceNumber: Scalars['Int'];
  relatedCartOrders: Maybe<Array<Maybe<CreatedCartOrderDetail>>>;
  relatedCartOrdersJSON: Maybe<Scalars['cartOrderJSON']>;
  salesTax: Maybe<Scalars['Float']>;
  /** When the order will happen */
  selectedDate: Maybe<Scalars['Date']>;
  /**
   * this is from old design - ignore that for now
   * @deprecated old design
   */
  serviceName: Maybe<Scalars['String']>;
  status: Maybe<OrderStatus>;
  storefront: Storefront;
  /** when seller revised the order */
  storefrontSignedAt: Maybe<Scalars['Date']>;
  totalCharged: Maybe<Scalars['Float']>;
  totalToBePaid: Maybe<Scalars['Int']>;
  /** hours or products */
  units: Maybe<Scalars['Int']>;
  /** v1 or v2 or v3 based on flow in which order was created in (legacy) or marketplace commission */
  version: Maybe<OrderVersion>;
}


export interface OrderDistanceArgs {
  filter: InputMaybe<DistanceFilterInput>;
}


export interface OrderNotesArgs {
  filter: InputMaybe<NotesFilterInput>;
}

export interface OrderDiscountCode {
  __typename?: 'OrderDiscountCode';
  code: Scalars['String'];
  discountCodeId: Scalars['Int'];
  discountType: DiscountType;
  discountValue: Scalars['Float'];
  isReferral: Maybe<Scalars['Boolean']>;
  orderDiscountAmount: Maybe<Scalars['Float']>;
  orderMinimumAmount: Scalars['Float'];
  usageMessageJSON: Maybe<Scalars['usageMessageJSON']>;
}

export interface OrderDiscountCodeRemoved {
  __typename?: 'OrderDiscountCodeRemoved';
  discountRemoved: Maybe<DiscountCodeRemoved>;
  orderDiscount: Maybe<OrderDiscountCode>;
}

export interface OrderFulfillmentInput {
  fulfillmentDate: Scalars['Date'];
  fulfillmentMethod: OrderFulfillmentMethod;
  fulfillmentTracking?: InputMaybe<Scalars['String']>;
  orderId: Scalars['Int'];
}

export enum OrderFulfillmentMethod {
  Local = 'LOCAL',
  Onsite = 'ONSITE',
  Pickup = 'PICKUP',
  Shipping = 'SHIPPING',
  Virtual = 'VIRTUAL'
}

export enum OrderFulfillmentStatus {
  Completed = 'COMPLETED',
  NotFulfilled = 'NOT_FULFILLED',
  Partial = 'PARTIAL',
  Shipped = 'SHIPPED'
}

export enum OrderPaymentStatus {
  Paid = 'PAID',
  Unpaid = 'UNPAID'
}

export enum OrderStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  FullySigned = 'FULLY_SIGNED',
  HalfSigned = 'HALF_SIGNED',
  InProgress = 'IN_PROGRESS',
  PaidNotComplete = 'PAID_NOT_COMPLETE',
  Requested = 'REQUESTED',
  UnpayedCompleted = 'UNPAYED_COMPLETED'
}

export enum OrderVersion {
  V1 = 'V1',
  V2 = 'V2',
  V3 = 'V3',
  V4 = 'V4',
  V5 = 'V5',
  V6 = 'V6'
}

export interface OutsideOrderUserInput {
  buyerEmail?: InputMaybe<Scalars['String']>;
  buyerName: Scalars['String'];
  buyerPhoneNumber?: InputMaybe<Scalars['String']>;
}

export interface OutsideUser {
  __typename?: 'OutsideUser';
  buyerEmail: Maybe<Scalars['String']>;
  buyerName: Scalars['String'];
  buyerPhoneNumber: Maybe<Scalars['String']>;
}

export interface PaginatedAdminCsConversationList {
  __typename?: 'PaginatedAdminCSConversationList';
  data: Array<AdminCsConversationList>;
  pagination: Maybe<PaginationResult>;
}

export interface PaginatedAdminConversationList {
  __typename?: 'PaginatedAdminConversationList';
  data: Array<AdminConversationList>;
  pagination: Maybe<PaginationResult>;
}

export interface PaginatedAdminDiscountCode {
  __typename?: 'PaginatedAdminDiscountCode';
  data: Array<AdminDiscountCode>;
  pagination: Maybe<PaginationResult>;
}

export interface PaginatedAdminOutReachListBuyer {
  __typename?: 'PaginatedAdminOutReachListBuyer';
  data: Array<AdminOutReachListBuyer>;
  pagination: Maybe<PaginationResult>;
}

export interface PaginatedAdminOutReachListSeller {
  __typename?: 'PaginatedAdminOutReachListSeller';
  data: Array<AdminOutReachListSeller>;
  pagination: Maybe<PaginationResult>;
}

export interface PaginatedAdminStorefront {
  __typename?: 'PaginatedAdminStorefront';
  data: Array<AdminStorefront>;
  pagination: Maybe<PaginationResult>;
}

export interface PaginatedChatRoom {
  __typename?: 'PaginatedChatRoom';
  data: Array<ChatRoom>;
  pagination: Maybe<PaginationResult>;
}

export interface PaginatedComment {
  __typename?: 'PaginatedComment';
  data: Array<Comment>;
  pagination: Maybe<PaginationResult>;
}

export interface PaginatedConversationList {
  __typename?: 'PaginatedConversationList';
  data: Array<ConversationList>;
  pagination: Maybe<PaginationResult>;
}

export interface PaginatedOrder {
  __typename?: 'PaginatedOrder';
  data: Array<Order>;
  pagination: Maybe<PaginationResult>;
}

export interface PaginatedSellerOrderListCartOrderSegment {
  __typename?: 'PaginatedSellerOrderListCartOrderSegment';
  data: Array<SellerOrderListCartOrderSegment>;
  pagination: Maybe<PaginationResult>;
}

export interface PaginatedSellerOrderListOrderSegment {
  __typename?: 'PaginatedSellerOrderListOrderSegment';
  data: Array<SellerOrderListOrderSegment>;
  pagination: Maybe<PaginationResult>;
}

export interface PaginatedStorefront {
  __typename?: 'PaginatedStorefront';
  data: Array<Storefront>;
  pagination: Maybe<PaginationResult>;
}

export interface PaginatedUserAdmin {
  __typename?: 'PaginatedUserAdmin';
  data: Array<UserAdmin>;
  pagination: Maybe<PaginationResult>;
}

export interface PaginationInput {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
}

export interface PaginationResult {
  __typename?: 'PaginationResult';
  currentPage: Scalars['Int'];
  from: Scalars['Int'];
  maxPage: Scalars['Int'];
  perPage: Scalars['Int'];
  to: Scalars['Int'];
  total: Scalars['Int'];
}

export interface PauseStorefrontwithDate {
  endPauseDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['Int'];
  startPauseDate: Scalars['Date'];
}

export interface PayOrderInput {
  donation?: InputMaybe<Scalars['Float']>;
  id: Scalars['Int'];
}

export enum PriceType {
  PerHour = 'PER_HOUR',
  PerProduct = 'PER_PRODUCT',
  Varies = 'VARIES'
}

export interface Query {
  __typename?: 'Query';
  adminCSConversationList: Maybe<PaginatedAdminCsConversationList>;
  adminCategoryList: Maybe<Array<Maybe<AdminCategory>>>;
  adminConversationDetail: Maybe<AdminConversationDetail>;
  adminConversationList: Maybe<PaginatedAdminConversationList>;
  adminDashboard: Array<DashboardStat>;
  adminDiscountCodeList: Maybe<PaginatedAdminDiscountCode>;
  adminGetCohort: Maybe<Array<Maybe<Cohort>>>;
  adminOrder: Maybe<PaginatedOrder>;
  adminOutReachListBuyer: Maybe<PaginatedAdminOutReachListBuyer>;
  adminOutReachListSeller: Maybe<PaginatedAdminOutReachListSeller>;
  adminStorefront: Maybe<PaginatedAdminStorefront>;
  adminStripeStatus: Maybe<StripeStatus>;
  adminTagDetail: Maybe<AdminTag>;
  adminTagList: Maybe<Array<Maybe<AdminTagSummary>>>;
  blacklist: Array<UserBlacklist>;
  buyerCartOrderDetail: Maybe<CreatedCartOrderDetail>;
  cartOrderWithMe: Maybe<Array<Maybe<CreatedCartOrderDetail>>>;
  catalog: Maybe<Array<Maybe<Catalog>>>;
  category: Array<Category>;
  categoryTaxonomy: Array<Maybe<CategoryTaxonomy>>;
  chatroom: PaginatedChatRoom;
  comment: Maybe<PaginatedComment>;
  contact: Maybe<Contact>;
  /** Returns credit cards for currently logged in user */
  creditCard: Maybe<Array<CreditCardInfo>>;
  globalNotificationWidget: Maybe<GlobalNotificationWidget>;
  hasProxyConversation: Maybe<HasConversationWithAdmin>;
  invoiceIsUser: Scalars['Boolean'];
  isBlocked: IsBlocked;
  me: Maybe<CurrentUser>;
  myConversationList: Maybe<PaginatedConversationList>;
  mynotificationcenter: Maybe<Array<Maybe<UserNotificationCenter>>>;
  /** #### ORDER FLOW V1: */
  order: Maybe<PaginatedOrder>;
  orderDetailCart: CreatedCartOrderDetail;
  /** #### ORDER FLOW V2: */
  orderV2: Maybe<PaginatedOrder>;
  orderV3: Maybe<PaginatedOrder>;
  sellerCartOrderDetail: Maybe<CreatedCartOrderDetail>;
  sellerOrderList: SellerOrderList;
  settings: Maybe<Settings>;
  sitemap: SiteMap;
  storageInfo: StorageInfo;
  storefront: Maybe<PaginatedStorefront>;
  toBePaidOrdersCount: Scalars['Int'];
  userRegistrationStatus: UserRegistrationStatus;
  users: Array<Maybe<User>>;
  usersAdmin: Maybe<PaginatedUserAdmin>;
  validate: Validation;
}


export interface QueryAdminCsConversationListArgs {
  filter: InputMaybe<FilterAdminConversationListInput>;
  pagination: InputMaybe<PaginationInput>;
}


export interface QueryAdminConversationDetailArgs {
  conversation: Scalars['Int'];
}


export interface QueryAdminConversationListArgs {
  filter: InputMaybe<FilterAdminConversationListInput>;
  pagination: InputMaybe<PaginationInput>;
}


export interface QueryAdminDiscountCodeListArgs {
  filter: InputMaybe<FilterAdminDiscountCodeInput>;
  pagination: InputMaybe<PaginationInput>;
}


export interface QueryAdminOrderArgs {
  filter: InputMaybe<FilterAdminOrderInput>;
  pagination: InputMaybe<PaginationInput>;
}


export interface QueryAdminOutReachListBuyerArgs {
  filter: InputMaybe<FilterAdminConversationListInput>;
  pagination: InputMaybe<PaginationInput>;
}


export interface QueryAdminOutReachListSellerArgs {
  filter: InputMaybe<FilterAdminConversationListInput>;
  pagination: InputMaybe<PaginationInput>;
}


export interface QueryAdminStorefrontArgs {
  filter: InputMaybe<FilterAdminStorefrontInput>;
  pagination: InputMaybe<PaginationInput>;
}


export interface QueryAdminStripeStatusArgs {
  storefrontId: Scalars['Int'];
}


export interface QueryAdminTagDetailArgs {
  tagId: Scalars['Int'];
}


export interface QueryBuyerCartOrderDetailArgs {
  id: InputMaybe<Scalars['Int']>;
  orderHashId: InputMaybe<Scalars['String']>;
}


export interface QueryCartOrderWithMeArgs {
  buyerId: Scalars['String'];
}


export interface QueryCatalogArgs {
  filter: InputMaybe<FilterCatalogInput>;
}


export interface QueryCategoryArgs {
  id: InputMaybe<Scalars['Int']>;
}


export interface QueryChatroomArgs {
  filter: InputMaybe<FilterChatRoomInput>;
  pagination: InputMaybe<PaginationInput>;
}


export interface QueryCommentArgs {
  filter: InputMaybe<FilterCommentInput>;
  pagination: InputMaybe<PaginationInput>;
}


export interface QueryHasProxyConversationArgs {
  userId: Scalars['ID'];
  userType: UserType;
}


export interface QueryInvoiceIsUserArgs {
  i: Scalars['String'];
}


export interface QueryIsBlockedArgs {
  roomId: Scalars['Int'];
}


export interface QueryMyConversationListArgs {
  filter: FilterConversationListInput;
  pagination: InputMaybe<PaginationInput>;
}


export interface QueryMynotificationcenterArgs {
  filter: InputMaybe<FilterNotificationCenterInput>;
}


export interface QueryOrderArgs {
  appusertype: InputMaybe<UserType>;
  filter: InputMaybe<FilterOrderInput>;
  pagination: InputMaybe<PaginationInput>;
  supportedOrderVersion: InputMaybe<OrderVersion>;
}


export interface QueryOrderDetailCartArgs {
  cartOrderId: Scalars['Int'];
}


export interface QueryOrderV2Args {
  filter: InputMaybe<FilterOrderV3Input>;
  pagination: InputMaybe<PaginationInput>;
}


export interface QueryOrderV3Args {
  appusertype: InputMaybe<UserType>;
  filter: InputMaybe<FilterOrderV3Input>;
  pagination: InputMaybe<PaginationInput>;
  supportedOrderVersion: InputMaybe<OrderVersion>;
}


export interface QuerySellerCartOrderDetailArgs {
  id: InputMaybe<Scalars['Int']>;
  orderHashId: InputMaybe<Scalars['String']>;
}


export interface QueryStorefrontArgs {
  appusertype: InputMaybe<UserType>;
  filter: InputMaybe<FilterStorefrontInput>;
  pagination: InputMaybe<PaginationInput>;
}


export interface QueryUserRegistrationStatusArgs {
  email: Scalars['String'];
}


export interface QueryUsersArgs {
  filter: FilterUserInput;
}


export interface QueryUsersAdminArgs {
  filter: InputMaybe<FilterUserAdminInput>;
  pagination: InputMaybe<PaginationInput>;
}

export enum ReactionType {
  Helpful = 'HELPFUL'
}

export interface ReactionsFilter {
  type?: InputMaybe<ReactionType>;
}

export interface ReadConversationMessageInput {
  conversationId: Scalars['Int'];
  isUnread?: InputMaybe<Scalars['Boolean']>;
  twilioMessageSid?: InputMaybe<Scalars['String']>;
  updateAll: Scalars['Boolean'];
}

export interface RegisterUserAddressInput {
  city: Scalars['String'];
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  streetAddress: Scalars['String'];
  zip: Scalars['String'];
}

export interface RegisterUserContactInput {
  contact: GetInTouchInput;
  user: RegisterUserInput;
}

export interface RegisterUserCoordsInput {
  lat?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
}

export interface RegisterUserInput {
  attribution?: InputMaybe<Scalars['attributionJSON']>;
  coords?: InputMaybe<RegisterUserCoordsInput>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  utm?: InputMaybe<UtmJsonInput>;
  zip: Scalars['String'];
}

export interface RegisteredUserContact {
  __typename?: 'RegisteredUserContact';
  contactId: Maybe<Scalars['Int']>;
  token: Maybe<Scalars['String']>;
  uid: Maybe<Scalars['ID']>;
}

export interface RegistrationStatus {
  __typename?: 'RegistrationStatus';
  success: Scalars['Boolean'];
}

/**
 * https://projects.invisionapp.com/d/main#/console/16585436/348685281/preview#project_console
 * https://projects.invisionapp.com/d/main#/console/16585436/350051814/preview#project_console
 */
export interface ReviseOrderInput {
  additionalCost?: InputMaybe<Scalars['Float']>;
  amount: Scalars['Float'];
  id: Scalars['Int'];
  note?: InputMaybe<Scalars['String']>;
  pricingDetail?: InputMaybe<Scalars['String']>;
  selectedDate: Scalars['Date'];
  /** Hours, Products or none */
  units?: InputMaybe<Scalars['Int']>;
}

export interface SellerDeclineCartOrderInput {
  cartOrderId: Scalars['Int'];
  messageToCustomer: Scalars['String'];
  reason: Array<Scalars['String']>;
}

export interface SellerOrderFulfillmentInput {
  fulfilledCartOrder?: InputMaybe<Array<CartOrderFulfillmentInput>>;
  fulfilledOrder?: InputMaybe<Array<OrderFulfillmentInput>>;
}

export interface SellerOrderList {
  __typename?: 'SellerOrderList';
  canceled: Maybe<PaginatedSellerOrderListOrderSegment>;
  completed: Maybe<PaginatedSellerOrderListOrderSegment>;
  declined: Maybe<PaginatedSellerOrderListCartOrderSegment>;
  notfulfilled: Maybe<PaginatedSellerOrderListOrderSegment>;
  requested: Maybe<PaginatedSellerOrderListCartOrderSegment>;
  unpaid: Maybe<PaginatedSellerOrderListOrderSegment>;
}


export interface SellerOrderListCanceledArgs {
  pagination: InputMaybe<PaginationInput>;
}


export interface SellerOrderListCompletedArgs {
  pagination: InputMaybe<PaginationInput>;
}


export interface SellerOrderListDeclinedArgs {
  pagination: InputMaybe<PaginationInput>;
}


export interface SellerOrderListNotfulfilledArgs {
  pagination: InputMaybe<PaginationInput>;
}


export interface SellerOrderListRequestedArgs {
  pagination: InputMaybe<PaginationInput>;
}


export interface SellerOrderListUnpaidArgs {
  pagination: InputMaybe<PaginationInput>;
}

export interface SellerOrderListCartOrderSegment {
  __typename?: 'SellerOrderListCartOrderSegment';
  buyer: User;
  cartOrderFlowStatus: CartOrderFlowStatus;
  isInitiatedOrder: Scalars['Boolean'];
  orderHeader: CartOrderHeader;
}

export interface SellerOrderListOrderSegment {
  __typename?: 'SellerOrderListOrderSegment';
  buyer: User;
  canceledAt: Maybe<Scalars['Date']>;
  canceledByUser: Maybe<User>;
  commentId: Maybe<Scalars['Int']>;
  created_at: Scalars['Date'];
  discountCode: Maybe<OrderDiscountCode>;
  dueDate: Maybe<Scalars['Date']>;
  fulfillmentStatus: Maybe<OrderFulfillmentStatus>;
  id: Scalars['Int'];
  isInitiatedOrder: Scalars['Boolean'];
  isOutsideBuyer: Scalars['Boolean'];
  paymentStatus: OrderPaymentStatus;
  serviceName: Scalars['String'];
  status: OrderStatus;
  totalCharged: Maybe<Scalars['Float']>;
  totalToBePaid: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['Date']>;
}

export interface Settings {
  __typename?: 'Settings';
  geolocation: Scalars['Boolean'];
  messaging: SettingsMessaging;
  notifications: SettingsNotifications;
  thankYouNotes: Scalars['Boolean'];
}

export interface SettingsMessaging {
  __typename?: 'SettingsMessaging';
  otherUsers: Scalars['Boolean'];
  storefrontOwners: Scalars['Boolean'];
}

export interface SettingsNotifications {
  __typename?: 'SettingsNotifications';
  emailNotifications: Scalars['Boolean'];
  pushNotifications: Scalars['Boolean'];
}

export interface SiteMap {
  __typename?: 'SiteMap';
  browse: Maybe<Scalars['SiteMapBrowseType']>;
  catalog: Maybe<Scalars['SiteMapCatalogType']>;
  category: Maybe<Scalars['SiteMapCategoryType']>;
  shop: Maybe<Scalars['SiteMapShopType']>;
}

export enum Sorting {
  Asc = 'ASC',
  Desc = 'DESC'
}

export interface StatisticsFilter {
  statisticsOptions?: InputMaybe<Scalars['String']>;
  storefrontId: Scalars['Int'];
}

export interface StorageFileInfo {
  __typename?: 'StorageFileInfo';
  contentType: Scalars['String'];
  name: Scalars['String'];
  size: Scalars['Float'];
  uri: Scalars['String'];
}

export interface StorageInfo {
  __typename?: 'StorageInfo';
  storefront: Maybe<StorefrontStorageInfo>;
  storefrontV2: Maybe<StorefrontStorageInfo>;
  user: Array<StorageFileInfo>;
}

export interface Storefront {
  __typename?: 'Storefront';
  adminCategoryJSON: Maybe<Scalars['adminCategoryJSON']>;
  approvalDate: Maybe<Scalars['Date']>;
  businessName: Scalars['String'];
  catalog: Maybe<Array<Maybe<Catalog>>>;
  catalogSortOrder: Maybe<Scalars['catalogSortOrderJSONB']>;
  catalogSummary: Maybe<Array<Maybe<CatalogSummary>>>;
  category: Maybe<Category>;
  categoryJSON: Maybe<Scalars['categoryJSON']>;
  city: Maybe<Scalars['String']>;
  created_at: Scalars['Date'];
  description: Scalars['String'];
  detail: Maybe<Scalars['String']>;
  distance: Maybe<Scalars['Float']>;
  dynamicLink: Maybe<Scalars['String']>;
  fulfillmentJSON: Maybe<Scalars['fulfillmentJSON']>;
  geofenceRadius: Scalars['Float'];
  id: Scalars['Int'];
  instaAccessToken: Maybe<Scalars['String']>;
  instaUserId: Maybe<Scalars['String']>;
  instagramPhotos: Array<Maybe<InstagramPhoto>>;
  isFavorite: Maybe<Scalars['Boolean']>;
  measuredStatistics: Maybe<Scalars['measuredStatistics']>;
  outsideGeofenceRadius: Maybe<Scalars['Boolean']>;
  owner: User;
  photos: StorefrontStorageInfo;
  priceHigh: Maybe<Scalars['Float']>;
  priceLow: Scalars['Float'];
  priceType: PriceType;
  state: Maybe<Scalars['String']>;
  status: StorefrontStatus;
  tagline: Scalars['String'];
  /** Whether the thank you notes for this storefront are enabled */
  thankYouNotesEnabled: Scalars['Boolean'];
  visualTagsJSON: Maybe<Scalars['visualTagsJSONB']>;
}


export interface StorefrontDistanceArgs {
  filter: InputMaybe<DistanceStorefrontFilter>;
}


export interface StorefrontMeasuredStatisticsArgs {
  filter: InputMaybe<StatisticsFilter>;
}

export interface StorefrontBalance {
  __typename?: 'StorefrontBalance';
  available: Scalars['Float'];
  history: Array<StorefrontTransaction>;
}

/** ## We have a simplified input because we're using an upsert that allows for delete. We handle the validation in the resolver instead */
export interface StorefrontCatalogInput {
  allowSelectQuantity?: InputMaybe<Scalars['Boolean']>;
  catalogId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  fulfillmentMethodJSON?: InputMaybe<Scalars['fulfillmentMethodJSON']>;
  media?: InputMaybe<Array<InputMaybe<CatalogMediaInput>>>;
  mediaSortOrder?: InputMaybe<Scalars['mediaSortOrderJSON']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  priceType?: InputMaybe<CatalogPriceType>;
  pricingDescription?: InputMaybe<Scalars['String']>;
  status: CatalogStatus;
  storefrontId: Scalars['Int'];
}

export interface StorefrontIdAdminFilter {
  id?: InputMaybe<Scalars['Int']>;
}

export enum StorefrontSort {
  Highest = 'HIGHEST',
  Lowest = 'LOWEST',
  Newest = 'NEWEST',
  Proximity = 'PROXIMITY',
  Relevance = 'RELEVANCE'
}

export interface StorefrontStatistics {
  __typename?: 'StorefrontStatistics';
  completedOrders: Scalars['Int'];
  profit: Scalars['Float'];
  thankYouNotes: Scalars['Int'];
}

export enum StorefrontStatus {
  Active = 'ACTIVE',
  ActiveNoPayment = 'ACTIVE_NO_PAYMENT',
  Blocked = 'BLOCKED',
  Deactivated = 'DEACTIVATED',
  New = 'NEW',
  NotYetRequested = 'NOT_YET_REQUESTED',
  NoPayment = 'NO_PAYMENT',
  Paused = 'PAUSED',
  Rejected = 'REJECTED',
  Requested = 'REQUESTED'
}

export interface StorefrontStorageInfo {
  __typename?: 'StorefrontStorageInfo';
  main: Maybe<StorageFileInfo>;
  others: Maybe<Array<Maybe<StorageFileInfo>>>;
}

export interface StorefrontTransaction {
  __typename?: 'StorefrontTransaction';
  amount: Scalars['Float'];
  availableOn: Scalars['Date'];
  createdOn: Scalars['Date'];
  description: Scalars['String'];
  fee: Scalars['Float'];
  status: Scalars['String'];
  type: Scalars['String'];
}

export interface StripeStatus {
  __typename?: 'StripeStatus';
  charges_enabled: Maybe<Scalars['Boolean']>;
  payouts_enabled: Maybe<Scalars['Boolean']>;
}

export interface UpdateCategoryInput {
  active?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  isVirtual: Scalars['Boolean'];
  rank: Scalars['Int'];
  title?: InputMaybe<Scalars['String']>;
}

export interface UpdateCommentInput {
  body?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  parentId?: InputMaybe<Scalars['Int']>;
  storefrontId?: InputMaybe<Scalars['Int']>;
}

export interface UpdateOrderInput {
  amount?: InputMaybe<Scalars['Float']>;
  dueDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['Int'];
  note?: InputMaybe<Scalars['String']>;
}

export interface UpdateSettingsInput {
  emailNotifications?: InputMaybe<Scalars['Boolean']>;
  geolocation?: InputMaybe<Scalars['Boolean']>;
  otherUsers?: InputMaybe<Scalars['Boolean']>;
  pushNotifications?: InputMaybe<Scalars['Boolean']>;
  storefrontOwners?: InputMaybe<Scalars['Boolean']>;
  thankYouNotes?: InputMaybe<Scalars['Boolean']>;
}

export interface UpdateStorefrontInput {
  address?: InputMaybe<Scalars['String']>;
  adminCategoryJSON?: InputMaybe<Scalars['adminCategoryJSON']>;
  adminResetInsta?: InputMaybe<Scalars['Boolean']>;
  businessName?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['Int']>;
  categoryJSON?: InputMaybe<Scalars['categoryJSON']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  detail?: InputMaybe<Scalars['String']>;
  dynamicLink?: InputMaybe<Scalars['String']>;
  fulfillmentJSON?: InputMaybe<Scalars['fulfillmentJSON']>;
  geofenceRadius?: InputMaybe<Scalars['Float']>;
  id: Scalars['Int'];
  priceHigh?: InputMaybe<Scalars['Float']>;
  priceLow?: InputMaybe<Scalars['Float']>;
  priceType?: InputMaybe<PriceType>;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<StorefrontStatus>;
  tagline?: InputMaybe<Scalars['String']>;
  visualTagsJSON?: InputMaybe<Scalars['visualTagsJSONB']>;
  zipCode?: InputMaybe<Scalars['String']>;
}

export interface UpdateStorefrontInputV2 {
  address?: InputMaybe<Scalars['String']>;
  adminCategoryJSON?: InputMaybe<Scalars['adminCategoryJSON']>;
  adminResetInsta?: InputMaybe<Scalars['Boolean']>;
  businessName?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['Int']>;
  categoryJSON?: InputMaybe<Scalars['categoryJSON']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  /** Uris or file names */
  deletePhotos?: InputMaybe<Array<Scalars['String']>>;
  description?: InputMaybe<Scalars['String']>;
  detail?: InputMaybe<Scalars['String']>;
  dynamicLink?: InputMaybe<Scalars['String']>;
  fulfillmentJSON?: InputMaybe<Scalars['fulfillmentJSON']>;
  geofenceRadius?: InputMaybe<Scalars['Float']>;
  id: Scalars['Int'];
  instaAccessToken?: InputMaybe<Scalars['String']>;
  instaUserId?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  priceHigh?: InputMaybe<Scalars['Float']>;
  priceLow?: InputMaybe<Scalars['Float']>;
  priceType?: InputMaybe<PriceType>;
  /** Uri or file name */
  primaryImage?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<StorefrontStatus>;
  tagline?: InputMaybe<Scalars['String']>;
  uploadPhotos?: InputMaybe<Array<Scalars['Upload']>>;
  visualTagsJSON?: InputMaybe<Scalars['visualTagsJSONB']>;
  zipCode?: InputMaybe<Scalars['String']>;
}

export interface UpdateStorefrontTagInput {
  addTag?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  deleteTag?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  storefrontId: Scalars['Int'];
}

export interface UpdateTagInput {
  addCategory?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  addStorefront?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  deleteCategory?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  deleteStorefront?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  tagId: Scalars['Int'];
}

export interface UpdateUserInput {
  city?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  photo?: InputMaybe<Scalars['Upload']>;
  state?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<UserType>;
  welcomeEmail?: InputMaybe<Scalars['Boolean']>;
  zip?: InputMaybe<Scalars['String']>;
}

export interface UpdateUserRegistrationInput {
  password: Scalars['String'];
}

export interface UpdateUserRegistrationStatusInput {
  email: Scalars['String'];
  optional?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
}

export interface UpdateUserTypeInput {
  type: UserType;
  uid: Scalars['ID'];
}

export interface UpdateUserViewTypeInput {
  currentappviewtype: UserType;
  uid: Scalars['ID'];
}

export interface UploadFile {
  base64: Scalars['String'];
  mime: Scalars['String'];
  uid: Scalars['String'];
}

export interface UploadFilesInput {
  files: Array<UploadFile>;
}

export interface User {
  __typename?: 'User';
  city: Maybe<Scalars['String']>;
  created_at: Scalars['Date'];
  currentappviewtype: Maybe<UserType>;
  displayName: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  favorites: Maybe<Array<Maybe<Storefront>>>;
  firstName: Maybe<Scalars['String']>;
  isAnonymous: Scalars['Boolean'];
  isBlocked: Maybe<Scalars['Boolean']>;
  lastName: Maybe<Scalars['String']>;
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
  phoneNumber: Maybe<Scalars['String']>;
  photoURL: Maybe<Scalars['String']>;
  referralCode: Maybe<Scalars['String']>;
  settings: Settings;
  state: Maybe<Scalars['String']>;
  streetAddress: Maybe<Scalars['String']>;
  uid: Scalars['ID'];
  zip: Maybe<Scalars['String']>;
}

export interface UserAdmin {
  __typename?: 'UserAdmin';
  city: Maybe<Scalars['String']>;
  created_at: Scalars['Date'];
  displayName: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  photoURL: Maybe<Scalars['String']>;
  streetAddress: Maybe<Scalars['String']>;
  type: Maybe<UserType>;
  uid: Scalars['ID'];
  zip: Maybe<Scalars['String']>;
}

export interface UserBlacklist {
  __typename?: 'UserBlacklist';
  blocked: User;
  id: Scalars['Int'];
  user: User;
}

export interface UserNotificationCenter {
  __typename?: 'UserNotificationCenter';
  created_at: Scalars['Date'];
  isPinned: Maybe<Scalars['Boolean']>;
  isRead: Maybe<Scalars['Boolean']>;
  notificationId: Scalars['Int'];
  payload: Maybe<Scalars['payloadJSON']>;
  readAt: Maybe<Scalars['Date']>;
  type: NotificationType;
  userType: Maybe<UserType>;
}

export interface UserRegistrationStatus {
  __typename?: 'UserRegistrationStatus';
  optional: Maybe<Scalars['String']>;
  validation: Scalars['Int'];
}

export enum UserType {
  Admin = 'ADMIN',
  Ambassador = 'AMBASSADOR',
  Buyer = 'BUYER',
  Seller = 'SELLER'
}

export interface UserWithStorefront {
  __typename?: 'UserWithStorefront';
  city: Maybe<Scalars['String']>;
  created_at: Scalars['Date'];
  currentappviewtype: Maybe<UserType>;
  displayName: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  favorites: Maybe<Array<Maybe<Storefront>>>;
  firstName: Maybe<Scalars['String']>;
  isAnonymous: Scalars['Boolean'];
  isBlocked: Maybe<Scalars['Boolean']>;
  lastName: Maybe<Scalars['String']>;
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
  phoneNumber: Maybe<Scalars['String']>;
  photoURL: Maybe<Scalars['String']>;
  referralCode: Maybe<Scalars['String']>;
  settings: Settings;
  state: Maybe<Scalars['String']>;
  storefront: Maybe<Storefront>;
  streetAddress: Maybe<Scalars['String']>;
  uid: Scalars['ID'];
  zip: Maybe<Scalars['String']>;
}

export interface Validation {
  __typename?: 'Validation';
  address: Scalars['Boolean'];
  businessName: Scalars['Boolean'];
  creditCard: Scalars['Boolean'];
  zipCode: Scalars['Boolean'];
}


export interface ValidationAddressArgs {
  address: ValidationAddress;
}


export interface ValidationBusinessNameArgs {
  businessName: Scalars['String'];
}


export interface ValidationZipCodeArgs {
  zipCode: Scalars['String'];
}

export interface ValidationAddress {
  address: Scalars['String'];
  city: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipCode: Scalars['String'];
}

export interface SellerFulfillmentDetail {
  __typename?: 'sellerFulfillmentDetail';
  declined: Maybe<SellerFulfillmentDetailDeclined>;
  fulfilled: Maybe<SellerFulfillmentDetailFulfilled>;
}

export interface SellerFulfillmentDetailDeclined {
  __typename?: 'sellerFulfillmentDetailDeclined';
  declinedDate: Scalars['Date'];
  messageToCustomer: Scalars['String'];
  reason: Array<Scalars['String']>;
  status: Maybe<CartOrderFlowStatus>;
}

export interface SellerFulfillmentDetailFulfilled {
  __typename?: 'sellerFulfillmentDetailFulfilled';
  cartOrderId: Scalars['Int'];
  fulfillmentDate: Scalars['Date'];
  fulfillmentMethod: FulfillmentMethod;
  fulfillmentTracking: Maybe<Scalars['String']>;
  sellerOrderId: Scalars['Int'];
}

export interface UtmJsonInput {
  utm_campaign?: InputMaybe<Scalars['String']>;
  utm_content?: InputMaybe<Scalars['String']>;
  utm_medium?: InputMaybe<Scalars['String']>;
  utm_source?: InputMaybe<Scalars['String']>;
  utm_term?: InputMaybe<Scalars['String']>;
}

export type SignUpMutationVariables = Exact<{
  user: CreateUserInput;
}>;


export type SignUpMutation = { __typename?: 'Mutation', createUserV2: { __typename?: 'CurrentUser', type: UserType, isRegistrationComplete: boolean | null, profileJSON: any, uid: string, zip: string | null, city: string | null, streetAddress: string | null, phoneNumber: string | null, photoURL: string | null, displayName: string | null, firstName: string | null, lastName: string | null, email: string | null, latitude: number | null, longitude: number | null, isBlocked: boolean | null, created_at: any, isAnonymous: boolean, currentappviewtype: UserType | null, referralCode: string | null } };

export type ApplyDiscountCodeToInvoiceMutationVariables = Exact<{
  orderId: Scalars['Int'];
  discountCodeId: InputMaybe<Scalars['Int']>;
  discountCode: Scalars['String'];
  removed: InputMaybe<Scalars['Boolean']>;
  appusertype: InputMaybe<UserType>;
}>;


export type ApplyDiscountCodeToInvoiceMutation = { __typename?: 'Mutation', applyDiscountCodetoInvoice: { __typename?: 'OrderDiscountCodeRemoved', orderDiscount: { __typename?: 'OrderDiscountCode', discountCodeId: number, code: string, discountType: DiscountType, discountValue: number, orderMinimumAmount: number, orderDiscountAmount: number | null, usageMessageJSON: any | null } | null, discountRemoved: { __typename?: 'DiscountCodeRemoved', removed: boolean } | null } | null };

export type CreatePaymentMethodMutationVariables = Exact<{
  cardToken: Scalars['String'];
}>;


export type CreatePaymentMethodMutation = { __typename?: 'Mutation', createPaymentMethod: boolean | null };

export type GetCreditCardQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCreditCardQuery = { __typename?: 'Query', creditCard: Array<{ __typename?: 'CreditCardInfo', number: string, expiry: string, brand: string, name: string }> | null };

export type PayInvoiceMutationVariables = Exact<{
  order: PayOrderInput;
  appusertype: InputMaybe<UserType>;
}>;


export type PayInvoiceMutation = { __typename?: 'Mutation', payOrder: { __typename?: 'Order', id: number, status: OrderStatus | null, chargedAt: any | null } | null };

export type CancelOrderMutationVariables = Exact<{
  orderId: Scalars['Int'];
  appusertype: InputMaybe<UserType>;
}>;


export type CancelOrderMutation = { __typename?: 'Mutation', cancelOrder: { __typename?: 'Order', id: number, status: OrderStatus | null } | null };

export type RemovePaymentMethodMutationVariables = Exact<{ [key: string]: never; }>;


export type RemovePaymentMethodMutation = { __typename?: 'Mutation', removePaymentMethod: boolean };

export type InvoiceDetailsQueryVariables = Exact<{
  id: InputMaybe<Scalars['Int']>;
  appusertype: InputMaybe<UserType>;
  invoiceHashId: InputMaybe<Scalars['String']>;
}>;


export type InvoiceDetailsQuery = { __typename?: 'Query', orderV3: { __typename?: 'PaginatedOrder', data: Array<{ __typename?: 'Order', id: number, status: OrderStatus | null, created_at: any, preferredDateEnd: any, preferredDateStart: any, amount: number | null, additionalCost: number | null, salesTax: number | null, pricingDetail: string | null, dueDate: any | null, serviceName: string | null, extraUnits: number | null, selectedDate: any | null, completedAt: any | null, chargedAt: any | null, storefrontSignedAt: any | null, buyerSignedAt: any | null, units: number | null, referenceNumber: number, outsideGeofenceRadius: boolean, isDeposit: boolean | null, version: OrderVersion | null, marketplaceCommission: number | null, buyerPaysMarketplaceCommission: boolean, paymentProcessingFee: number | null, totalToBePaid: number | null, buyerPaysProcessingFee: boolean, buyer: { __typename?: 'User', uid: string, photoURL: string | null, displayName: string | null }, storefront: { __typename?: 'Storefront', id: number, businessName: string, priceHigh: number | null, priceLow: number, priceType: PriceType, description: string, owner: { __typename?: 'User', uid: string, photoURL: string | null, displayName: string | null, firstName: string | null } }, notes: Array<{ __typename?: 'Note', id: number, body: string, author: { __typename?: 'User', uid: string, photoURL: string | null, displayName: string | null } }>, discountCode: { __typename?: 'OrderDiscountCode', discountCodeId: number, code: string, discountType: DiscountType, discountValue: number, orderMinimumAmount: number, orderDiscountAmount: number | null, usageMessageJSON: any | null } | null }> } | null };

export type InvoicesQueryVariables = Exact<{
  page: InputMaybe<Scalars['Int']>;
  perPage: InputMaybe<Scalars['Int']>;
  status: InputMaybe<FilterOrderV2Status>;
  appusertype: InputMaybe<UserType>;
  supportedOrderVersion: InputMaybe<OrderVersion>;
  storefrontId: InputMaybe<Scalars['Int']>;
  isNotReviewed: InputMaybe<Scalars['Boolean']>;
}>;


export type InvoicesQuery = { __typename?: 'Query', orderV3: { __typename?: 'PaginatedOrder', data: Array<{ __typename?: 'Order', commentId: number | null, isDeposit: boolean | null, id: number, status: OrderStatus | null, preferredDateEnd: any, preferredDateStart: any, referenceNumber: number, amount: number | null, additionalCost: number | null, dueDate: any | null, serviceName: string | null, extraUnits: number | null, completedAt: any | null, chargedAt: any | null, storefrontSignedAt: any | null, buyerSignedAt: any | null, version: OrderVersion | null, marketplaceCommission: number | null, buyerPaysMarketplaceCommission: boolean, paymentProcessingFee: number | null, totalToBePaid: number | null, buyer: { __typename?: 'User', uid: string, photoURL: string | null, displayName: string | null }, storefront: { __typename?: 'Storefront', id: number, businessName: string, priceHigh: number | null, priceLow: number, priceType: PriceType, description: string, owner: { __typename?: 'User', uid: string, photoURL: string | null, displayName: string | null } }, notes: Array<{ __typename?: 'Note', id: number, body: string }>, discountCode: { __typename?: 'OrderDiscountCode', discountCodeId: number, code: string, discountType: DiscountType, discountValue: number, orderMinimumAmount: number, orderDiscountAmount: number | null, usageMessageJSON: any | null } | null }>, pagination: { __typename?: 'PaginationResult', total: number, perPage: number, currentPage: number, maxPage: number, from: number, to: number } | null } | null };

export type OrderFragmentFragment = { __typename?: 'Order', id: number, status: OrderStatus | null, preferredDateEnd: any, preferredDateStart: any, referenceNumber: number, amount: number | null, additionalCost: number | null, dueDate: any | null, serviceName: string | null, extraUnits: number | null, completedAt: any | null, chargedAt: any | null, storefrontSignedAt: any | null, buyerSignedAt: any | null, version: OrderVersion | null, marketplaceCommission: number | null, buyerPaysMarketplaceCommission: boolean, paymentProcessingFee: number | null, totalToBePaid: number | null, buyer: { __typename?: 'User', uid: string, photoURL: string | null, displayName: string | null }, storefront: { __typename?: 'Storefront', id: number, businessName: string, priceHigh: number | null, priceLow: number, priceType: PriceType, description: string, owner: { __typename?: 'User', uid: string, photoURL: string | null, displayName: string | null } }, notes: Array<{ __typename?: 'Note', id: number, body: string }>, discountCode: { __typename?: 'OrderDiscountCode', discountCodeId: number, code: string, discountType: DiscountType, discountValue: number, orderMinimumAmount: number, orderDiscountAmount: number | null, usageMessageJSON: any | null } | null };

export type CreateCartOrderMutationVariables = Exact<{
  storefronts: Array<CartOrderStorefrontInput> | CartOrderStorefrontInput;
  emailMarketingOptIn: Scalars['Boolean'];
}>;


export type CreateCartOrderMutation = { __typename?: 'Mutation', createCartOrder: { __typename?: 'CreatedCartOrderDetail', orderHeader: { __typename?: 'CartOrderHeader', cartOrderId: number, displayCartOrderId: number, created_at: any, updated_at: any | null, isRequested: boolean }, orderDetail: Array<{ __typename?: 'CreatedCartOrderStorefront', cartOrderSequence: string, sellerOrderId: number | null, cartOrderFlowStatus: CartOrderFlowStatus, storefront: { __typename?: 'Storefront', id: number }, cartOrderItems: Array<{ __typename?: 'CartOrderItem', cartOrderItemId: number }> }> } | null };

export type CartOrderFromHashIdQueryVariables = Exact<{
  id: InputMaybe<Scalars['Int']>;
  orderHashId: InputMaybe<Scalars['String']>;
}>;


export type CartOrderFromHashIdQuery = { __typename?: 'Query', buyerCartOrderDetail: { __typename?: 'CreatedCartOrderDetail', orderHeader: { __typename?: 'CartOrderHeader', cartOrderId: number, displayCartOrderId: number, created_at: any, updated_at: any | null, isRequested: boolean }, orderDetail: Array<{ __typename?: 'CreatedCartOrderStorefront', cartOrderSequence: string, sellerOrderId: number | null, cartOrderFlowStatus: CartOrderFlowStatus, storefront: { __typename?: 'Storefront', id: number, businessName: string, fulfillmentJSON: any | null, visualTagsJSON: any | null, owner: { __typename?: 'User', uid: string, streetAddress: string | null, city: string | null, state: string | null, zip: string | null, phoneNumber: string | null, photoURL: string | null, displayName: string | null, firstName: string | null, lastName: string | null, email: string | null, latitude: number | null, longitude: number | null, isBlocked: boolean | null, created_at: any, isAnonymous: boolean, currentappviewtype: UserType | null, referralCode: string | null } }, cartOrderItems: Array<{ __typename?: 'CartOrderItem', cartOrderItemId: number, created_at: any, updated_at: any | null, cartOrderStorefrontId: number, contemporaryCatalogDetailJSON: any, catalogItemRequestDetailJSON: any, catalogItemSellerInputJSON: any | null, sellerFulfillmentDetailJSON: any | null }> }> } | null };

export type IsUserInvoiceQueryVariables = Exact<{
  invoiceHash: Scalars['String'];
}>;


export type IsUserInvoiceQuery = { __typename?: 'Query', invoiceIsUser: boolean };

export const OrderFragmentFragmentDoc = `
    fragment OrderFragment on Order {
  id
  buyer {
    uid
    photoURL
    displayName
  }
  storefront {
    id
    businessName
    priceHigh
    priceLow
    priceType
    description
    owner {
      uid
      photoURL
      displayName
    }
  }
  status
  preferredDateEnd
  preferredDateStart
  referenceNumber
  amount
  additionalCost
  dueDate
  serviceName
  extraUnits
  completedAt
  chargedAt
  storefrontSignedAt
  buyerSignedAt
  version
  notes {
    id
    body
  }
  marketplaceCommission
  buyerPaysMarketplaceCommission
  paymentProcessingFee
  totalToBePaid
  discountCode {
    discountCodeId
    code
    discountType
    discountValue
    orderMinimumAmount
    orderDiscountAmount
    usageMessageJSON
  }
}
    `;
export const SignUpDocument = `
    mutation SignUp($user: CreateUserInput!) {
  createUserV2(user: $user) {
    type
    isRegistrationComplete
    profileJSON
    uid
    zip
    city
    streetAddress
    phoneNumber
    photoURL
    displayName
    firstName
    lastName
    email
    latitude
    longitude
    isBlocked
    created_at
    isAnonymous
    currentappviewtype
    referralCode
  }
}
    `;
export const ApplyDiscountCodeToInvoiceDocument = `
    mutation ApplyDiscountCodeToInvoice($orderId: Int!, $discountCodeId: Int, $discountCode: String!, $removed: Boolean, $appusertype: UserType) {
  applyDiscountCodetoInvoice(
    order: {id: $orderId, discountCodeId: $discountCodeId, discountCode: $discountCode}
    remove: $removed
    appusertype: $appusertype
  ) {
    orderDiscount {
      discountCodeId
      code
      discountType
      discountValue
      orderMinimumAmount
      orderDiscountAmount
      usageMessageJSON
    }
    discountRemoved {
      removed
    }
  }
}
    `;
export const CreatePaymentMethodDocument = `
    mutation CreatePaymentMethod($cardToken: String!) {
  createPaymentMethod(cardToken: $cardToken)
}
    `;
export const GetCreditCardDocument = `
    query GetCreditCard {
  creditCard {
    number
    expiry
    brand
    name
  }
}
    `;
export const PayInvoiceDocument = `
    mutation PayInvoice($order: PayOrderInput!, $appusertype: UserType) {
  payOrder(order: $order, appusertype: $appusertype) {
    id
    status
    chargedAt
  }
}
    `;
export const CancelOrderDocument = `
    mutation CancelOrder($orderId: Int!, $appusertype: UserType) {
  cancelOrder(orderId: $orderId, appusertype: $appusertype) {
    id
    status
  }
}
    `;
export const RemovePaymentMethodDocument = `
    mutation RemovePaymentMethod {
  removePaymentMethod
}
    `;
export const InvoiceDetailsDocument = `
    query InvoiceDetails($id: Int, $appusertype: UserType, $invoiceHashId: String) {
  orderV3(
    filter: {id: $id, invoiceHashId: $invoiceHashId}
    appusertype: $appusertype
  ) {
    data {
      id
      buyer {
        uid
        photoURL
        displayName
      }
      status
      created_at
      preferredDateEnd
      preferredDateStart
      amount
      additionalCost
      salesTax
      pricingDetail
      dueDate
      serviceName
      extraUnits
      selectedDate
      completedAt
      chargedAt
      storefrontSignedAt
      buyerSignedAt
      units
      referenceNumber
      outsideGeofenceRadius
      isDeposit
      version
      marketplaceCommission
      buyerPaysMarketplaceCommission
      paymentProcessingFee
      totalToBePaid
      storefront {
        id
        businessName
        priceHigh
        priceLow
        priceType
        description
        owner {
          uid
          photoURL
          displayName
          firstName
        }
      }
      notes {
        id
        body
        author {
          uid
          photoURL
          displayName
        }
      }
      discountCode {
        discountCodeId
        code
        discountType
        discountValue
        orderMinimumAmount
        orderDiscountAmount
        usageMessageJSON
      }
      buyerPaysProcessingFee
    }
  }
}
    `;
export const InvoicesDocument = `
    query Invoices($page: Int, $perPage: Int, $status: FilterOrderV2Status, $appusertype: UserType, $supportedOrderVersion: OrderVersion, $storefrontId: Int, $isNotReviewed: Boolean) {
  orderV3(
    pagination: {page: $page, perPage: $perPage}
    filter: {status: $status, storefrontId: $storefrontId, isNotReviewed: $isNotReviewed}
    appusertype: $appusertype
    supportedOrderVersion: $supportedOrderVersion
  ) {
    data {
      ...OrderFragment
      commentId
      isDeposit
    }
    pagination {
      total
      perPage
      currentPage
      maxPage
      from
      to
    }
  }
}
    ${OrderFragmentFragmentDoc}`;
export const CreateCartOrderDocument = `
    mutation CreateCartOrder($storefronts: [CartOrderStorefrontInput!]!, $emailMarketingOptIn: Boolean!) {
  createCartOrder(
    order: {storefronts: $storefronts, emailMarketingOptIn: $emailMarketingOptIn}
  ) {
    orderHeader {
      cartOrderId
      displayCartOrderId
      created_at
      updated_at
      isRequested
    }
    orderDetail {
      storefront {
        id
      }
      cartOrderSequence
      sellerOrderId
      cartOrderFlowStatus
      cartOrderItems {
        cartOrderItemId
      }
    }
  }
}
    `;
export const CartOrderFromHashIdDocument = `
    query CartOrderFromHashId($id: Int, $orderHashId: String) {
  buyerCartOrderDetail(id: $id, orderHashId: $orderHashId) {
    orderHeader {
      cartOrderId
      displayCartOrderId
      created_at
      updated_at
      isRequested
    }
    orderDetail {
      storefront {
        id
        owner {
          uid
          streetAddress
          city
          state
          zip
          phoneNumber
          photoURL
          displayName
          firstName
          lastName
          email
          latitude
          longitude
          isBlocked
          created_at
          isAnonymous
          currentappviewtype
          referralCode
        }
        businessName
        fulfillmentJSON
        visualTagsJSON
      }
      cartOrderSequence
      sellerOrderId
      cartOrderFlowStatus
      cartOrderItems {
        cartOrderItemId
        created_at
        updated_at
        cartOrderStorefrontId
        contemporaryCatalogDetailJSON
        catalogItemRequestDetailJSON
        catalogItemSellerInputJSON
        sellerFulfillmentDetailJSON
      }
    }
  }
}
    `;
export const IsUserInvoiceDocument = `
    query IsUserInvoice($invoiceHash: String!) {
  invoiceIsUser(i: $invoiceHash)
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    SignUp(variables: SignUpMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SignUpMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SignUpMutation>(SignUpDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SignUp', 'mutation');
    },
    ApplyDiscountCodeToInvoice(variables: ApplyDiscountCodeToInvoiceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ApplyDiscountCodeToInvoiceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ApplyDiscountCodeToInvoiceMutation>(ApplyDiscountCodeToInvoiceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ApplyDiscountCodeToInvoice', 'mutation');
    },
    CreatePaymentMethod(variables: CreatePaymentMethodMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreatePaymentMethodMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreatePaymentMethodMutation>(CreatePaymentMethodDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreatePaymentMethod', 'mutation');
    },
    GetCreditCard(variables?: GetCreditCardQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetCreditCardQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCreditCardQuery>(GetCreditCardDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetCreditCard', 'query');
    },
    PayInvoice(variables: PayInvoiceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PayInvoiceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PayInvoiceMutation>(PayInvoiceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PayInvoice', 'mutation');
    },
    CancelOrder(variables: CancelOrderMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CancelOrderMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CancelOrderMutation>(CancelOrderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CancelOrder', 'mutation');
    },
    RemovePaymentMethod(variables?: RemovePaymentMethodMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RemovePaymentMethodMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemovePaymentMethodMutation>(RemovePaymentMethodDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemovePaymentMethod', 'mutation');
    },
    InvoiceDetails(variables?: InvoiceDetailsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<InvoiceDetailsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<InvoiceDetailsQuery>(InvoiceDetailsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'InvoiceDetails', 'query');
    },
    Invoices(variables?: InvoicesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<InvoicesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<InvoicesQuery>(InvoicesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Invoices', 'query');
    },
    CreateCartOrder(variables: CreateCartOrderMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateCartOrderMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateCartOrderMutation>(CreateCartOrderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateCartOrder', 'mutation');
    },
    CartOrderFromHashId(variables?: CartOrderFromHashIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CartOrderFromHashIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CartOrderFromHashIdQuery>(CartOrderFromHashIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CartOrderFromHashId', 'query');
    },
    IsUserInvoice(variables: IsUserInvoiceQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IsUserInvoiceQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IsUserInvoiceQuery>(IsUserInvoiceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'IsUserInvoice', 'query');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;