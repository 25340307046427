import { Fullfillment } from '@hooks/useStores';

const fullfillmentOptionsThatRequireLocation: Fullfillment[] = [
  'Local Delivery',
  'Shipping',
  'Onsite',
];

export const shouldRenderLocation = (deliveryMethod: Fullfillment) =>
  fullfillmentOptionsThatRequireLocation.includes(deliveryMethod);
