import { orderBy } from 'lodash/fp';
import { useQuery } from 'react-query';

import { useApiClient } from '@api/apiClient';
import { InvoicesQuery, UserType } from '@api/generated/graphql';
import { useAuth } from '@hooks';

import { InvoiceState, invoiceStateByEntity } from '../constants';

export type InvoiceVM = NonNullable<NonNullable<InvoicesQuery['orderV3']>['data']>[number];

const sortOrderByInvoiceState: Record<InvoiceState, number> = {
  PENDING: 0,
  PAID: 1,
  REJECTED: 2,
};

export const useInvoices = () => {
  const auth = useAuth();
  const apiClient = useApiClient();
  return useQuery(
    ['invoices', apiClient, auth.user?.uid],
    () =>
      apiClient.Invoices({
        appusertype: UserType.Buyer,
        page: 1,
        perPage: 100,
      } as any),
    {
      refetchInterval: 60000,
      enabled: Boolean(auth.user),
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      select: (res) =>
        orderBy(
          (item) => {
            const invoiceState = invoiceStateByEntity[item.status!];
            return sortOrderByInvoiceState[invoiceState];
          },
          'asc',
          res.orderV3?.data ?? []
        ),
    }
  );
};
