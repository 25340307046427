import Image from 'next/image';
import { ChangeEvent, ReactNode, RefObject, useState } from 'react';
import { Button, Form, FormGroup, InputGroup, Spinner } from 'react-bootstrap';

export const ConversationDetailsFooter = ({
  onSubmit,
  hasAttachments,
  placeholder,
  disabled,
  inputRef,
  submitButtonText,
  submitButtonClassNames,
}: {
  submitButtonClassNames?: string;
  onSubmit: (val: string, files?: File[]) => unknown;
  hasAttachments?: boolean;
  placeholder: string;
  disabled?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  submitButtonText: ReactNode;
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);

  const onSelectFiles = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (fileList) {
      const newFiles = [...files];
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList.item(i);
        if (file) {
          newFiles.push(file);
        }
      }
      setFiles(newFiles);
    }
  };

  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    if (value.trim().length || files.length) {
      setLoading(true);
      await onSubmit(value, files);
      setLoading(false);
      setValue('');
      setFiles([]);
    }
  };

  return (
    <>
      {loading && files.length > 0 && <div className="mb-2">Message sending...</div>}
      <div className="d-flex gap-2">
        {files.map((file, index) => (
          <div key={index} className="position-relative mb-2" style={{ width: 64, height: 64 }}>
            <Image
              src={URL.createObjectURL(file)}
              alt={file.name}
              fill
              style={{ objectFit: 'cover', objectPosition: 'center' }}
            />
            <i
              className="fi-x-circle position-absolute text-white bg-warning rounded-circle cursor-pointer"
              onClick={() => handleRemoveFile(index)}
              style={{
                right: -5,
                top: -5,
              }}
            />
          </div>
        ))}
      </div>

      <FormGroup className="py-1 w-100 form-group d-block rounded-pill shadow-none">
        <InputGroup className="border-dark ms-0">
          {hasAttachments ? (
            <Form.Group className="d-flex mt-1 ms-1">
              <Form.Label>
                <i className="fi-upload-file text-primary cursor-pointer" />
                <Form.Control
                  type="file"
                  className="d-none"
                  multiple
                  accept=".jpg, .jpeg, .png,"
                  onChange={onSelectFiles}
                />
              </Form.Label>
            </Form.Group>
          ) : (
            <div className="d-flex align-items-center ms-2">
              <i className="fi-chat-circle text-primary" />
            </div>
          )}

          <Form.Control
            onChange={(e) => setValue(e.target.value)}
            onKeyUp={(e) => (e.key === 'Enter' ? handleSubmit() : null)}
            value={value}
            size="sm"
            ref={inputRef}
            placeholder={placeholder}
          />
          <Button
            size="sm"
            onClick={() => handleSubmit()}
            variant="text-primary rounded-pill gap-2 d-flex align-items-center"
            className={`${submitButtonClassNames}`}
            disabled={loading || disabled}
            style={{ backgroundColor: '#FCADE2' }}
          >
            {loading && <Spinner animation="border" role="status" size="sm" />}
            {submitButtonText}
          </Button>
        </InputGroup>
      </FormGroup>
    </>
  );
};
