import algoliasearch from 'algoliasearch/lite';
import type { AutocompletionRequest } from 'react-google-places-autocomplete/build/GooglePlacesAutocomplete.types';

export const searchIndex = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_INDEX;

export const seasonalCategoryId: number = parseInt(process.env.NEXT_PUBLIC_SEASONAL_CATEGORY);
export const whatsNewIndex: string = process.env.NEXT_PUBLIC_ALGOLIA_WHATSNEW_INDEX;
export const whatsNewDays: number = parseInt(process.env.NEXT_PUBLIC_WHATSNEW_DAYS);
export const whatsNewCategoryId: number = parseInt(process.env.NEXT_PUBLIC_WHATSNEW_CATEGORY);
export const whatsNewPrecision: number = parseInt(process.env.NEXT_PUBLIC_WHATSNEW_PRECISION);
export const siteUrl: string = process.env.SITE_URL!;
export const siteDomain: string = process.env.NEXT_PUBLIC_SITE_URL;

export const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_API_KEY
);

export const breakpoints = {
  xs: '0',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
} as const;

export const defaultStoreOwnerImageSrc = '/images/owner-placeholder.png';

export const autocompleteRequestConfig: AutocompletionRequest = {
  componentRestrictions: { country: 'us' },
  types: ['(regions)'],
};

export const isClient = typeof window !== 'undefined';

export const DEFAULT_ERROR_MESSAGE = `Something went wrong. We're working on it and will have it fixed soon.`;
