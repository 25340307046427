import { useEffect } from 'react';

import { ScreenName, trackMixpanelEvent } from '@packages/event-tracking';

import { InvoiceEventName, PayInvoiceEventPayload } from './types';
import { InvoiceDetailsVM } from '../useInvoiceDetails';

export const payInvoicePubSub = {
  publish: (screen: ScreenName) => {
    PubSub.publish(InvoiceEventName.PayInvoice, screen);
  },
  subscribe: (fn: (data: ScreenName) => void) =>
    PubSub.subscribe(InvoiceEventName.PayInvoice, (_, payload) => fn(payload)),
};

export const useTrackPayInvoice = (invoice?: InvoiceDetailsVM) => {
  useEffect(() => {
    const payInvoiceUnsubscribeToken = payInvoicePubSub.subscribe((screen) => {
      const payload: PayInvoiceEventPayload = {
        action: 'press',
        element: 'button',
        isWeb: true,
        module: 'Invoice Detail',
        screen,
        eventType: 'Pay Invoice',
        component: 'Invoice',
        buyerName: invoice?.buyer.displayName ?? '',
        businessName: invoice?.storefront.businessName ?? '',
        sellerName: invoice?.storefront.owner.displayName ?? '',
        isDeposit: invoice?.isDeposit ?? false,
        orderAmount: invoice?.amount ?? 0,
        additionalCosts: invoice?.additionalCost ?? 0,
        salesTax: invoice?.salesTax ?? 0,
        paymentProcessingFee: invoice?.paymentProcessingFee ?? 0,
        marketplaceCommission: invoice?.marketplaceCommission ?? 0,
        discountCodeUsed: invoice?.discountCode?.code ?? '', // (value of the discount code, not a boolean)
        discountCodeApplies: invoice?.discountCode?.orderDiscountAmount ?? 0,
      };
      trackMixpanelEvent('Invoice Details', payload);
    });

    return () => {
      PubSub.unsubscribe(payInvoiceUnsubscribeToken);
    };
  }, [invoice]);
};
