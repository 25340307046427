import { useQuery } from 'react-query';

import { useApiClient } from '@api/apiClient/getApiClient';

export const useCreditCard = () => {
  const apiClient = useApiClient();
  return useQuery(['creditCard', apiClient], () => apiClient.GetCreditCard(), {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    select: (res) => res.creditCard?.[0],
  });
};
