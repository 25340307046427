import Link from 'next/link';
import { ReactNode } from 'react';
import { Container, Row } from 'react-bootstrap';

type HomeBannerProps = { title: string; description: string; extra?: ReactNode };

export const HomeBannerWithoutImage = ({ title, description, extra }: HomeBannerProps) => {
  return (
    <section className="rounded-bottom overflow-hidden">
      <Container>
        <Row style={{ textAlign: 'center', justifyContent: 'center', paddingBottom: '20px' }}>
          <Link href="/browse/category/fresh" passHref style={{ textDecoration: 'none' }}>
            <div className="display-5 text-dark fw-bold cursor-pointer my-3">{title}</div>
          </Link>
          <div className="font-serif mb-5 fs-5 fw-bolder text-brownsugar my-3 px-4">
            {description}
          </div>
          {extra}
        </Row>
      </Container>
    </section>
  );
};
