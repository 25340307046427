import { makeGqlCall } from './makeGqlCall';

const meQuery = /* GraphQL */ `
  {
    me {
      type
      isRegistrationComplete
      zip
      city
      state
      streetAddress
      phoneNumber
      photoURL
      displayName
      firstName
      lastName
      email
      profileJSON
    }
  }
`;

export type UserType = 'BUYER' | 'SELLER' | 'ADMIN' | 'AMBASSADOR';

export type FetchMeResponse = {
  me?: {
    type: UserType;
    isRegistrationComplete?: boolean;
    zip?: string;
    city?: string;
    state?: string;
    streetAddress?: string;
    phoneNumber?: string;
    photoURL?: string;
    displayName?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    profileJSON?: {
      emailMarketingOptIn?: boolean;
    };
  };
};

export const fetchUserQuery = makeGqlCall<FetchMeResponse, undefined>(meQuery);
