import { Col, Container, Row, Stack } from 'react-bootstrap';

import { ImageLoader } from './ImageLoader';
import { MarketButton } from './MarketButton';

export const DownloadApp = () => {
  return (
    <div className="bg-primary">
      <Container>
        <Row className="px-0 px-md-6 align-items-center">
          <Col
            xl={{ span: 6 }}
            lg={{ span: 7 }}
            md={{ span: 12 }}
            className="my-6 text-light text-center text-md-start"
          >
            <h2 className="text-light font-title display-2">
              The best of West
              <br />
              Tenth is in the app.
            </h2>
            <p>
              Explore new businesses, message with sellers directly, and pay orders securely all in
              one place — the West Tenth app.
            </p>
            <div className="mt-2 d-flex justify-content-center justify-content-md-start">
              <Stack direction="horizontal" gap={3}>
                <MarketButton href={process.env.NEXT_PUBLIC_DOWNLOAD_APPLE_URL} market="apple" />
                <MarketButton
                  href={process.env.NEXT_PUBLIC_DOWNLOAD_ANDROID_URL}
                  market="android"
                />
              </Stack>
            </div>
          </Col>
          <Col
            xl={{ span: 4, offset: 2 }}
            lg={{ span: 5 }}
            className="d-flex align-items-center d-none d-lg-block"
          >
            <ImageLoader src={'/images/footer.png'} width={400} height={400} alt={'download app'} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
