import { useEffect } from 'react';

export const useReloadOnPopState = (pages: string[]) => {
  useEffect(() => {
    const handleRouteChange = () => {
      if (
        typeof window !== 'undefined' &&
        pages.some((page) => window.location.href.includes(page))
      )
        window.location.reload();
    };
    window.addEventListener('popstate', handleRouteChange);
    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, [pages]);
};
