import { Card, Col, Container, Row } from 'react-bootstrap';
import { EffectFade, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Pencil1Svg } from '@components/Icon/Pencil1Svg';
import { Star3Svg } from '@components/Icon/Star3Svg';
import { FeaturedIn } from '@screens/HomeScreen/FeaturedIn';

const useQuotes = () => [
  {
    description: `This is a brilliant and much-needed marketplace for home-based professionals and makers.`,
    author: 'Emily C',
  },
  {
    description: `On West Tenth, I know I am going to find something special.`,
    author: 'Danielle B',
  },
  {
    description: `Working with a trusted interior designer from West Tenth made the process stress-free.`,
    author: 'Nora A',
  },
  {
    description: `I am so grateful I discovered West Tenth. Their bakers are so incredibly talented, I am always blown away.`,
    author: 'Rachel M',
  },
  {
    description: `My life got a whole lot easier once I started using West Tenth. I love buying local and this marketplace makes it so easy.`,
    author: 'Sara S',
  },
  {
    description: `I absolutely love West Tenth! It is such a fast and convenient way to find what you’re looking for, right in your own city or neighborhood.`,
    author: 'Lorraine M',
  },
  {
    description: `Great way to support local businesses!`,
    author: 'Rebekah S',
  },
  {
    description: `I’m saving time, getting something unique, and supporting local.`,
    author: 'Allie B',
  },
];

export const Quotes = () => {
  const quotes = useQuotes();
  return (
    <Container>
      <Row>
        <Col>
          <div className="position-relative rounded p-2 py-5 p-md-5 bg-primary">
            <Star3Svg
              fill="#BAF915"
              style={{ position: 'absolute', top: '40px', left: '60px', zIndex: 3 }}
              className="d-none d-md-block"
            />
            <Star3Svg
              fill="#BAF915"
              style={{ position: 'absolute', top: '-60px', left: '-10px', zIndex: 3 }}
              className="d-md-none"
            />
            <Pencil1Svg
              fill="#F4F3E3"
              className="d-none d-lg-block"
              style={{ position: 'absolute', top: '280px', right: '80px', zIndex: 3 }}
            />

            <div className="text-center fs-xs text-uppercase text-light">Kind Words</div>

            <Row>
              <Col
                xs={{ span: 12, offset: 0 }}
                sm={{ span: 10, offset: 1 }}
                lg={{ span: 8, offset: 2 }}
                xl={{ span: 6, offset: 3 }}
              >
                <div className="swiper-pagination-light">
                  <Swiper
                    modules={[EffectFade, Pagination]}
                    effect="fade"
                    pagination={{
                      el: '#bullets',
                      clickable: true,
                    }}
                    autoHeight
                    slidesPerView={1}
                    className="mt-2 mt-md-5 mb-2"
                    noSwipingClass="yarl__slide"
                  >
                    {quotes.map((quote) => (
                      <SwiperSlide key={quote.description} className="text-center">
                        {({ isActive }) => (
                          <Card
                            className="bg-primary border-0"
                            style={{ opacity: isActive ? 1 : 0 }}
                          >
                            <Card.Body>
                              <div className="display-3 font-title" style={{ color: '#DEB4D3' }}>
                                &ldquo;{quote.description}&rdquo;
                              </div>
                              <footer className="mt-3">
                                <span className="text-light font-serif">{quote.author}</span>
                              </footer>
                            </Card.Body>
                          </Card>
                        )}
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <div
                    id="bullets"
                    className="swiper-pagination position-relative bottom-0 mb-lg-3"
                  ></div>
                </div>
              </Col>
            </Row>

            <div className="mt-5">
              <FeaturedIn />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
