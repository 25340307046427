import { useEffect } from 'react';

import { useCurrentScreen } from '@hooks/useCurrentScreen';
import { ScreenName, trackMixpanelEvent } from '@packages/event-tracking';

import { AddCreditCardEventPayload, InvoiceEventName } from './types';

export const addCreditCardPubSub = {
  publish: (screen: ScreenName) => {
    const payload: AddCreditCardEventPayload = {
      action: 'press',
      component: 'Credit Card',
      element: 'button',
      eventType: 'Add Credit Card',
      isWeb: true,
      module: 'Invoice Detail',
      screen: screen,
    };
    PubSub.publish(InvoiceEventName.AddCreditCard, payload);
  },
  subscribe: (fn: (data: AddCreditCardEventPayload) => void) =>
    PubSub.subscribe(InvoiceEventName.AddCreditCard, (_, payload) => fn(payload)),
};

export const useTrackAddCreditCard = () => {
  const { screen: currentScreen } = useCurrentScreen();
  useEffect(() => {
    const addCreditCardUnsubscribeToken = addCreditCardPubSub.subscribe((data) => {
      trackMixpanelEvent('Invoice Details', data);
    });

    return () => {
      PubSub.unsubscribe(addCreditCardUnsubscribeToken);
    };
  }, [currentScreen]);
};
