import { useMemo } from 'react';
import { Col, Form, Row, Stack } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import { useUpdateEffect } from 'react-use';

import { AddCreditCardVM } from './addCreditCardValidationSchema';
import { CardExpiry } from './CardExpiry';
import { getCardNumberMask, getCreditCardType, getCvvMask } from './utils';

export const AddCreditCardForm = () => {
  const { watch, control, formState, setValue } = useFormContext<AddCreditCardVM>();
  const { errors } = formState;
  const cardNumber = watch('cardNumber') ?? '';
  const cvv = watch('cvv') ?? '';

  const creditCardType = useMemo(() => getCreditCardType(cardNumber), [cardNumber]);

  const creditCardNumberMask = getCardNumberMask(creditCardType);
  const cvvMask = getCvvMask(creditCardType);
  useUpdateEffect(() => {
    if (creditCardType !== 'amex') {
      setValue('cvv', cvv.slice(0, 3));
    }
  }, [creditCardType]);
  return (
    <Stack direction="vertical" gap={3}>
      <div className="w-100 d-flex">
        <Controller
          control={control}
          name="cardholderName"
          render={({ field: { onChange, name, value } }) => (
            <Form.Label label="Card Holder Name" className="flex-grow-1 mb-0">
              <Form.Control
                isInvalid={!!formState.errors.cardholderName}
                placeholder="Card Holder Name"
                onChange={onChange}
                name={name}
                value={value}
              />
              <Form.Control.Feedback type="invalid">
                {errors.cardholderName?.message}
              </Form.Control.Feedback>
            </Form.Label>
          )}
        />
      </div>
      <Row>
        <Col sm={12} xs={12} lg={12} xl={12} className="d-flex">
          <Controller
            control={control}
            name="cardNumber"
            render={({ field: { onChange, name, value } }) => (
              <Form.Label label="Card number" className="flex-grow-1 mb-0">
                <Form.Control
                  as={PatternFormat}
                  isInvalid={!!formState.errors.cardNumber}
                  format={creditCardNumberMask}
                  mask="_"
                  placeholder="Card number"
                  onChange={onChange}
                  name={name}
                  value={value}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.cardNumber?.message}
                </Form.Control.Feedback>
              </Form.Label>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Controller
            control={control}
            name="cvv"
            render={({ field: { onChange, name, value } }) => (
              <Form.Label label="CVV" className="flex-grow-1 w-100 mb-0">
                <Form.Control
                  as={PatternFormat}
                  isInvalid={!!formState.errors.cvv}
                  format={cvvMask}
                  mask="_"
                  placeholder="CVV"
                  onChange={onChange}
                  name={name}
                  value={value}
                />
                <Form.Control.Feedback type="invalid">{errors.cvv?.message}</Form.Control.Feedback>
              </Form.Label>
            )}
          />
        </Col>
        <Col xs={6}>
          <Controller
            control={control}
            name="expiryDate"
            render={({ field: { onChange, name, value } }) => (
              <Form.Label label="Exp. Date" className="flex-grow-1 w-100 mb-0">
                <Form.Control
                  as={CardExpiry}
                  isInvalid={!!formState.errors.expiryDate}
                  placeholder="Exp. Date"
                  mask="_"
                  onChange={onChange}
                  name={name}
                  value={value}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.expiryDate?.message}
                </Form.Control.Feedback>
              </Form.Label>
            )}
          />
        </Col>
      </Row>
    </Stack>
  );
};
