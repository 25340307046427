import { useCallback } from 'react';
import { useLocalStorage } from 'react-use';

import { isClient } from '@constants';
import { useAuth, userIdStoreKey } from '@hooks/useAuth';
import { waitForIsClient } from '@utils/waitForIsClient';

export type UserDetailsPayload = {
  isWeb: true;
  userId?: string | undefined;
  'is anonymous': boolean;
};

export const useGetUserDetailsPayload = () => {
  const auth = useAuth();
  const [userIdFromStorage] = useLocalStorage(userIdStoreKey, null);
  const userId = auth.user?.uid || (isClient ? userIdFromStorage : null);

  return useCallback(async (): Promise<UserDetailsPayload> => {
    await waitForIsClient;
    return { isWeb: true, userId: userId ?? undefined, 'is anonymous': !userId };
  }, [userId]);
};
