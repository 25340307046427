import Link from 'next/link';
import { ReactNode, useLayoutEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { useIsLaptopOrDesktop } from '@hooks';

type HomeBannerProps = { image: string; title: string; description: string; extra?: ReactNode };

export const HomeBanner = ({ image, title, description, extra }: HomeBannerProps) => {
  const isDesktop = useIsLaptopOrDesktop();
  const [isClient, setIsClient] = useState(false);

  useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      setIsClient(true);
    }
  }, []);

  return (
    <section className="bg-white rounded-bottom overflow-hidden">
      <div
        style={
          isClient
            ? isDesktop
              ? {
                  backgroundImage: `url(${image})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'right',
                  backgroundSize: 'contain',
                  marginBottom: 10,
                }
              : undefined
            : undefined
        }
        className="d-flex align-items-center"
      >
        {isDesktop && (
          <Link href="/browse/parties-events" passHref>
            <div className="home-hero-image"></div>
          </Link>
        )}
        <Container>
          <Row>
            <Col
              xs={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 7 }}
              className="px-lg-5 pt-5 d-flex flex-column justify-content-center align-self-end"
            >
              <Link href="/browse/category/fresh" passHref style={{ textDecoration: 'none' }}>
                <div className="fmw-700 display-5 text-dark fw-bold cursor-pointer">{title}</div>
              </Link>
              <div className="font-serif mb-5 fs-5 fw-bolder text-brownsugar my-3">
                {description}
              </div>
              {extra}
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};
