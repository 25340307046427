import { CancellablePromise, Message } from '@twilio/conversations';
import { union } from 'lodash/fp';
import { CSSProperties, memo, useEffect, useState } from 'react';

import { ImageLoader } from '@components';

type MessageBubbleProps = {
  direction?: 'outgoing' | 'incoming';
  className?: string;
  message: Message;
  style?: CSSProperties;
  onImageClick: (imageUrls: string[], idx: number) => void;
  onLoad: () => void;
};

export const MessageBubble = memo(
  ({
    message,
    direction = 'outgoing',
    className = '',
    style,
    onImageClick,
    onLoad,
  }: MessageBubbleProps) => {
    const [media, setMedia] = useState<{ isReady: boolean; media: string[] }>({
      isReady: false,
      media: (message.attachedMedia ?? [])?.map(() => '/images/storefront-image-placeholder.png'),
    });

    useEffect(() => {
      let mounted = true;
      let res: CancellablePromise<string | null>[] = [];
      (async () => {
        const mediaUrlPromises = (message.attachedMedia ?? []).map((media) =>
          media.getContentTemporaryUrl()
        );
        res = mediaUrlPromises;
        const mediaUrls = await Promise.all(mediaUrlPromises);
        const newMedia = mediaUrls.filter(Boolean) as string[];
        const shouldUpdateMedia =
          newMedia.length && union(newMedia, media.media).length !== media.media.length;
        if (shouldUpdateMedia && mounted) {
          setMedia({ isReady: true, media: newMedia });
        }
      })();
      return () => {
        mounted = false;
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <>
        <div
          style={style}
          className={`px-3 py-2 text-dark rounded-3 m-0 w-75 text-break ${
            direction === 'incoming'
              ? 'bg-periwinkle text-primary rounded-top-left-0'
              : 'bg-purple text-white rounded-top-right-0 align-self-end'
          } ${className}`}
        >
          {message.body}
          {media.media.length ? (
            <div className="d-flex gap-2 flex-wrap">
              {media.media.map((med, idx) => (
                <ImageLoader
                  onClick={() => onImageClick(media.media, idx)}
                  onLoad={onLoad}
                  key={media.isReady ? med : idx}
                  src={med}
                  alt="sent image"
                  className="cursor-pointer"
                  style={{ objectFit: 'contain' }}
                  width={250}
                  height={175}
                />
              ))}
            </div>
          ) : null}
        </div>
      </>
    );
  }
);

MessageBubble.displayName = 'MessageBubble';
