export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_META_PIXEL;

export const pageView = () => {
  window.fbq('track', 'PageView');
};

export const searchEvent = (options: { customProperties: unknown; search_string: string }) => {
  window.fbq('track', 'Search', options);
};

export const initiateCheckoutEvent = (options: { customProperties: unknown }) => {
  window.fbq('track', 'InitiateCheckout', options);
};

export const addToCartEvent = (options: {
  currency: 'USD';
  num_items: number;
  customProperties: unknown;
  value: unknown;
}) => {
  window.fbq('track', 'AddToCart', options);
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const purchaseEvent = (options: {
  currency: 'USD';
  value: number;
  num_items: number;
  customProperties: unknown;
}) => {
  window.fbq('track', 'Purchase', options);
};

export const contactEvent = (options = {}) => {
  window.fbq('track', 'Contact', options);
};
