import { z } from 'zod';

import { validationMessages } from '@constants';
import { Fullfillment } from '@hooks';

import { CUSTOM_TIMEFRAME } from './availableTimeframeOptions';
import { shouldRenderLocation } from './shouldRenderLocation';

export const removeSignsAndPhoneCode = (phone: string) => phone.replace(/\D/gim, '').slice(1);
const phoneRegex = /\+1\(([0-9]{3})\) ([0-9]{3})-([0-9]{4})/;
export const PhoneSchema = z
  .string()
  .regex(phoneRegex, validationMessages.phone)
  .transform(removeSignsAndPhoneCode);

const zipCodeRegex = /([0-9]{5})/;
export const ZipCodeSchema = z.string().regex(zipCodeRegex, validationMessages.zipCode);

export const userFirstNameSchema = z.string().trim().min(1, validationMessages.required).max(100);
export const userLastNameSchema = z.string().trim().min(1, validationMessages.required).max(100);

export const UserSchema = z.object({
  firstName: userFirstNameSchema,
  lastName: userLastNameSchema,
  phoneNumber: PhoneSchema,
  email: z.string().trim().email(),
  zip: ZipCodeSchema,
});

export const isValidDate = (d: Date) => d instanceof Date && !isNaN(+d);

export const isInThePast = (date: Date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return date < today;
};

export const getTimeframeValidationSchema = ({
  isTimeframeRequired,
}: {
  isTimeframeRequired: boolean;
}) =>
  z
    .object({
      value: z.string().trim().optional().nullable(),
      customValue: z.string().trim().optional().nullable(),
    })
    .refine(
      (timeframe) => {
        if (isTimeframeRequired && !timeframe.value) {
          return false;
        }
        if (timeframe.value === CUSTOM_TIMEFRAME && !timeframe.customValue) {
          return false;
        }
        return true;
      },
      {
        message: validationMessages.required,
      }
    )
    .refine(
      (timeframe) => {
        if (timeframe.value === CUSTOM_TIMEFRAME && timeframe.customValue) {
          const date = new Date(timeframe.customValue);
          return date && isValidDate(date) && !isInThePast(date);
        }
        return true;
      },
      {
        message: "Selected timeframe can't be in the past",
      }
    );

export const getQuoteSchema = (isQuickShop: boolean) =>
  z.object({
    note: isQuickShop
      ? z.string().trim().optional()
      : z.string().trim().min(1, validationMessages.required),
    timeframe: getTimeframeValidationSchema({ isTimeframeRequired: isQuickShop }),
    delivery: isQuickShop
      ? z
          .object({
            method: z.string().trim().nullable().optional(),
            location: z.string().trim().optional(),
          })
          .refine((delivery) => Boolean(delivery.method), {
            message: validationMessages.deliveryMethodRequired,
          })
          .refine(
            (delivery) => {
              if (shouldRenderLocation(delivery.method as Fullfillment) && !delivery.location) {
                return false;
              }
              return true;
            },
            {
              message: validationMessages.locationRequired,
            }
          )
      : z.object({
          method: z.string().trim().nullable().optional(),
          location: z.string().trim().optional(),
        }),
  });

const getRegisterSchema = (isQuickShop: boolean) =>
  getQuoteSchema(isQuickShop).merge(z.object({ user: UserSchema }));

const quantitySchema = z.number().min(1).int();
export const getContactFormSchema = (isRegister: boolean, isQuickShop = false) => {
  const validationSchema = isRegister
    ? getRegisterSchema(isQuickShop)
    : getQuoteSchema(isQuickShop);

  if (isQuickShop) {
    return validationSchema.merge(z.object({ quantity: quantitySchema }));
  }

  return validationSchema;
};

export const addItemToCartFormSchema = getQuoteSchema(true).merge(
  z.object({ quantity: quantitySchema })
);

// TODO contact form shouldn't know about cart
export type AddItemToCartFormVM = {
  timeframe: z.infer<ReturnType<typeof getTimeframeValidationSchema>>;
  delivery: {
    method?: Fullfillment;
    location?: string;
  };
  quantity?: number;
  note: string;
};

export type UserInfoFormGroupVM = z.infer<typeof UserSchema>;

export type ContactFormVM = AddItemToCartFormVM & {
  user?: UserInfoFormGroupVM;
};
