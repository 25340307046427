import axios from 'axios';
import { get } from 'lodash/fp';

type CreateCardEntity = {
  cvc: string;
  exp_month: string;
  exp_year: string;
  name: string;
  number: string;
};

type CreateCardError = {
  response: {
    data?: {
      message?: string;
    };
  };
};

export const isCreateCardError = (res: unknown): res is CreateCardError =>
  Boolean(get(['response', 'data', 'message'], res));

type CreateCardResponse = {
  data: {
    id: string;
  };
};
export const createCard = (values: CreateCardEntity) =>
  axios.post<CreateCardEntity, CreateCardResponse>('/api/createCard', values);
