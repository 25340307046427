import { useMutation, useQueryClient } from 'react-query';

import { useApiClient } from '@api/apiClient';
import { UserType } from '@api/generated/graphql';
import { useCurrentScreen } from '@hooks';

import { payInvoicePubSub } from '../../../useTrackInvoiceDetailsEvents';

export const usePayInvoice = () => {
  const queryClient = useQueryClient();
  const { screen } = useCurrentScreen();
  const apiClient = useApiClient();
  return useMutation(
    ({ id }: { id: number }) =>
      apiClient.PayInvoice({
        order: {
          id,
        },
        appusertype: UserType.Buyer,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(['invoices']);
        queryClient.refetchQueries(['invoiceDetails']);
        payInvoicePubSub.publish(screen!);
      },
    }
  );
};
