import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import {
  isSubscribeNewsletterError,
  subscribeNewsletter,
  SubscribeNewsletterEntity,
} from './subscribeNewsletter';

const ERROR_MESSAGE = `Cannot subscribe you to newsletter list.`;

const getErrorMessage = (error: unknown) => {
  return (isSubscribeNewsletterError(error) && error?.response?.data?.message) || ERROR_MESSAGE;
};
export const useSubscribeNewsletter = () => {
  const subscribe = useCallback(async (values: SubscribeNewsletterEntity) => {
    await subscribeNewsletter(values);
  }, []);

  return useMutation(subscribe, {
    onSuccess: () => {
      toast.success('You successfully subscribed', {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    onError: (error) => {
      console.log('eeeeee', error);
      toast.error(getErrorMessage(error), {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  });
};
