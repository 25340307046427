import { get } from 'lodash/fp';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { useApiClient } from '@api/apiClient';
import { fetchUserQuery } from '@api/fetchUser';
import { CreateUserInput, UserType } from '@api/generated/graphql';
import { useAuth } from '@hooks/useAuth';

type SignUpVM = Pick<
  CreateUserInput,
  'email' | 'firstName' | 'lastName' | 'password' | 'phoneNumber' | 'zip'
>;

type ErrorResponse = {
  response: {
    errors: Array<{
      message: string;
    }>;
  };
};

const isGraphqlError = (res: unknown): res is ErrorResponse => {
  return get(['response', 'errors', 'length'], res);
};
const parseError = (error: unknown) =>
  isGraphqlError(error) ? error.response.errors?.[0].message : 'Something went wrong';

export const useSignUp = (onSuccess: () => void) => {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const apiClient = useApiClient();

  return useMutation(
    (user: SignUpVM) => apiClient.SignUp({ user: { ...user, type: UserType.Buyer } }),
    {
      onSuccess: async (_, data) => {
        await auth.signOut();
        await auth.signInWithEmailAndPassword(data.email, data.password);
        queryClient.refetchQueries(['invoices']);
        queryClient.refetchQueries([fetchUserQuery.name]);
        onSuccess();
      },
      onError: (err) => {
        toast.error(parseError(err), {
          position: toast.POSITION.TOP_RIGHT,
        });
      },
    }
  );
};
