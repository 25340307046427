import { makeGqlCall } from './makeGqlCall';

const createPasswordQuery = /* GraphQL */ `
  mutation ($user: UpdateUserRegistrationInput!) {
    registeredUserUpdate(user: $user) {
      success
    }
  }
`;

type CreatePasswordResponse = {
  registeredUserUpdate: {
    success: boolean;
  };
};

type CreatePasswordVariables = {
  user: {
    password: string;
  };
};

export const createPasswordMutation = makeGqlCall<CreatePasswordResponse, CreatePasswordVariables>(
  createPasswordQuery
);
