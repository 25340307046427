import { ReactNode, useEffect, useState } from 'react';
import { CloseButton, Modal, ModalProps } from 'react-bootstrap';
import { useUpdateEffect } from 'react-use';

import { ForgotPasswordForm } from './ForgotPasswordForm';
import { SignInForm } from './SignInForm';
import { SignUpForm } from './SignUpForm';

export type AuthMethod = 'signIn' | 'signUp' | 'forgotPassword';

type AuthModalProps = ModalProps & {
  onSuccess?: () => void;
  activeAuthMethod: AuthMethod;
  signInFormProps?: {
    title: ReactNode;
    subTitle?: string;
  };
  signUpFormProps?: {
    title: ReactNode;
    subTitle?: string;
  };
};

export const AuthModal = (props: AuthModalProps) => {
  const [authMethod, setAuthMethod] = useState(props.activeAuthMethod);

  useEffect(() => {
    if (props.show === false && authMethod === 'forgotPassword') {
      setAuthMethod('signIn');
    }
  }, [props.show]);

  useUpdateEffect(() => {
    setAuthMethod(props.activeAuthMethod);
  }, [props.activeAuthMethod]);

  const toggleAuthMethod = () => setAuthMethod((prev) => (prev === 'signIn' ? 'signUp' : 'signIn'));

  return (
    <Modal centered {...props} className="auth-modal">
      <Modal.Body className="px-0 py-2 py-sm-0">
        <CloseButton
          onClick={props.onHide}
          aria-label="Close modal"
          className="position-absolute top-0 end-0 mt-3 me-3"
        />
        <div className="row mx-0 align-items-center">
          <div className="col-md-12 px-4 pt-4 pb-4 px-sm-5 pb-sm-5 pt-md-5">
            <div className="mx-auto">
              {authMethod === 'signIn' ? (
                <SignInForm
                  {...(props.signInFormProps ?? {
                    title: 'Sign In',
                  })}
                  style={{ width: 420, maxWidth: '100%' }}
                  onSuccess={() => {
                    props.onHide?.();
                    props.onSuccess?.();
                  }}
                  onToggle={toggleAuthMethod}
                  onForgotPassword={() => setAuthMethod('forgotPassword')}
                />
              ) : null}
              {authMethod === 'signUp' ? (
                <SignUpForm
                  style={{ width: 420, maxWidth: '100%' }}
                  {...(props.signUpFormProps ?? {
                    title: 'Sign Up',
                  })}
                  onSuccess={() => {
                    props.onHide?.();
                    props.onSuccess?.();
                  }}
                  onToggle={toggleAuthMethod}
                />
              ) : null}
              {authMethod === 'forgotPassword' ? (
                <ForgotPasswordForm
                  style={{ width: 420, maxWidth: '100%' }}
                  onToggle={toggleAuthMethod}
                />
              ) : null}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
