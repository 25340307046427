import { useCallback, useRef } from 'react';

export function useScrollToTop() {
  const ref = useRef<HTMLDivElement>(null);
  return [
    ref,
    useCallback((behavior: ScrollBehavior = 'smooth') => {
      if (ref.current) {
        ref.current.scrollTo({
          behavior,
          top: 0,
        });
      }
    }, []),
  ] as const;
}
