import { Nav } from 'react-bootstrap';

import { SignInButton } from '@components/SignInButton';
import { useLoggedInUser } from '@hooks/useAuth';
import { useCurrentScreen } from '@hooks/useCurrentScreen';
import { ScreenName } from '@packages/event-tracking';

export const AuthNav = () => {
  const { screen: currentScreen } = useCurrentScreen();
  const { user, initializing } = useLoggedInUser();

  if (initializing) {
    return null;
  }

  return user ? (
    <Nav.Link href="/profile" active={currentScreen === ScreenName.ProfileManagement}>
      Hi, {user.displayName?.split(' ')?.[0] ?? ''}
    </Nav.Link>
  ) : (
    <SignInButton>
      {({ showSignInModal }) => <Nav.Link onClick={showSignInModal}>Login</Nav.Link>}
    </SignInButton>
  );
};
