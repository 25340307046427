import { ReactNode } from 'react';
import { Col, Row } from 'react-bootstrap';

export const TwoColumnLayout = ({ left, right }: { left: ReactNode; right: ReactNode }) => {
  return (
    <Row>
      <Col
        xs={{ span: 12, order: 1 }}
        md={{ span: 12, order: 0 }}
        lg={{ span: 7, order: 0 }}
        className="position-relative my-5"
      >
        {left}
      </Col>
      <Col
        xs={{ span: 12, order: 0 }}
        md={{ span: 12, order: 1 }}
        lg={{ span: 5, order: 1 }}
        style={{ zIndex: 10 }}
        className="mb-0 my-lg-5"
      >
        {right}
      </Col>
    </Row>
  );
};
