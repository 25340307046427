import PubSub from 'pubsub-js';
import { useState } from 'react';
import { Button, Form, FormGroup, InputGroup } from 'react-bootstrap';

import { useSubscribeNewsletter } from '@hooks/useSubscribeNewsletter/useSubscribeNewsletter';
import { EventName } from '@packages/event-tracking';

export const FooterNewsletter = ({ theme = 'light' }: { theme?: 'light' | 'dark' }) => {
  const textColor = theme === 'light' ? 'text-primary' : 'text-light';
  const bgColor = theme === 'light' ? 'bg-light' : 'bg-primary';

  const [value, setValue] = useState('');
  const { mutateAsync: subscribeNewsletter, isLoading } = useSubscribeNewsletter();

  const handleSubscribe = async (val: string) => {
    // PubSub.publish(EventName.SearchStores, val);
    await subscribeNewsletter({ email: val });
    setValue('');
  };

  return (
    <>
      <div className="text-uppercase fw-bolder">Newsletter</div>
      <FormGroup
        className={`p-0 w-100 form-group d-block shadow-none border-0 border-bottom rounded-0 ${bgColor} ${
          theme === 'light' ? 'border-primary' : 'border-light'
        }`}
      >
        <InputGroup className="display-flex align-items-center ms-0">
          <Form.Control
            onChange={(e) => setValue(e.target.value)}
            onKeyUp={(e) => (e.key === 'Enter' ? handleSubscribe(value) : null)}
            value={value}
            size="sm"
            placeholder="Email Address"
            className={`p-0 ${textColor}`}
          />
          <Button
            onClick={() => handleSubscribe(value)}
            variant={`rounded-pill btn-xs`}
            className={`${textColor}`}
            disabled={isLoading}
          >
            Subscribe
          </Button>
        </InputGroup>
      </FormGroup>
    </>
  );
};
