import { useMutation, useQueryClient } from 'react-query';

import { useApiClient } from '@api/apiClient';
import { ApplyDiscountCodeToInvoiceMutationVariables } from '@api/generated/graphql';

export const useApplyDiscountCodeToInvoice = () => {
  const queryClient = useQueryClient();
  const apiClient = useApiClient();
  return useMutation(
    (variables: ApplyDiscountCodeToInvoiceMutationVariables) =>
      apiClient.ApplyDiscountCodeToInvoice(variables),
    {
      onSuccess: () => {
        queryClient.refetchQueries(['invoiceDetails']);
      },
    }
  );
};
