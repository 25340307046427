import z from 'zod';

import { validationMessages } from '@constants';

const cvvSchema = z.string().regex(/^\d{3,4}$/, 'CVV code must be a 3 or 4 digit number');
export const cardNumberValidationSchema = z
  .string()
  .transform((val) => val.replace(/\s/gim, ''))
  .refine(
    (val) => {
      const everySymbolIsDigit = /^\d+$/.test(val);
      return everySymbolIsDigit && (val.length === 15 || val.length === 16);
    },
    {
      message: 'Invalid credit card number',
    }
  );

export const addCreditCardFormValidationSchema = z.object({
  cardNumber: cardNumberValidationSchema,
  cvv: cvvSchema,
  expiryDate: z
    .custom<string>()
    .refine((val) => (/^\d\d\/\d\d$/.test(val) ? validationMessages.required : false)),
  cardholderName: z.string().trim().min(1, validationMessages.required),
});

export type AddCreditCardVM = z.infer<typeof addCreditCardFormValidationSchema>;
