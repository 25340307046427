import { ReactNode, useState } from 'react';
import { Button } from 'react-bootstrap';

import { AuthModal } from './AuthModal/AuthModal';

export const SignInButton = ({
  children = ({ showSignInModal }) => (
    <Button
      onClick={showSignInModal}
      variant="link"
      className="p-0 fw-bolder text-primary text-decoration-underline"
    >
      Sign in here
      {/*<i className="fi-arrow-long-right ms-2"></i>*/}
    </Button>
  ),
}: {
  children?: ({ showSignInModal }: { showSignInModal: () => void }) => ReactNode;
}) => {
  const [isAuthModalVisible, setAuthModalVisible] = useState(false);

  return (
    <>
      {children({
        showSignInModal: () => setAuthModalVisible(true),
      })}
      <AuthModal
        show={isAuthModalVisible}
        onHide={() => setAuthModalVisible(false)}
        onSuccess={() => setAuthModalVisible(false)}
        activeAuthMethod="signIn"
      />
    </>
  );
};
