import { makeGqlCall } from './makeGqlCall';

const registerUserQuery = /* GraphQL */ `
  mutation ($register: RegisterUserContactInput!) {
    registerUserContact(register: $register) {
      uid
      contactId
      token
    }
  }
`;

type RegisterUserResponse = {
  registerUserContact: {
    uid: string;
    contactId: string;
    token: string;
  };
};

export type UtmJSONInput = {
  utm_campaign?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_term?: string;
  utm_content?: string;
};

export type RegisterUserVariables = {
  register: {
    user: {
      email: string;
      phoneNumber: string;
      firstName: string;
      lastName: string;
      zip: string;
      utm: UtmJSONInput;
      attribution: {
        sscid?: string;
      };
      coords: { lat: number; lng: number };
    };
    contact: {
      storefrontId: number;
      note: string;
      isQuickShop?: boolean;
      timeframe?: string;
      deliveryMethod?: string;
    };
  };
};

export const registerUserMutation = makeGqlCall<RegisterUserResponse, RegisterUserVariables>(
  registerUserQuery
);
