import { Form, FormControlProps } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FieldPath, FieldValues, get } from 'react-hook-form';

type InputFieldProps<T extends FieldValues> = FormControlProps & {
  name: FieldPath<T>;
  label: string;
};

export const InputField = <T extends FieldValues>({ name, ...props }: InputFieldProps<T>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<T>();
  const errorMessage = get(errors, name)?.message;
  return (
    <Form.FloatingLabel label={props.label}>
      <Form.Control
        isInvalid={Boolean(errorMessage)}
        {...register(name, {
          valueAsNumber: props.type === 'number',
          valueAsDate: props.type === 'date' || props.type === 'datetime-local',
        })}
        {...props}
        placeholder={props.label}
      />
      <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
    </Form.FloatingLabel>
  );
};
