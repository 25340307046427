import { Stack } from 'react-bootstrap';

import { Circle } from '@components/Circle';
import { StoreOwnerImage } from '@components/StoreOwnerImage';
import { formatCurrency } from '@utils/formatCurrency';

import { InvoiceState } from '../../../../../constants';
import { InvoiceVM } from '../../../../../useInvoices';

type InvoiceItemProps = {
  state: InvoiceState;
  name: string | null;
  price: number;
  owner: InvoiceVM['storefront']['owner'];
  onClick: () => void;
};
const labelColorByInvoiceState: Record<InvoiceState, string> = {
  PAID: 'dark',
  PENDING: 'brownsugar',
  REJECTED: 'dark',
};
const invoiceLabelByState: Record<InvoiceState, string> = {
  PAID: 'Paid',
  PENDING: 'To be paid',
  REJECTED: 'Canceled',
};

export const InvoiceItem = ({ state, name = '', price, owner, onClick }: InvoiceItemProps) => {
  const containerClasses = `${
    state === 'PENDING' ? 'border border-2 border-warning' : ''
  } mx-3 invoice-item rounded-2 shadow font-serif position-relative cursor-pointer`;
  return (
    <div className={containerClasses} onClick={onClick}>
      {state === 'PENDING' ? (
        <Circle
          radius={9}
          className="border border-2 border-white position-absolute bg-dark"
          style={{ left: -10, top: -10 }}
        />
      ) : null}
      <div className="p-3 d-flex justify-content-between align-items-start">
        <p className="text-dark mb-0 fw-bolder text-break">{name}</p>
        <div className="d-flex align-items-center flex-shrink-0">
          <div className="d-flex align-items-center flex-shrink-0">
            <p className={`fw-bolder mb-0 text-${labelColorByInvoiceState[state]}`}>
              {invoiceLabelByState[state]}
              {state !== 'REJECTED' ? (
                <span className="fw-bolder"> {formatCurrency(price)}</span>
              ) : null}
            </p>
            <i className="fi-arrow-long-right ms-2" />
          </div>
        </div>
      </div>
      <hr className="border-warning" style={{ borderStyle: 'dashed' }} />
      <Stack direction="horizontal" gap={3} className="align-items-start p-3">
        <StoreOwnerImage
          rounded="circle"
          noBorder
          src={owner.photoURL ?? null}
          size={40}
          alt={owner?.displayName ?? 'storeowner image'}
        />
        <Stack direction="vertical" className="align-items-start">
          <p className="fw-bolder mb-0 text-dark">{owner.displayName}</p>
          <span className="fs-sm font-sans">Storefront Owner</span>
        </Stack>
      </Stack>
    </div>
  );
};
