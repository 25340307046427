import { identity } from 'lodash/fp';

import { makeGqlCall } from './makeGqlCall';

const sitemapQuery = /* GraphQL */ `
  {
    sitemap {
      browse
      shop
      category
    }
  }
`;

export type FetchSitemapResponse = {
  sitemap: {
    browse?: [
      {
        browse: Record<string, { id: string }>;
      }
    ];
    shop?: [
      {
        shop: Record<string, { id: string }>;
      }
    ];
    category?: [
      {
        category: Record<string, { id: string }>;
      }
    ];
  };
};
export const fetchSitemap = makeGqlCall<FetchSitemapResponse>(sitemapQuery);

let sitemap = fetchSitemap();

export const getSitemap = async <T = FetchSitemapResponse>(
  select: (res: FetchSitemapResponse) => T = identity
) => {
  if (!select(await sitemap)) {
    sitemap = fetchSitemap();
  }
  return select(await sitemap);
};
