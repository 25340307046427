import { useCallback, useRef } from 'react';

export function useScrollToBottom() {
  const ref = useRef<HTMLDivElement>(null);
  return [
    ref,
    useCallback((behavior: ScrollBehavior = 'smooth') => {
      if (ref.current) {
        ref.current.scrollTo({ behavior, top: ref.current.scrollHeight });
      }
    }, []),
  ] as const;
}
