import { defaultStoreOwnerImageSrc } from '@constants';
import { CatalogItem, Fullfillment, Store, VisualTag } from '@hooks/useStores';

import { metersToMiles } from './miles';

export const badgeByVisualTag: Record<VisualTag, { title: string; icon?: string; color: string }> =
  {
    sale: {
      title: 'Sale',
      color: 'accent',
      icon: 'fi-fire',
    },
    trending: {
      title: 'Trending',
      color: 'brownsugar',
      icon: 'fi-fire',
    },
    'black-owned': {
      title: 'Black Owned',
      color: 'dark',
      icon: 'fi-home',
    },
  };

export type StoreVM = {
  id: number;
  name: string;
  description: string;
  imageSrc: string;
  url: string;
  ownerImageSrc: string | null;
  owner: string;
  catalog: Array<CatalogItem>;
  distanceTo: number;
  category: string;
  categoryId: number;
  price: Store['storefrontPricing'];
  deliveryOptions: Fullfillment[];
  location: string;
  review?: {
    grade: number | null;
    amount: number | null;
  };
  visualTags: VisualTag[];
  geofenceRadius: number;
  photos: {
    main?: {
      name: string;
      size: number;
      uri: string;
    };
    others?: Array<{
      name: string;
      size: number;
      uri: string;
    }>;
  };
};

export const adaptStoreToVM = (hit: Store): StoreVM => {
  return {
    id: hit.id,
    name: hit.businessName,
    description: hit.tagline,
    imageSrc: hit.photos.main?.uri ?? '/images/storefront-image-placeholder.png',
    owner: hit.owner.displayName,
    ownerImageSrc: hit.owner.photoUrl ?? defaultStoreOwnerImageSrc,
    category: hit.primaryCategory,
    catalog: hit.catalog,
    categoryId: hit.categoryId,
    deliveryOptions: hit.fulfillmentOptions,
    location: `${hit.city}, ${hit.state}`,
    review: {
      grade: hit.statistics.statistics.avgReview,
      amount: hit.statistics.statistics.countReview,
    },
    price: hit.storefrontPricing,
    url: `/shop/${hit.uriSlug}`,
    distanceTo: metersToMiles(hit._rankingInfo.matchedGeoLocation.distance),
    visualTags: hit.visualTags,
    geofenceRadius: hit.geofenceRadius,
    photos: hit.photos,
  };
};
