import { useEffect, useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import { autocompleteRequestConfig } from '@constants';
import { PlaceLocation, useUserLocation, waitForGoogleMapsReady } from '@hooks/useUserLocation';

const getPlaceSearchStyles = ({
  noBorder,
  size,
  isFrontPage,
}: {
  noBorder?: boolean;
  size?: 'sm' | undefined;
  isFrontPage?: boolean;
}) => ({
  input: (provided: object) => ({
    ...provided,
  }),
  clearIndicator: (provided: object) => ({
    ...provided,
    color: '#3C5045',
  }),
  option: (provided: object) => ({
    ...provided,
  }),
  placeholder: (provided: object) => ({
    ...provided,
    color: '#5D7071',
  }),
  menuPortal: (provided: object) => ({ ...provided, zIndex: 9999 }),
  control: (provided: object) => ({
    ...provided,
    fontSize: '0.875rem',
    backgroundColor: isFrontPage ? 'transparent' : '#fff',
    borderColor: '#3C5045',
    '&:hover': '#3C5045',
    borderRadius: '50rem',
    paddingLeft: '8px',
    height: size === 'sm' ? '36px' : '44px',
    minHeight: size === 'sm' ? '36px' : '44px',
    ...(noBorder
      ? {
          border: 'none',
          boxShadow: 'none',
          ':hover': {
            border: 'none',
            boxShadow: 'none',
          },
        }
      : {}),
  }),
  singleValue: (provided: object) => ({
    ...provided,
    color: '#3C5045',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  dropdownIndicator: () => ({ display: 'none' }),
});

export type PlaceOptionVM = {
  label: string;
  value: PlaceLocation;
};

export const PlaceSearch = ({
  isFrontPage = false,
  noBorder = false,
  size,
}: {
  isFrontPage?: boolean;
  noBorder?: boolean;
  size?: 'sm' | undefined;
}) => {
  const { location, setLocation } = useUserLocation();
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    waitForGoogleMapsReady.then(() => setShouldShow(true)); // render only on client-side)
  }, []);

  return shouldShow ? (
    <GooglePlacesAutocomplete
      debounce={400}
      selectProps={{
        noOptionsMessage: () => <>Please enter a City or Zip Code</>,
        menuPortalTarget: document.body,
        styles: getPlaceSearchStyles({ noBorder, size, isFrontPage }),
        className: 'py-1 text-dark w-100 z-index-1',
        isClearable: true,
        placeholder: isFrontPage ? 'ENTER CITY OR ZIP CODE' : 'Enter City or Zip Code',
        value: location && { value: location, label: location?.formatted_address },
        onChange: (option: PlaceOptionVM | null) => {
          setLocation(
            option
              ? { place_id: option.value.place_id, formatted_address: option.label }
              : undefined
          );
        },
      }}
      autocompletionRequest={autocompleteRequestConfig}
    />
  ) : null;
};
