import Link from 'next/link';
import { Button, Col, Row } from 'react-bootstrap';

import { ImageLoader } from '@components/ImageLoader';

export const StartSelling = () => {
  return (
    <Row className="mx-0 rounded py-lg-5 my-lg-2 py-sm-5 px-md-5 d-flex justify-content-center align-items-center bg-light">
      <Col
        lg={{ offset: 0, span: 5 }}
        md={{ offset: 0, span: 5 }}
        sm={{ offset: 0, span: 12 }}
        className="p-4"
      >
        <h3 className="text-dark">Start Selling With Us</h3>
        <p className="mb-5">
          Share your talents with thousands of new customers looking for the very thing that you are
          great at. West Tenth helps home-based businesses like yours find customers, build
          community, and make money. It takes just minutes, and sign-up is completely free.
        </p>
        <div>
          <Link href="https://www.westtenth.com/sellwithus" passHref>
            <Button className="text-light rounded-pill">Become a Seller</Button>
          </Link>
        </div>
      </Col>
      <Col
        xl={{ offset: 1, span: 4 }}
        lg={{ offset: 1, span: 5 }}
        md={{ span: 5 }}
        sm={{ span: 12, offset: 0 }}
      >
        <div
          style={{ width: 416, height: 400 }}
          className="d-flex justify-content-center w-100 overflow-hidden position-relative"
        >
          <ImageLoader
            className="rounded-2"
            fill
            style={{ objectFit: 'contain' }}
            priority
            src="/images/start-selling.png"
            alt="start selling"
          />
        </div>
      </Col>
    </Row>
  );
};
