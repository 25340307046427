import { Message } from '@twilio/conversations';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';

import { readConversationMessages } from './readConversationMessages';

export const useReadMessagesOnExpandedConversation = (
  expanded: boolean,
  messages: Message[],
  selectedConversationId?: number
) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    (async () => {
      if (selectedConversationId && expanded) {
        await readConversationMessages({
          conversation: {
            conversationId: selectedConversationId,
            updateAll: true,
          },
          appusertype: 'BUYER',
        });
        queryClient.refetchQueries('myConversations');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConversationId, expanded, messages]);
};
