import * as firebase from 'firebase/app';
import {
  browserLocalPersistence,
  getAuth,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  User,
} from 'firebase/auth';
import { create } from 'zustand';

import { waitForIsClient } from '../utils/waitForIsClient';

const app = firebase.initializeApp({
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
});

export const auth = getAuth(app);

auth.setPersistence(browserLocalPersistence);

export const useLoggedInUser = create<{
  user: User | null;
  initializing: boolean;
  setUser: (user: User | null) => void;
}>((set) => ({
  user: auth.currentUser,
  initializing: !auth.currentUser,
  setUser: (user) => set({ user, initializing: false }),
}));

export const userIdStoreKey = 'userId';
export const getUserId = async () => {
  await waitForIsClient;
  return auth.currentUser?.uid || localStorage.getItem(userIdStoreKey);
};

auth.onAuthStateChanged((user) => {
  useLoggedInUser.setState({ user, initializing: false });
  waitForIsClient.then(() =>
    localStorage.setItem(userIdStoreKey, (user?.uid ?? 'null')?.toString())
  );
});

export const useAuth = () => {
  const { user, initializing } = useLoggedInUser();

  const signInWithToken = async (token: string) => {
    try {
      return await signInWithCustomToken(auth, token);
    } catch (error) {
      const newToken = await user?.getIdToken(true);
      if (newToken) {
        return await signInWithCustomToken(auth, newToken);
      }
    }
  };

  return {
    user,
    initializing,
    signOut: () => auth.signOut(),
    signInWithToken,
    signInWithEmailAndPassword: (email: string, password: string) =>
      signInWithEmailAndPassword(auth, email, password),
    sendPasswordResetEmail: (email: string) => sendPasswordResetEmail(auth, email),
  };
};
