import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { GoogleAnalytics } from 'nextjs-google-analytics';
import React, { useEffect } from 'react';
import SSRProvider from 'react-bootstrap/SSRProvider';
import { hotjar } from 'react-hotjar';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { toast, ToastContainer } from 'react-toastify';

import { DEFAULT_ERROR_MESSAGE, siteDomain } from '@constants';
import {
  useReloadOnPopState,
  useSaveQueryStringVariables,
  useShowSpinnerOnRouteChange,
  useTrackCurrentScreen,
  useTrackPreviousScreen,
} from '@hooks';
import { ScreenName } from '@packages/event-tracking';
import { fbq } from '@packages/fbpixel';
import { MessagingAndInvoicing } from '@packages/messaging-and-invoicing';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/grid';
import 'swiper/css/effect-fade';

import 'nouislider/distribute/nouislider.css';
import 'react-toastify/dist/ReactToastify.min.css';
import '../scss/theme.scss';

const pagesWithFilters = ['search', 'browse', 'welcome'];

const useAppQueryClient = () => {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          mutations: {
            onError: () => {
              toast.error(DEFAULT_ERROR_MESSAGE, {
                position: toast.POSITION.TOP_RIGHT,
              });
            },
          },
        },
      })
  );
  return queryClient;
};

const App = ({
  Component,
  pageProps,
}: {
  Component: () => JSX.Element;
  pageProps: { screenName: ScreenName; dehydratedState?: unknown };
}) => {
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_HOTJAR_HJID && process.env.NEXT_PUBLIC_HOTJAR_HJSV) {
      hotjar.initialize(+process.env.NEXT_PUBLIC_HOTJAR_HJID, +process.env.NEXT_PUBLIC_HOTJAR_HJSV);
    }
  }, []);
  useShowSpinnerOnRouteChange();
  useSaveQueryStringVariables();
  useTrackPreviousScreen(pageProps.screenName);
  useTrackCurrentScreen(pageProps.screenName);
  useReloadOnPopState(pagesWithFilters);

  const router = useRouter();
  const queryClient = useAppQueryClient();

  useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    fbq.pageView();

    const handleRouteChange = () => {
      fbq.pageView();
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  const _pathSliceLength = Math.min.apply(Math, [
    router.asPath.indexOf('?') > 0 ? router.asPath.indexOf('?') : router.asPath.length,
    router.asPath.indexOf('#') > 0 ? router.asPath.indexOf('#') : router.asPath.length,
  ]);

  const canonicalUrl = siteDomain + router.asPath.substring(0, _pathSliceLength);

  return (
    <SSRProvider>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>Web marketplace</title>
        <link rel="canonical" href={canonicalUrl} />
        <link rel="icon" sizes="192x192" href="/images/favicon/android-chrome-192x192.png" />
        <link rel="icon" sizes="512x512" href="/images/favicon/android-chrome-512x512.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/images/favicon/apple-touch-icon.png" />
        <link rel="icon" sizes="16x16" href="/images/favicon/favicon-16x16.png" />
        <link rel="icon" sizes="32x32" href="/images/favicon/favicon-32x32.png" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <GoogleAnalytics trackPageViews />
      <Script
        id="fb-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
        }}
      />
      <Script src="https://www.dwin1.com/19038.js" defer />
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps?.dehydratedState}>
          <Component {...pageProps} key={router?.asPath} />
          <ToastContainer style={{ flexShrink: 0 }} />
          <div className="messenger-container">
            <MessagingAndInvoicing />
          </div>
        </Hydrate>
      </QueryClientProvider>
      <Script
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_PLACES_API_KEY}&libraries=places`}
      />
      <Script src="/static/justuno.js" />
    </SSRProvider>
  );
};

export default App;
