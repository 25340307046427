import { Col, Container, Row } from 'react-bootstrap';

import { isSuperCategory, useSuperCategories } from '@api';
import { IconBox } from '@components';

export const Categories = () => {
  const { data: categories } = useSuperCategories();

  return (
    <div className="bg-light rounded">
      <Container as="section" className="py-3 my-4 px-3 px-md-5">
        <h4 className="fst-italic mb-3 font-serif">Browse by Category</h4>
        <Row xs={2} sm={3} lg={6} className="g-3 g-xl-4">
          {categories?.categoryTaxonomy
            .filter(isSuperCategory)
            .slice(0, 6)
            ?.map((category, indx) => (
              <Col key={indx}>
                <IconBox
                  href={`/browse/${category.uriSlug}`}
                  media={category.icon}
                  title={category.title}
                  align="center"
                />
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  );
};
