import { Button } from 'react-bootstrap';

type RejectedInvoiceDetailsProps = {
  onClose: () => void;
};

export const RejectedInvoiceDetailsRightColumn = ({ onClose }: RejectedInvoiceDetailsProps) => (
  <>
    <div className="d-flex flex-grow-1 align-items-start m-lg-6 justify-content-center">
      <div className="flex-grow-1">
        <h5 className="text-danger font-serif d-flex align-items-start">
          <i style={{ fontSize: 24 }} className="fi-alert-octagon me-2" />
          This order was canceled
        </h5>
      </div>
    </div>

    <div className="d-flex justify-content-end gap-3">
      <Button onClick={onClose} variant="primary" className="rounded-pill w-100 w-lg-50 text-light">
        Close
      </Button>
    </div>
  </>
);
