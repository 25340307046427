import { useEffect } from 'react';

import { useCurrentScreen } from '@hooks/useCurrentScreen';
import { ScreenName, trackMixpanelEvent } from '@packages/event-tracking';

import { DiscountCodeSuccessEventPayload, InvoiceEventName } from './types';

export const discountCodeSuccessPubSub = {
  publish: (promoCode: string, screen: ScreenName) => {
    const payload: DiscountCodeSuccessEventPayload = {
      action: 'added',
      component: 'Discount Code',
      element: 'button',
      eventType: 'Discount Code Event',
      isWeb: true,
      module: 'Invoice Detail',
      screen,
      promoCode,
    };
    PubSub.publish(InvoiceEventName.DiscountCodeSuccess, payload);
  },
  subscribe: (fn: (data: DiscountCodeSuccessEventPayload) => void) =>
    PubSub.subscribe(InvoiceEventName.DiscountCodeSuccess, (_, payload) => fn(payload)),
};

export const useTrackDiscountCodeSuccess = () => {
  const { screen: currentScreen } = useCurrentScreen();
  useEffect(() => {
    const discountCodeSuccessUnsubscribeToken = discountCodeSuccessPubSub.subscribe((data) => {
      trackMixpanelEvent('Discount Code Event', data);
    });

    return () => {
      PubSub.unsubscribe(discountCodeSuccessUnsubscribeToken);
    };
  }, [currentScreen]);
};
