import american_express from './stp_card_amex.png';
import cvc from './stp_card_cvc.png';
import cvc_amex from './stp_card_cvc_amex.png';
import diners_club from './stp_card_diners.png';
import discover from './stp_card_discover.png';
import jcb from './stp_card_jcb.png';
import master_card from './stp_card_mastercard.png';
import placeholder from './stp_card_unknown.png';
import visa from './stp_card_visa.png';

const Icons = {
  cvc,
  cvc_amex,
  'american-express': american_express,
  'diners-club': diners_club,
  'master-card': master_card,
  discover,
  jcb,
  placeholder,
  visa,
};

export default Icons;
