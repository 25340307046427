import PubSub from 'pubsub-js';
import { useEffect, useState } from 'react';
import { Button, Form, FormGroup, InputGroup } from 'react-bootstrap';

import { EventName } from '@packages/event-tracking';

export const StoreSearch = ({
  query,
  onSearch,
}: {
  query: string;
  onSearch: (val: string) => void;
}) => {
  useEffect(() => {
    setValue(query);
  }, [query]);
  const [value, setValue] = useState(query);

  const handleSearch = (val: string) => {
    PubSub.publish(EventName.SearchStores, val);
    onSearch(val);
  };

  return (
    <FormGroup
      style={{ minWidth: 250, borderWidth: '0.01em' }}
      className="px-1 py-0 w-100 form-group d-block rounded-pill shadow-none bg-light border-dark"
    >
      <InputGroup className="display-flex align-items-center border-dark ms-0">
        <Form.Control
          onChange={(e) => setValue(e.target.value)}
          onKeyUp={(e) => (e.key === 'Enter' ? handleSearch(value) : null)}
          value={value}
          size="sm"
          placeholder="FIND SERVICES AND GOODS..."
        />
        <Button
          onClick={() => handleSearch(value)}
          variant="primary rounded-pill btn-icon btn-xs"
          className="text-light"
        >
          <i className="fi-search"></i>
        </Button>
      </InputGroup>
    </FormGroup>
  );
};
