import { pick } from 'lodash/fp';
import { z } from 'zod';
import { create } from 'zustand';

import { isClient } from '@constants';
import { DeliveryMethodSchema, Fullfillment, visualTagValidationSchema } from '@hooks/useStores';

const orderCatalogItemValidationSchema = z.object({
  price: z.number(),
  catalogId: z.number(),
  name: z.string(),
  rawurl: z.array(z.string()),
  allowSelectQuantity: z.boolean(),
});

export type OrderCatalogItemVM = z.infer<typeof orderCatalogItemValidationSchema>;

const orderItemStoreValidationSchema = z.object({
  businessName: z.string(),
  id: z.number(),
  visualTags: z.array(visualTagValidationSchema),
  owner: z.object({
    displayName: z.string(),
    firstName: z.string(),
    photoUrl: z.string().nullable(),
    uid: z.string(),
  }),
  fulfillmentOptions: z.array(DeliveryMethodSchema),
});
export type OrderItemStoreVM = z.infer<typeof orderItemStoreValidationSchema>;

const orderItemValidationSchema = z.object({
  id: z.string(),
  store: orderItemStoreValidationSchema,
  storeId: z.number(),
  catalogItem: orderCatalogItemValidationSchema,
  quantity: z.number().optional(),
  note: z.string().optional(),
  timeframe: z.string().optional().nullable(),
  delivery: z
    .object({ method: DeliveryMethodSchema.optional(), location: z.string().optional() })
    .optional(),
});

export type OrderItemVM = z.infer<typeof orderItemValidationSchema>;

export type AddOrderItemToCartVM = {
  store: OrderItemStoreVM;
  quantity?: number;
  note?: string;
  orderItem: OrderCatalogItemVM;
  timeframe?: string | null;
  delivery?: { method?: Fullfillment; location?: string };
};
type CartOrder = {
  orderItems: OrderItemVM[];
  addOrderItem: (item: AddOrderItemToCartVM) => Promise<void>;
  removeOrderItem: (item: { id: string }) => Promise<void>;
  updateOrderItem: (item: OrderItemVM) => Promise<void>;
  clearCart: () => Promise<void>;
};
const isOrderItem = (input: any): input is OrderItemVM =>
  orderItemValidationSchema.safeParse(input).success;
const getCartOrder = (): OrderItemVM[] => {
  const cartOrder = JSON.parse(localStorage.getItem('cardOrder') ?? '[]');
  if (Array.isArray(cartOrder)) {
    return cartOrder.filter(isOrderItem);
  }
  return [];
};
const saveCartOrder = (items: OrderItemVM[]) =>
  localStorage.setItem('cardOrder', JSON.stringify(items));

export const useCartOrder = create<CartOrder>((set, get) => ({
  orderItems: isClient ? getCartOrder() : [],
  addOrderItem: async (item: AddOrderItemToCartVM) =>
    new Promise((res) => {
      const items = get().orderItems;
      const itemToAdd: OrderItemVM = {
        id: Math.random().toString(),
        store: pick(
          ['businessName', 'id', 'visualTags', 'owner', 'fulfillmentOptions'],
          item.store
        ),
        storeId: item.store.id,
        catalogItem: pick(
          ['price', 'catalogId', 'name', 'rawurl', 'allowSelectQuantity'],
          item.orderItem
        ),
        delivery: item.delivery,
        timeframe: item.timeframe,
        note: item.note,
        quantity: item.quantity,
      };

      set({
        orderItems: items.concat([itemToAdd]),
      });
      res();
    }),
  removeOrderItem: async ({ id }: { id: string }) =>
    new Promise<void>((res) => {
      set({ orderItems: get().orderItems.filter((i) => i.id !== id) });
      res();
    }),
  updateOrderItem: (item: OrderItemVM) =>
    new Promise<void>((res) => {
      const items = get().orderItems;
      const index = items.findIndex((i) => i.id === item.id);
      items.splice(index, 1, item);
      set({ orderItems: items });
      res();
    }),
  clearCart: () =>
    new Promise<void>((res) => {
      set({ orderItems: [] });
      res();
    }),
}));

useCartOrder.subscribe((order) => {
  saveCartOrder(order.orderItems);
});
