import Image from 'next/image';
import Link, { LinkProps } from 'next/link';
import React from 'react';
import { UrlObject } from 'url';
import { isImageFitCover, isImageSlide, useLightboxProps } from 'yet-another-react-lightbox/core';
import { ContainerRect, SlideImage } from 'yet-another-react-lightbox/dist/types';

type Props = {
  slide: SlideImage;
  rect: ContainerRect;
  linkTo: {
    type: 'url' | 'lightbox';
    url?: LinkProps['href'];
    lightboxOpenState?: () => void;
  };
  offset: number;
};

type Dimension = 'width' | 'height';

type CalculateImageDimensionType = {
  slide: SlideImage;
  rect: ContainerRect;
  cover: boolean;
  dimension: Dimension;
};

const calculateImageDimension = ({
  slide,
  rect,
  cover,
  dimension,
}: CalculateImageDimensionType): number => {
  const otherDimension: Dimension = dimension === 'width' ? 'height' : 'width';
  return !cover
    ? Math.round(
        Math.min(
          rect[dimension],
          (rect[otherDimension] / (slide?.[otherDimension] ?? 1)) * (slide[dimension] ?? 1)
        )
      )
    : rect[dimension];
};

const SlideImageAsNextJsImage = ({ slide, rect, linkTo, offset }: Props) => {
  const { imageFit } = useLightboxProps().carousel;
  const cover = isImageSlide(slide) && isImageFitCover(slide, imageFit);

  const width = calculateImageDimension({ slide, rect, cover, dimension: 'width' });
  const height = calculateImageDimension({ slide, rect, cover, dimension: 'height' });

  const ImageWrapper = linkTo.type === 'url' ? Link : React.Fragment;
  const hrefUrl = linkTo.url as UrlObject;
  return (
    <ImageWrapper
      href={hrefUrl}
      passHref
      {...(linkTo.type === 'url' && {
        as: typeof linkTo.url === 'string' ? linkTo.url : (hrefUrl.pathname as string),
      })}
      style={{ all: 'unset' }}
    >
      <div
        style={{ position: 'relative', width, height, cursor: 'pointer' }}
        onClick={linkTo.lightboxOpenState}
      >
        <Image
          priority={offset === 0}
          fill
          alt=""
          src={slide.src}
          loading={offset === 0 ? 'eager' : 'lazy'}
          draggable={false}
          style={{ objectFit: cover ? 'cover' : 'contain' }}
          sizes={`${Math.ceil((width / window.innerWidth) * 100)}vw`}
          unoptimized={offset !== 0}
        />
      </div>
    </ImageWrapper>
  );
};

export default SlideImageAsNextJsImage;
