import { Accordion } from 'react-bootstrap';

export const ReturnPolicySection = () => (
  <Accordion className="my-4">
    <Accordion.Item eventKey="0">
      <Accordion.Header>Return Policy</Accordion.Header>
      <Accordion.Body>
        Returns should be handled directly with the business owner. If needed, West Tenth is happy
        to assist.
      </Accordion.Body>
    </Accordion.Item>
  </Accordion>
);
