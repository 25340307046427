import { useState } from 'react';
import { Col, Modal, Row, Spinner } from 'react-bootstrap';

import { invoiceStateByEntity } from '@packages/messaging-and-invoicing/constants';

import { InvoiceDetails } from './InvoiceDetails';
import { InvoicePaidModal } from './InvoicePaidModal';
import { PaidInvoiceDetailsRightColumn } from './PaidInvoiceDetailsRightColumn';
import { PayInvoiceSection } from './PayInvoiceSection';
import { RejectedInvoiceDetailsRightColumn } from './RejectedInvoiceDetailsRightColumn';
import { useInvoiceDetails } from './useInvoiceDetails';
import { useInvoiceDetailsModal } from './useInvoiceDetailsModal';
import { useTrackInvoiceDetailsEvents } from './useTrackInvoiceDetailsEvents';

export const InvoiceDetailsModal = () => {
  const invoiceDetailsModalState = useInvoiceDetailsModal();

  const { data: invoice, isFetching: isInvoiceLoading } =
    useInvoiceDetails(invoiceDetailsModalState);
  const [isInvoicePaidModalOpened, setIsInvoicePaidModalOpened] = useState(false);

  const handleInvoicePaid = () => {
    invoiceDetailsModalState.close();
    setIsInvoicePaidModalOpened(true);
  };

  useTrackInvoiceDetailsEvents(invoice);

  const state = invoice?.status ? invoiceStateByEntity[invoice?.status] : null;

  return (
    <>
      <Modal
        size="lg"
        show={invoiceDetailsModalState.opened}
        fullscreen={'lg-down'}
        onHide={invoiceDetailsModalState.close}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="border-0" />
        <Modal.Body style={{ minHeight: 350 }}>
          <Row className="d-flex mt-n2">
            <Col lg={{ span: 6 }} className="position-relative">
              {isInvoiceLoading ? (
                <div
                  className="d-flex flex-grow-1 position-absolute h-100 justify-content-center align-items-center bg-white bg-opacity-50"
                  style={{ zIndex: 10, left: 0, right: 0, top: 0, bottom: 0 }}
                >
                  <Spinner animation="border" role="status" />
                </div>
              ) : null}
              <InvoiceDetails invoiceDetails={invoice} state={state} />
            </Col>
            <Col lg={{ span: 6 }} className="position-relative d-flex flex-column">
              {state === 'PAID' ? (
                <PaidInvoiceDetailsRightColumn
                  onClose={invoiceDetailsModalState.close}
                  invoice={invoice}
                />
              ) : null}
              {state === 'REJECTED' ? (
                <RejectedInvoiceDetailsRightColumn onClose={invoiceDetailsModalState.close} />
              ) : null}
              {state === 'PENDING' ? (
                <PayInvoiceSection
                  onInvoiceRejected={invoiceDetailsModalState.close}
                  onInvoicePaid={handleInvoicePaid}
                  invoiceId={invoice?.id}
                  invoiceTotal={invoice?.totalToBePaid ?? 0}
                />
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <InvoicePaidModal
        visible={isInvoicePaidModalOpened}
        onClose={() => setIsInvoicePaidModalOpened(false)}
      />
    </>
  );
};
