import Link from 'next/link';
import { Button, Stack } from 'react-bootstrap';

import { StoreOwnerImage } from '@components';
import { useIsMediaBreakpoint } from '@hooks/useIsMediaBreakpoint';
import { truncateAndAddEllipsis } from '@utils/truncateAndAddEllipsis';

import { ConversationEntity } from '../../../../fetchMyConversations';

export const ConversationDetailsHeader = ({
  otherUser,
  onClose,
  store,
}: {
  otherUser: ConversationEntity['otherUser'] | undefined;
  store: ConversationEntity['storefrontJSON'];
  onClose: () => void;
}) => {
  const isDesktop = useIsMediaBreakpoint('lg');
  return (
    <>
      <Link
        href={`/shop/${store?.uriSlug}`}
        onClick={() => !isDesktop && onClose()}
        passHref
        style={{ textDecoration: 'none' }}
      >
        <Stack direction="horizontal" gap={2} className="align-items-center">
          <StoreOwnerImage
            src={otherUser?.photoURL ?? null}
            size={42}
            alt={otherUser?.firstName ?? 'storeowner image'}
            noBorder={true}
            rounded={1}
          />
          <Stack direction="vertical" className="align-items-start">
            <h5 className="mb-0 text-dark text-break font-title">
              {truncateAndAddEllipsis(`${otherUser?.firstName} ${otherUser?.lastName}`)}
            </h5>
            {store?.businessName && (
              <Button
                size="sm"
                variant="link font-serif p-0 fw-bolder text-primary text-decoration-underline"
              >
                View {truncateAndAddEllipsis(store.businessName)}
              </Button>
            )}
          </Stack>
        </Stack>
      </Link>
      <Button className="btn btn-icon" variant="link" onClick={onClose}>
        <i className="fi-minus" />
      </Button>
    </>
  );
};
