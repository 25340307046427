import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Form } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

import { createPasswordMutation } from '@api/createPassword';
import { InputField } from '@components/InputField';
import { useAuth } from '@hooks';

export const passwordSchema = z.string().min(6);

export const createPasswordSchema = z
  .object({
    password: passwordSchema,
    confirmPassword: passwordSchema,
  })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: 'custom',
        path: ['confirmPassword'],
        message: 'The passwords did not match',
      });
      ctx.addIssue({
        code: 'custom',
        path: ['password'],
        message: 'The passwords did not match',
      });
    }
  });

type CreatePasswordVM = z.infer<typeof createPasswordSchema>;

export const CreatePasswordForm = ({ onSuccess }: { onSuccess: () => void }) => {
  const methods = useForm<CreatePasswordVM>({
    resolver: zodResolver(createPasswordSchema),
  });

  const auth = useAuth();

  const onSubmit = async ({ password }: CreatePasswordVM) => {
    const token = await auth.user?.getIdToken(true);
    const res = await createPasswordMutation(
      { user: { password } },
      { Authorization: `Bearer ${token}` }
    );
    if (res.registeredUserUpdate.success) {
      if (auth.user?.email) {
        await auth.signOut();
        await auth.signInWithEmailAndPassword(auth.user?.email, password);
      }
      onSuccess();
    }
  };
  return (
    <FormProvider {...methods}>
      <Form
        className="was-validated d-grid gap-2 gap-lg-4 mt-2 mt-lg-4"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <InputField type="password" name="password" label="Create a Password" />
        <InputField type="password" name="confirmPassword" label="Confirm Password" />
        <div className="gap-3 d-flex justify-content-center align-items-stretch">
          <Button
            style={{ width: 180 }}
            type="submit"
            size="lg"
            variant="primary rounded-pill text-dark"
          >
            Create
          </Button>
        </div>
      </Form>
    </FormProvider>
  );
};
