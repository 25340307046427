import { makeGqlCall } from './makeGqlCall';

const updateUserQuery = /* GraphQL */ `
  mutation ($user: UpdateUserInput!) {
    updateUserV2(user: $user) {
      uid
    }
  }
`;

type UpdateUserResponse = {
  updateUserV2: {
    uid: string;
  };
};

export type UpdateUserVariables = {
  user: {
    email?: string;
    phoneNumber?: string;
    password?: string;
    firstName?: string;
    lastName?: string;
    zip?: string;
    city?: string;
    streetAddress?: string;
    state?: string;
  };
};

export const updateUser = makeGqlCall<UpdateUserResponse, UpdateUserVariables>(updateUserQuery);
