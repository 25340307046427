import { Badge, Stack } from 'react-bootstrap';

import { Fullfillment } from '@hooks/useStores';

import {
  bgColorForFulfillmentOptionWithDistanceInsideRadius,
  bgColorForFulfillmentOptionWithDistanceOutsideRadius,
  iconByFullfillmentOption,
} from './constants';

export const FulfillmentOptions = ({
  items,
  distanceTo,
  geofenceRadius,
  isTruncated,
  size,
}: {
  items: Fullfillment[];
  distanceTo: number | undefined;
  geofenceRadius: number;
  isTruncated?: boolean;
  size?: 'sm';
}) => (
  <div
    className={`${isTruncated ? 'text-truncate text-truncate--2' : ''} ${
      size === 'sm' ? 'fs-sm' : ''
    }`}
    style={{ margin: -3, ...(isTruncated ? { height: 60 } : {}) }}
  >
    {items.map((option) => {
      const colorForFulfillment: string =
        (distanceTo || 0) > geofenceRadius
          ? bgColorForFulfillmentOptionWithDistanceOutsideRadius[option]
          : bgColorForFulfillmentOptionWithDistanceInsideRadius[option];

      return (
        <Badge
          pill
          bg={colorForFulfillment}
          className={`text-primary text-uppercase border`}
          style={{ margin: 3 }}
          key={option}
        >
          {/*<i className={`${iconByFullfillmentOption[option]} fs-xxs me-1`} />*/}
          {option}
        </Badge>
      );
    })}
  </div>
);
