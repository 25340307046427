import { create } from 'zustand';

import { PlaceLocation, userLocationStoreKey } from './constants';
import { fetchCurrentLocation } from './fetchCurrentLocation';
import { getUserLocationFromStorage } from './getUserLocationFromStorage';
import { waitForIsClient } from '../../utils/waitForIsClient';

export const useUserLocation = create<{
  location?: PlaceLocation;
  setLocation: (newLocation?: PlaceLocation) => void;
}>((set) => ({
  location: undefined,
  setLocation: (location) => set({ location }),
}));

// on update save location to storage
useUserLocation.subscribe((state) => {
  try {
    const location = JSON.stringify(state.location ?? null);
    localStorage.setItem(userLocationStoreKey, location);
  } catch (error) {
    localStorage.removeItem(userLocationStoreKey);
  }
});

waitForIsClient.then(getUserLocationFromStorage).then(async (currentLocation) => {
  const location = currentLocation || (await fetchCurrentLocation());
  useUserLocation.setState({ location });
});
