import { useQuery } from 'react-query';

import { FetchMeResponse, fetchUserQuery } from '@api';

import { useAuth } from './useAuth';

export type UserVM = FetchMeResponse['me'];

export const useUser = () => {
  const { user } = useAuth();

  return useQuery(
    [fetchUserQuery.name, user?.uid],
    async () => {
      const token = await user?.getIdToken(true);
      return token ? fetchUserQuery(undefined, { Authorization: `Bearer ${token}` }) : undefined;
    },
    {
      select: (data) => data?.me,
    }
  );
};
