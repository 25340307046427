import { Client } from '@twilio/conversations';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';

export const useHandleNewConversationsAdded = (conversationsClient: Client | null) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    const invalidateConversations = () => {
      queryClient.refetchQueries(['myConversations']);
    };
    conversationsClient?.on('conversationJoined', invalidateConversations);
    conversationsClient?.on('conversationAdded', invalidateConversations);

    return () => {
      conversationsClient?.off('conversationJoined', invalidateConversations);
      conversationsClient?.off('conversationAdded', invalidateConversations);
    };
  }, [queryClient, conversationsClient]);
};
