import { orderBy } from 'lodash/fp';
import { useQuery } from 'react-query';

import { useAuth } from '@hooks';

import { fetchMyConversations } from './fetchMyConversations';

export const useMyConversations = () => {
  const auth = useAuth();
  return useQuery(
    ['myConversations', auth.user?.uid],
    async () =>
      fetchMyConversations({
        filter: {
          conversationUserType: 'BUYER',
        },
      }).then((res) =>
        orderBy(
          (item) =>
            item.lastMessage?.created_at ? new Date(item.lastMessage.created_at).getTime() : -1,
          'desc',
          res.myConversationList?.data ?? []
        )
      ),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
};
