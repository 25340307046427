import { Stack } from 'react-bootstrap';

import { ImageLoader } from '@components/ImageLoader';

import Icons from './cardIcons';

const mapping = {
  stripeTo: {
    cardInput: {
      Visa: 'visa',
      'American Express': 'american-express',
      MasterCard: 'master-card',
      Discover: 'discover',
      JCB: 'jcb',
      'Diners Club': 'diners-club',
      Unknown: null,
    } as const,
  },
};

type CardInputLibraryBrand =
  | 'visa'
  | 'master-card'
  | 'american-express'
  | 'diners-club'
  | 'discover'
  | 'jcb'
  | 'unionpay'
  | 'maestro';

export const getCreditCardImage = (brand: string) => {
  // @ts-ignore
  const mapped = mapping.stripeTo.cardInput[brand];
  const cardType = (mapped ? mapped : brand.toLowerCase()) as CardInputLibraryBrand;
  // @ts-ignore
  const cardIcon = cardType in Icons ? Icons[cardType] : Icons.placeholder;
  return cardIcon;
};

export const fromStripeExpiry = (expiry: string) => {
  const parts = expiry.split('/');
  parts[0] = Number(parts[0]) <= 9 ? '0' + Number(parts[0]) : parts[0];
  parts[1] = parts[1].length !== 2 ? parts[1].substr(2, 2) : parts[1];

  return parts.join('/');
};

type CreditCardProps = {
  displayName: string;
  creditCardNumber: string;
  creditCardExpiry: string;
  brand: string;
};
export const CreditCard = ({
  displayName,
  creditCardNumber,
  creditCardExpiry,
  brand,
}: CreditCardProps) => {
  return (
    <Stack
      gap={3}
      direction="horizontal"
      className="border border-accent rounded p-3 align-items-center"
    >
      <ImageLoader
        width={40}
        height={40}
        style={{ objectFit: 'contain' }}
        src={getCreditCardImage(brand)}
        alt=""
      />
      <Stack className="align-self-center">
        <h5 className="fs-sm mb-0 fw-bolder font-serif">{displayName}</h5>
        <div className="d-flex justify-content-between flex-grow-1">
          <p className="fs-xs mb-0">{creditCardNumber}</p>
          <p className="fs-xs mb-0">{fromStripeExpiry(creditCardExpiry)}</p>
        </div>
      </Stack>
    </Stack>
  );
};
