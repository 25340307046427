import { CSSProperties } from 'react';

export const Circle = ({
  radius,
  color,
  className,
  style,
}: {
  radius: number;
  color?: string;
  className?: string;
  style?: CSSProperties;
}) => (
  <span
    className={`rounded-circle ${className || ''}`}
    style={{
      width: radius * 2,
      height: radius * 2,
      ...(color ? { backgroundColor: color } : {}),
      ...style,
    }}
  />
);
