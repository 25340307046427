import { OrderStatus } from '@api/generated/graphql';

export const messagingAndInvoicingContainerMaxHeight = '80vh'; // 80vh is from requirement,

export type InvoiceState = 'PAID' | 'PENDING' | 'REJECTED';
export const invoiceStateByEntity: Record<OrderStatus, InvoiceState> = {
  [OrderStatus.Cancelled]: 'REJECTED',
  [OrderStatus.Completed]: 'PAID',
  [OrderStatus.FullySigned]: 'PAID',
  [OrderStatus.HalfSigned]: 'PENDING',
  [OrderStatus.InProgress]: 'PENDING',
  [OrderStatus.UnpayedCompleted]: 'PENDING',
  [OrderStatus.Requested]: 'PENDING',
  [OrderStatus.PaidNotComplete]: 'PAID',
};
