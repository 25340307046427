import axios from 'axios';
import { get } from 'lodash/fp';

export type SubscribeNewsletterEntity = {
  email: string;
  firstName?: string;
  lastName?: string;
  sms?: string;
  city?: string;
  state?: string;
};

type SubscribeNewsletterError = {
  response: {
    data?: {
      message?: string;
    };
  };
};

export const isSubscribeNewsletterError = (res: unknown): res is SubscribeNewsletterError =>
  Boolean(get(['response', 'data', 'message'], res));

type SubscribeNewsletterResponse = {
  data: {
    id: string;
  };
};

export const subscribeNewsletter = (values: SubscribeNewsletterEntity) =>
  axios.post<SubscribeNewsletterEntity, SubscribeNewsletterResponse>(
    '/api/subscribeNewsletter',
    values
  );
