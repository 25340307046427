import type { Store } from '@hooks/useStores';

export type StoreDetailsPayload = {
  eventDetail: {
    storefrontId: number;
    'business name': string;
    'visual storefront flags': string[];
    distance?: number;
  };
};

export const getStoreDetailsPayload = (
  store: Pick<Store, 'id' | 'businessName' | 'visualTags'>,
  distance?: number
) => ({
  eventDetail: {
    storefrontId: store.id,
    'business name': store.businessName,
    'visual storefront flags': store.visualTags,
    distance,
  },
});
