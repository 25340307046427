import Link from 'next/link';
import { Col, Container, Row, Stack } from 'react-bootstrap';

import { FooterNewsletter } from '@components/FooterNewsletter';

import { ImageLoader } from './ImageLoader';
import { MarketButton } from './MarketButton';

export const Footer = ({
  className,
  theme = 'light',
  isBigLogo = false,
  isDisplayDownloadApp = false,
}: {
  className?: string;
  theme?: 'light' | 'dark';
  isBigLogo?: boolean;
  isDisplayDownloadApp?: boolean;
}) => {
  const textColor = theme === 'light' ? 'text-primary' : 'text-light';
  const bgColor = theme === 'light' ? 'bg-light' : 'bg-primary';

  const renderCopyright = () => (
    <>
      <small>© 2024 Copyright WestTenth. All rights reserved.</small>
      <small>Site by SDCO Partners</small>
    </>
  );

  const renderText = () => (
    <>
      A Marketplace For
      <br /> Local Experts &
      <br /> Original Ideas
    </>
  );

  return (
    <div className={`${className ?? ''} ${textColor} ${bgColor}`}>
      <div className="mx-md-2">
        <Container className="pt-md-4">
          {isBigLogo && (
            <Row className="d-none d-md-block">
              <Col>
                <Link href="/" passHref>
                  <ImageLoader
                    priority
                    src={
                      theme === 'light' ? '/images/logo-text.svg' : '/images/logo-text-light.svg'
                    }
                    width={193}
                    height={31}
                    alt="wtenth logo"
                    className="mb-2 w-100 px-2 px-lg-3"
                  />
                </Link>
              </Col>
            </Row>
          )}

          <Row className="d-md-none">
            <Col className="border-top border-bottom border-primary py-3 my-2">
              <Row className="align-items-center mx-sm-2">
                <Col>
                  <Link href="/" passHref>
                    <ImageLoader
                      priority
                      src={
                        theme === 'light'
                          ? '/images/logo-text-vertical.svg'
                          : '/images/logo-text-vertical-light.svg'
                      }
                      width={85}
                      height={66}
                      alt="wtenth logo"
                    />
                  </Link>
                </Col>
                <Col xs={8} sm={7}>
                  <div className="font-serif" style={{ fontSize: '1.4rem', lineHeight: 1 }}>
                    {renderText()}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mt-md-4 pb-3 pb-lg-4">
            <Col className="d-none d-md-block">
              <div className="mx-lg-4 mx-xl-5 mx-xxl-6">
                {!isBigLogo && (
                  <Link href="/" passHref>
                    <ImageLoader
                      priority
                      src={
                        theme === 'light' ? '/images/logo-text.svg' : '/images/logo-text-light.svg'
                      }
                      width={193}
                      height={31}
                      alt="wtenth logo"
                      className="mb-3 w-75"
                    />
                  </Link>
                )}
                <div className="font-serif display-6">{renderText()}</div>
                <div className="d-flex flex-column mt-4">{renderCopyright()}</div>
              </div>
            </Col>

            <Col>
              <div className="mx-lg-4 mx-xl-5 mx-xxl-6">
                <div className="d-flex justify-content-between gap-1">
                  <div>
                    <div className="text-uppercase mb-3 fw-bolder">Shop</div>
                    <ul className="list-unstyled fs-sm">
                      <li className="mb-0">
                        <a
                          target="_blank"
                          href="https://marketplace.westtenth.com"
                          className="nav-link"
                          rel="noreferrer"
                        >
                          Marketplace
                        </a>
                      </li>
                      <li className="mb-0">
                        <a
                          target="_blank"
                          href="https://www.westtenth.com/wedding"
                          className="nav-link"
                          rel="noreferrer"
                        >
                          Wedding Guide
                        </a>
                      </li>
                      <li className="mb-0">
                        <a
                          target="_blank"
                          href="https://www.westtenth.com/giftguide"
                          className="nav-link"
                          rel="noreferrer"
                        >
                          Holiday Gift Guide
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div className="text-uppercase mb-3 fw-bolder">Sell</div>
                    <ul className="list-unstyled fs-sm">
                      <li className="mb-0">
                        <a
                          target="_blank"
                          href="https://www.westtenth.com/sellwithus"
                          className="nav-link"
                          rel="noreferrer"
                        >
                          Sell With Us
                        </a>
                      </li>
                      <li className="mb-0">
                        <a
                          target="_blank"
                          href="https://www.westtenth.com/blog"
                          className="nav-link"
                          rel="noreferrer"
                        >
                          Blog
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div className="text-uppercase mb-3 fw-bolder">Company</div>
                    <ul className="list-unstyled fs-sm">
                      <li className="mb-0">
                        <a
                          target="_blank"
                          href="https://www.westtenth.com/about"
                          className="nav-link"
                          rel="noreferrer"
                        >
                          About
                        </a>
                      </li>
                      <li className="mb-0">
                        <a
                          className="nav-link"
                          target="_blank"
                          href="https://www.westtenth.com/privacy"
                          rel="noreferrer"
                        >
                          Privacy Policy
                        </a>
                      </li>
                      <li className="mb-0">
                        <a
                          className="nav-link"
                          target="_blank"
                          href="https://www.westtenth.com/termsofservice"
                          rel="noreferrer"
                        >
                          Terms & Conditions
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <Row className="mt-3">
                  <Col>
                    <FooterNewsletter theme={theme} />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col>
                    <div className="mt-1 d-flex justify-content-md-between gap-3">
                      <Link
                        href="https://www.instagram.com/w10th/"
                        target="_blank"
                        rel="noreferrer"
                        className={`text-uppercase fw-bolder opacity-50 fs-sm ${textColor}`}
                        style={{ textDecoration: 'none' }}
                      >
                        Instagram
                      </Link>
                      <Link
                        href="https://www.facebook.com/W10th/"
                        target="_blank"
                        rel="noreferrer"
                        className={`text-uppercase fw-bolder opacity-50 fs-sm ${textColor}`}
                        style={{ textDecoration: 'none' }}
                      >
                        Facebook
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          {isDisplayDownloadApp && (
            <Row className="pb-2 mt-lg-n1">
              <Col className="position-relative">
                <div className="position-absolute top-0 end-0 pe-4 mt-n4 d-md-none">
                  <ImageLoader
                    src="/images/locally-grown.png"
                    width={85}
                    height={85}
                    alt="Locally Grown"
                  />
                </div>

                <div className="bg-primary rounded-3 py-4 py-md-2 px-5">
                  <Row className="align-items-center">
                    <Col xs={12} md={4} lg={5} className="px-6 px-md-0">
                      <ImageLoader src="/images/footer.png" width={300} height={300} alt="footer" />
                    </Col>
                    <Col
                      xs={12}
                      md={{ span: 7, offset: 1 }}
                      lg={{ span: 6, offset: 1 }}
                      className="my-1 text-center text-md-start"
                    >
                      <h4 className="font-title text-light mb-2 display-2">
                        Take West Tenth
                        <br />
                        With You On-The-Go
                      </h4>
                      <p className="fs-sm mb-0 text-light fw-bolder">
                        Continue exploring and supporting
                        <br /> local with our mobile app.
                      </p>

                      <div className="mt-4 mt-md-3 d-flex justify-content-center justify-content-md-start mx-n4 mx-md-0 gap-1 gap-sm-3">
                        <MarketButton
                          href={process.env.NEXT_PUBLIC_DOWNLOAD_APPLE_URL}
                          market="apple"
                        />
                        <MarketButton
                          href={process.env.NEXT_PUBLIC_DOWNLOAD_ANDROID_URL}
                          market="android"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          )}

          <div className="mt-3 pb-6 d-flex flex-column d-md-none text-center">
            {renderCopyright()}
          </div>
        </Container>
      </div>
    </div>
  );
};
