import { DependencyList, RefObject, useEffect, useState } from 'react';

export const useHeight = <T extends Element>(ref: RefObject<T>, deps: DependencyList = []) => {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      // get the new height from the first entry
      const newHeight = entries[0].contentRect.height;
      setHeight(newHeight);
    });

    const refElement = ref.current;

    if (refElement) {
      resizeObserver.observe(refElement);

      return () => {
        if (refElement) {
          resizeObserver.unobserve(refElement);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return height;
};
