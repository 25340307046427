import { Button, Modal, Stack } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

import { breakpoints } from '@constants';
import { useIsLaptopOrDesktop } from '@hooks';
import { ExploreTalents } from '@screens/HomeScreen/components';

export const InvoicePaidModal = ({
  visible,
  onClose,
}: {
  visible?: boolean;
  onClose: () => void;
}) => {
  const isMobileView = useMediaQuery({ query: `(max-width: ${breakpoints.md})` });

  return (
    <Modal size="xl" show={visible} onHide={onClose} fullscreen="lg-down">
      <Modal.Header closeButton className="border-0" />
      <Modal.Body>
        <div className="d-flex justify-content-center">
          <div className="w-lg-75">
            <Stack gap={3}>
              <Stack gap={3} className="ms-lg-7">
                <h4 className="ms-lg-n5 mb-0 text-brownsugar fst-italic font-serif fw-bolder">
                  <i className="fi-check-circle me-2" />
                  Thanks for supporting a locally grown business!
                </h4>
                <p className="text-dark mb-0">
                  Explore other talented creators, makers, &amp; entrepreneurs right in your
                  community!
                </p>
              </Stack>
              <div className="d-flex justify-content-center mb-3">
                <ExploreTalents itemsToDisplay={isMobileView ? 2 : 3} />
              </div>
              <div className="d-flex justify-content-center">
                <Button
                  onClick={onClose}
                  variant="primary"
                  className="rounded-pill text-light"
                  style={{ width: 300, maxWidth: '100%' }}
                >
                  Close
                </Button>
              </div>
            </Stack>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
