import { useCallback } from 'react';

import type { Store } from '@hooks';

import { getStoreDetailsPayload } from './getStoreDetailsPayload';
import { trackEvent } from './trackEvent';
import { EventName } from './types';
import { useBreadcrumbPayload } from './useBreadcrumbPayload';
import { useGetBaseEventPayload } from './useGetBaseEventPayload';

export const useTrackSignUpComplete = (store: Store) => {
  const breadcrumb = useBreadcrumbPayload();
  const getBaseEventPayload = useGetBaseEventPayload();
  return useCallback(
    async (distance?: number) => {
      const baseEventPayload = await getBaseEventPayload({ element: 'button', label: 'Create' });
      if (baseEventPayload) {
        trackEvent(EventName.SignUpComplete, {
          ...breadcrumb,
          ...baseEventPayload,
          ...getStoreDetailsPayload(store, distance),
        });
      }
    },
    [breadcrumb, getBaseEventPayload, store]
  );
};
