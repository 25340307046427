import { Badge, Stack } from 'react-bootstrap';

import { VisualTag } from '@hooks';
import { badgeByVisualTag } from '@utils';

export const VisualTags = ({ items }: { items: VisualTag[] }) => {
  return (
    <Stack direction="horizontal" className="gap-3">
      {items
        .map((tag) => badgeByVisualTag[tag])
        .map((badge) => (
          <Badge key={badge.title} bg={badge.color} className="font-serif fst-italic fw-bolder">
            {badge.title}
          </Badge>
        ))}
    </Stack>
  );
};
