export const formatDate = (date: Date) => date.toLocaleDateString('en-CA');

export const addDays = (days: number, date = new Date()) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const addMinutes = (minutes: number) => (date: Date) => {
  const result = new Date(date);
  result.setMinutes(result.getMinutes() + minutes);
  return result;
};
