import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { getSdk } from '@api/generated/graphql';
import { auth, useAuth } from '@hooks/useAuth';

import { graphqlClient } from './graphqlClient';

export const getApiClient = (idToken?: string) =>
  getSdk(graphqlClient, async (action) => {
    const token = idToken || (await auth?.currentUser?.getIdToken(true));
    return action({
      Authorization: `Bearer ${token}`,
    });
  });
export const useApiClient = () => {
  const auth = useAuth();
  const { data: idToken } = useQuery([auth.user?.uid], () => auth.user?.getIdToken(true));

  return useMemo(() => getApiClient(idToken), [idToken]);
};
