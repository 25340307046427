import { CSSProperties, ReactNode, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';

import { PasswordToggle } from '@components/PasswordToggle';
import { useAuth } from '@hooks';

type SignInVM = { password: string; email: string };

export const SignInForm = ({
  onSuccess,
  onToggle,
  onForgotPassword,
  title,
  subTitle,
  style,
}: {
  title?: ReactNode;
  subTitle?: ReactNode;
  onSuccess?: () => void;
  onToggle?: () => void;
  onForgotPassword?: () => void;
  style?: CSSProperties;
}) => {
  const [loading, setLoading] = useState(false);
  const { signInWithEmailAndPassword } = useAuth();

  const methods = useForm<SignInVM>({});
  const [error, setError] = useState('');

  const signIn: SubmitHandler<SignInVM> = async (values) => {
    try {
      setLoading(true);
      await signInWithEmailAndPassword(values.email, values.password);
      methods.reset();
      setError('');
      setLoading(false);
      onSuccess?.();
    } catch {
      setLoading(false);
      setError('Invalid email or password');
    }
  };

  return (
    <Form noValidate onSubmit={methods.handleSubmit(signIn)}>
      {title && <h4 className="mb-3 text-center">{title}</h4>}
      {subTitle && <p className="fs-sm text-center">{subTitle}</p>}
      <div className="mx-auto" style={style}>
        <Form.Group controlId="si-email" className="mb-3">
          <Form.Control
            size="lg"
            type="email"
            placeholder="Email"
            required
            {...methods.register('email')}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <PasswordToggle
            size="lg"
            id="si-password"
            placeholder="Password"
            required
            {...methods.register('password')}
          />
        </Form.Group>
        {error && <span className="mb-3 mt-0 invalid-feedback d-block">{error}</span>}
        <Button
          type="submit"
          size="lg"
          disabled={loading}
          variant={`primary rounded-pill w-100 d-flex justify-content-center align-items-center gap-2`}
        >
          {loading && <Spinner animation="border" role="status" size="sm" />}
          Login
        </Button>
        {onToggle ? (
          <div className="d-flex justify-content-center align-items-center mt-3">
            Don&apos;t have an account?
            <Button variant="link text-brownsugar" className="px-2" onClick={onToggle}>
              Sign up here
              <i className="fi-arrow-long-right ms-2" />
            </Button>
          </div>
        ) : null}
        {onForgotPassword ? (
          <div className="d-flex justify-content-center align-items-center">
            <Button variant="link text-brownsugar" className="px-2" onClick={onForgotPassword}>
              Forgot password?
              <i className="fi-arrow-long-right ms-2" />
            </Button>
          </div>
        ) : null}
      </div>
    </Form>
  );
};
