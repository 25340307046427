import moment from 'moment';
import { Button } from 'react-bootstrap';

import { ImageLoader } from '@components/ImageLoader';

import { InvoiceDetailsVM } from './useInvoiceDetails';

type PaidInvoiceDetailsProps = {
  invoice: InvoiceDetailsVM | undefined;
  onClose: () => void;
};
export const PaidInvoiceDetailsRightColumn = ({ invoice, onClose }: PaidInvoiceDetailsProps) => (
  <>
    <div className="d-flex flex-grow-1 align-items-center justify-content-center">
      <div className="flex-grow-1">
        <h5 className="text-success font-serif d-flex align-items-center">
          <i className="fi-check-circle me-2" />
          You paid on {moment(invoice?.chargedAt).format('MMMM DD, YYYY')}
        </h5>
        <div className="d-flex justify-content-center mt-lg-4">
          <ImageLoader
            src="/images/SuccessfullyPaidInvoiceImage.png"
            width={279}
            height={207}
            alt="successfully paid order image"
          />
        </div>
      </div>
    </div>

    <div className="d-flex justify-content-end gap-3">
      <Button variant="primary" onClick={onClose} className="rounded-pill w-100 w-lg-50 text-light">
        Close
      </Button>
    </div>
  </>
);
