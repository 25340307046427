import Link from 'next/link';
import { ReactNode } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import { CatalogItem } from '@hooks';
import { useBreadcrumbPayload } from '@packages/event-tracking';

import { ImageLoader } from './ImageLoader';

export const StoreCatalog = ({
  items,
  storeUrl,
  title = `${items.length} Item${items.length > 1 ? 's' : ''}`,
}: {
  storeUrl: string;
  items: CatalogItem[];
  title?: ReactNode;
}) => {
  const breadcrumb = useBreadcrumbPayload();
  return items.length ? (
    <>
      <h4
        className="font-title display-6 border-bottom"
        style={{ lineHeight: '0.1', marginTop: '4rem', marginBottom: '1.6rem' }}
      >
        <span className="bg-light pe-3">{title}</span>
      </h4>
      <Row xs={1} sm={2} md={2} lg={2}>
        {items.map((item) => (
          <Col key={item.name} className="p-2">
            <Link
              passHref
              href={{
                pathname: `/shop/${storeUrl}/listing/${item.uriSlug}`,
                query: breadcrumb['user behavior'],
              }}
              as={`/shop/${storeUrl}/listing/${item.uriSlug}`}
              style={{ all: 'unset' }}
            >
              <Card className="cursor-pointer rounded-3 shadow-sm border-0 h-100 overflow-hidden">
                <div className="d-flex justify-content-center position-relative ratio ratio-4x3">
                  <ImageLoader
                    src={item.rawurl[0]}
                    fill
                    style={{ objectFit: 'cover', objectPosition: 'center' }}
                    alt={item.name}
                  />
                </div>
                <Card.Body className="p-3 d-flex flex-column">
                  <h6 className="mb-1 fw-bolder text-primary">{item.name}</h6>
                  <div className="flex-grow-1 fs-sm text-truncate text-truncate--2">
                    {item.description}
                  </div>
                  <span className="align-items-center d-flex mt-2">
                    <span
                      className="h3 font-title mb-0 text-primary"
                      style={{ marginTop: '-.2rem' }}
                    >
                      ${item.price}
                    </span>
                    <span className="text-secondary">
                      {item.priceType === 'PER_HOUR' ? '/hr' : ''}
                    </span>
                  </span>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </>
  ) : null;
};
