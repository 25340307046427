export const FullscreenBackdrop = ({ visible }: { visible?: boolean }) => {
  return (
    <div
      style={{
        opacity: visible ? 1 : 0,
        transition: '.3s',
        pointerEvents: visible ? 'all' : 'none',
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, .5)',
      }}
    />
  );
};
