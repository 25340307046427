import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { useApiClient } from '@api/apiClient';
import { useCurrentScreen } from '@hooks/useCurrentScreen';

import { deleteCreditCardPubSub } from '../../../useTrackInvoiceDetailsEvents';

export const useRemovePaymentMethod = () => {
  const queryClient = useQueryClient();
  const { screen } = useCurrentScreen();
  const apiClient = useApiClient();

  return useMutation(() => apiClient.RemovePaymentMethod(), {
    onSuccess: async () => {
      await queryClient.refetchQueries(['creditCard']);
      deleteCreditCardPubSub.publish(screen!);
      toast.success('Your payment information was successfully removed.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  });
};
