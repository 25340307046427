import { Fullfillment } from '../../hooks/useStores';

const bgFadedDark = '';
const bgHighlightOutside = 'almond';
const bgHighlightInside = 'purple';

export const bgColorForFulfillmentOptionWithDistanceOutsideRadius: Record<Fullfillment, string> = {
  'Pick Up': bgFadedDark,
  Shipping: bgHighlightOutside,
  'Local Delivery': bgFadedDark,
  Onsite: bgFadedDark,
  'Virtual Service': bgHighlightOutside,
};

export const bgColorForFulfillmentOptionWithDistanceInsideRadius: Record<Fullfillment, string> = {
  'Pick Up': bgHighlightInside,
  Shipping: bgFadedDark,
  'Local Delivery': bgHighlightInside,
  Onsite: bgHighlightInside,
  'Virtual Service': bgFadedDark,
};

export const iconByFullfillmentOption: Record<Fullfillment, string> = {
  'Pick Up': 'fi-map-pin',
  Shipping: 'fi-truck',
  'Local Delivery': 'fi-map-pin',
  Onsite: 'fi-map-pin',
  'Virtual Service': 'fi-chat-right',
};
