import { Index } from 'react-instantsearch';

import { isSuperCategory, useSuperCategories } from '@api';
import { ConfigureSearch } from '@components/ConfigureSearch';
import { StoreCarousel } from '@components/StoreCarousel';
import {
  searchIndex,
  seasonalCategoryId,
  whatsNewCategoryId,
  whatsNewDays,
  whatsNewIndex,
  whatsNewPrecision,
} from '@constants';

enum Category {
  Trending = 7777,
}

export const trendingCategory = {
  id: Category.Trending,
  title: `Featured Do-ers & Experts`,
  uriSlug: 'browse/category/trending',
};

export const seasonalCategory = {
  id: seasonalCategoryId,
  title: `See the Seasonal Best`,
};

export const whatsNewCategory = {
  id: whatsNewCategoryId,
  title: `What’s New on West Tenth`,
  uriSlug: 'browse/category/fresh',
};

type StoresCarouselProps = {
  id: number;
  title: string;
  uriSlug?: string;
  isFrontPage?: boolean;
};

export const StoresCarousel = ({ title, id, uriSlug, isFrontPage }: StoresCarouselProps) => {
  const isWhatsNew = id === whatsNewCategoryId;
  const whatsNewDate = new Date();
  whatsNewDate.setDate(whatsNewDate.getDate() - whatsNewDays);

  const { data: superCategories } = useSuperCategories();
  const findCategoryUriSlug = (categoryId: number) => {
    const category = superCategories?.categoryTaxonomy.find((cat) => cat.categoryId === categoryId);
    return category
      ? isSuperCategory(category)
        ? `browse/${category?.uriSlug}`
        : `browse/category/${category?.uriSlug}`
      : '';
  };

  return (
    <>
      <Index indexName={isWhatsNew ? whatsNewIndex : searchIndex}>
        <ConfigureSearch
          filters={
            isWhatsNew
              ? `approved_timestamp > ${whatsNewDate.getTime() / 1000}`
              : `categoryIds:${id}`
          }
          {...(isWhatsNew && { aroundPrecision: whatsNewPrecision })}
          index={isWhatsNew ? whatsNewIndex : searchIndex}
        />
        <StoreCarousel
          carouselName={isWhatsNew ? 'new' : `category-${id}`}
          title={title}
          link={{ href: `/${uriSlug ? uriSlug : findCategoryUriSlug(id)}`, text: 'See more' }}
          isFrontPage={isFrontPage}
        />
      </Index>
    </>
  );
};
