import { useRouter } from 'next/router';
import { Button, Col, FormGroup, Row } from 'react-bootstrap';

import { PlaceSearch } from '@components';

export const HomePlaceSearch = () => {
  const router = useRouter();

  return (
    <FormGroup className="form-group d-block rounded-lg-pill w-lg-75">
      <Row className="row align-items-center g-0 ms-n2">
        <Col xs={12} lg={7} xl={8} className="d-flex align-items-center ps-3">
          <PlaceSearch noBorder />
        </Col>
        <hr className="text-secondary d-lg-none my-2" />
        <Col lg={5} xl={4}>
          <Button
            onClick={() => router.push('/search')}
            variant="primary rounded-pill"
            className="text-dark w-100"
          >
            Browse nearby
          </Button>
        </Col>
      </Row>
    </FormGroup>
  );
};
