import { useTrackAddCreditCard } from './useTrackAddCreditCard';
import { useTrackDeleteCreditCard } from './useTrackDeleteCreditCard';
import { useTrackDiscountCodeError } from './useTrackDiscountCodeError';
import { useTrackDiscountCodeRemove } from './useTrackDiscountCodeRemove';
import { useTrackDiscountCodeSuccess } from './useTrackDiscountCodeSuccess';
import { useTrackPayInvoice } from './useTrackPayInvoice';
import { useTrackRejectInvoice } from './useTrackRejectInvoice';
import { InvoiceDetailsVM } from '../useInvoiceDetails';

export const useTrackInvoiceDetailsEvents = (currentInvoice?: InvoiceDetailsVM) => {
  useTrackAddCreditCard();
  useTrackDeleteCreditCard();
  useTrackRejectInvoice(currentInvoice);
  useTrackPayInvoice(currentInvoice);
  useTrackDiscountCodeSuccess();
  useTrackDiscountCodeError();
  useTrackDiscountCodeRemove();
};
