import Head from 'next/head';

import { siteDomain } from '@constants';

export const HomeScreenHead = () => {
  return (
    <Head>
      <title>
        West Tenth | Find locally made products & services from small business you&apos;ll love
      </title>
      <meta
        name="og:title"
        content="West Tenth | Find locally made products & services from small business you'll love"
      />
      <meta
        name="og:description"
        content="Plan a party, organize a home, or book a family photoshoot with creative entrepreneurs you’ll love on West Tenth; The Go-to for local Artists, Organizers, Healers, Planners, Bakers, & Doers."
      />
      <meta
        name="og:image"
        content={`${siteDomain}/_next/image?url=%2Fimages%2Fsharew10new.png&w=1080&q=75`}
      />

      <meta
        name="description"
        content="Whether you need to plan a party, organize a home, or book a family photoshoot, search and book services on West Tenth from bakers to house cleaners & more."
      />
      <meta
        name="keywords"
        content="Party decor, event rentals, beauty services, fitness trainer, local photographer, home services near me, small business services, unique gifts, custom gifts, wedding vendors near me"
      />
    </Head>
  );
};
