import { ReactNode } from 'react';
import { Stack } from 'react-bootstrap';
import { useUpdateEffect } from 'react-use';

import { StoreOwnerImage } from '@components';

import { ConversationItem } from './ConversationItem';
import { useScrollToTop } from './useScrollToTop';
import { ConversationEntity } from '../../../fetchMyConversations';
import { useMyConversations } from '../../../useMyConversations';

type ConversationsListProps = {
  activeConversationId?: number;
  onConversationClick: (item: ConversationEntity) => void;
  prefix?: ReactNode;
};
export const ConversationsList = ({
  activeConversationId,
  onConversationClick,
  prefix,
}: ConversationsListProps) => {
  const { data: conversations = [] } = useMyConversations();

  const [ref, scrollToTop] = useScrollToTop();

  useUpdateEffect(() => {
    scrollToTop('auto');
  }, [conversations, scrollToTop]);

  return (
    <Stack
      ref={ref}
      direction="vertical"
      gap={3}
      className={`overflow-auto p-3`}
      style={{ maxHeight: '50vh' }}
    >
      {prefix}
      {conversations?.map((conversation) => {
        const hasUnreadMessages = conversation.unReadMessageCountByUser > 0;
        const isSelected = activeConversationId === conversation.conversationId;

        const businessName = conversation.storefrontJSON?.businessName;

        const withBorder = hasUnreadMessages || isSelected;
        return (
          <ConversationItem
            tooltip={
              <>
                {businessName ? `${businessName} by ` : ''}
                {conversation.otherUser.firstName ?? ''} {conversation.otherUser.lastName ?? ''}
              </>
            }
            onClick={() => onConversationClick(conversation)}
            withNotificationBadge={hasUnreadMessages}
            withBorder={withBorder}
            key={conversation.conversationId}
            activeNotificationBadgeColor="primary"
            color="primary"
            image={
              <StoreOwnerImage
                noBorder
                rounded={1}
                size={56}
                src={conversation.otherUser.photoURL ?? null}
              />
            }
          />
        );
      })}
    </Stack>
  );
};
