import { useEffect, useRef } from 'react';
import { useClickAway } from 'react-use';

import { toggleMessagingButton } from '@components';
import { useAuth } from '@hooks/useAuth';

import {
  FullscreenBackdrop,
  MessagingAndInvoicingContent,
  MessagingAndInvoicingList,
} from './components';
import { InvoiceDetailsModal } from './components/MessagingAndInvoicingContent/InvoicingContent/components';
import { useGalleryOpened, useMessagingAndInvoicing } from './hooks';
import { MessengerContextProvider } from './MessengerContext';
import { useTrackInvoicingEvents } from './useTrackInvoicingEvents';

export const MessagingAndInvoicing = () => {
  const { close, messagingAndInvoicing, toggleInvoicing, toggleMessagingDetails } =
    useMessagingAndInvoicing();

  const { isOpened: isGalleryOpened } = useGalleryOpened();
  const containerRef = useRef<HTMLDivElement>(null);
  useClickAway(containerRef, (e) => {
    const target = e.target as HTMLElement;
    const isChatIcon = target.id === toggleMessagingButton;

    if (!isGalleryOpened && messagingAndInvoicing.activeTab !== 'invoicing' && !isChatIcon) {
      close();
    }
  });

  const auth = useAuth();
  useEffect(() => {
    if (!auth.user) {
      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user]);

  useTrackInvoicingEvents();

  if (!auth.user) {
    return null;
  }

  const shouldShowBackdrop =
    messagingAndInvoicing.activeTab === 'invoicing' &&
    messagingAndInvoicing.visibilityState === 'expanded';

  return (
    <MessengerContextProvider>
      <div ref={containerRef}>
        <FullscreenBackdrop visible={shouldShowBackdrop} />
        <MessagingAndInvoicingContent
          onClose={close}
          toggleMessagingDetails={toggleMessagingDetails}
          messagingAndInvoicing={messagingAndInvoicing}
        />
        <MessagingAndInvoicingList
          toggleInvoicing={toggleInvoicing}
          toggleMessagingDetails={toggleMessagingDetails}
          messagingAndInvoicing={messagingAndInvoicing}
        />
        <InvoiceDetailsModal />
      </div>
    </MessengerContextProvider>
  );
};
