import { create } from 'zustand';

import { ConversationEntity } from '../../fetchMyConversations';
import { MessagingAndInvoicingState } from '../types';

const messagingAndInvoicingInitialState: MessagingAndInvoicingState = {
  visibilityState: 'collapsed',
};

type MessagingAndInvoicingStore = {
  messagingAndInvoicing: MessagingAndInvoicingState;
  close: () => void;
  toggleInvoicing: () => void;
  toggleMessaging: () => void;
  toggleMessagingDetails: (conversation: ConversationEntity) => void;
};

export const useMessagingAndInvoicing = create<MessagingAndInvoicingStore>((set, get) => ({
  messagingAndInvoicing: messagingAndInvoicingInitialState,
  close: () =>
    set({
      messagingAndInvoicing: {
        ...get().messagingAndInvoicing,
        visibilityState: 'collapsed',
      },
    }),
  toggleInvoicing: () => {
    const { activeTab, visibilityState } = get().messagingAndInvoicing;
    set({
      messagingAndInvoicing: {
        activeTab: 'invoicing',
        visibilityState:
          activeTab && ['messaging', 'messaging-details'].includes(activeTab)
            ? 'expanded'
            : visibilityState === 'collapsed'
            ? 'expanded'
            : 'collapsed',
      },
    });
  },
  toggleMessaging: () => {
    const { messagingAndInvoicing, close } = get();
    const shouldClose =
      messagingAndInvoicing.activeTab === 'messaging' &&
      messagingAndInvoicing.visibilityState === 'expanded';
    shouldClose
      ? close()
      : set({
          messagingAndInvoicing: {
            visibilityState: 'expanded',
            activeTab: 'messaging',
          },
        });
  },
  toggleMessagingDetails: (conversation: ConversationEntity) => {
    const { messagingAndInvoicing, close } = get();

    messagingAndInvoicing.activeTab === 'messaging-details' &&
    messagingAndInvoicing.selectedConversation?.conversationId === conversation.conversationId &&
    messagingAndInvoicing.visibilityState === 'expanded'
      ? close()
      : set({
          messagingAndInvoicing: {
            visibilityState: 'expanded',
            activeTab: 'messaging-details',
            selectedConversation: conversation,
          },
        });
  },
}));
