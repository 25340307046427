import { create } from 'zustand';

export const DEFAULT_DISTANCE = 'Any';

export const useSelectedDistance = create<{
  distance: string;
  setDistance: (distance?: string) => void;
}>((set) => ({
  distance: DEFAULT_DISTANCE,
  setDistance: (distance) => set({ distance: distance ?? DEFAULT_DISTANCE }),
}));
