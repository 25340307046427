import { useEffect, useMemo } from 'react';
import { Configure, ConfigureProps, useRefinementList } from 'react-instantsearch';

import { DEFAULT_DISTANCE, useSelectedDistance, useUserCoordinates } from '@hooks';
import { milesToMeters } from '@utils';

export const PAGE_SIZE = 8;

type ConfigureSearchProps = ConfigureProps & {
  geoCenter?: {
    lat?: number;
    lng?: number;
    aroundRadius?: string;
  };
};

export const ConfigureSearch = (props: ConfigureSearchProps) => {
  const { coords } = useUserCoordinates();
  const { distance: selectedDistance, setDistance } = useSelectedDistance();
  const { geoCenter, ...AlgoliaProps } = props;

  const distance = geoCenter?.aroundRadius || selectedDistance;
  const aroundRadius =
    isNaN(+distance) || distance === DEFAULT_DISTANCE ? undefined : milesToMeters(+distance || 50);

  useEffect(() => {
    setDistance(distance);
  }, [distance]);

  const config = useMemo(
    () =>
      geoCenter?.lat && geoCenter.lng
        ? {
            aroundLatLng: `${geoCenter.lat}, ${geoCenter.lng}`,
          }
        : coords
        ? { aroundLatLng: `${coords.lat}, ${coords.lng}` }
        : {
            aroundLatLngViaIP: true,
          },
    [coords, geoCenter]
  );
  useRefinementList({ attribute: 'paused' });
  useRefinementList({ attribute: 'categories.lvl0' });
  useRefinementList({ attribute: 'fulfillmentOptions' });

  return (
    <Configure
      {...config}
      facets={['*']}
      hitsPerPage={PAGE_SIZE}
      getRankingInfo
      attributesToRetrieve={['*']}
      responseFields={['*']}
      aroundRadius={aroundRadius}
      {...AlgoliaProps}
    />
  );
};
