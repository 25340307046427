import { PlaceLocation, userLocationStoreKey } from './constants';
import { waitForIsClient } from '../../utils/waitForIsClient';

export const getUserLocationFromStorage = async (): Promise<PlaceLocation | null> =>
  waitForIsClient.then(() => {
    const location = localStorage.getItem(userLocationStoreKey);
    try {
      if (location && location !== 'undefined') {
        return JSON.parse(location);
      }
    } catch {
      return null;
    }
  });
