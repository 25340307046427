import { ReactNode } from 'react';

import StarRating from './StarRating';

type ReviewProps = {
  author: {
    thumbSrc: string;
    thumbSize: number;
    thumbShape: 'rounded-circle';
    name: string;
  };
  rating: number;
  date: string;
  likeCount?: number;
  likeActive?: boolean;
  likeClick?: () => void;
  children: ReactNode;
  light?: boolean;
};

export const Review = ({
  author,
  rating,
  date,
  likeCount,
  likeActive,
  likeClick,
  children,
  light,
  ...props
}: ReviewProps) => {
  const thumbShape = author.thumbShape ? author.thumbShape : 'rounded',
    thumbClass = `flex-shrink-0 bg-repeat-0 bg-position-center bg-size-cover ${thumbShape} me-3`,
    thumbSrc = author.thumbSrc,
    thumbSize = author.thumbSize + 'px',
    name = author.name;

  return (
    <div {...props}>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div className="d-flex pe-2">
          {thumbSrc && (
            <div
              className={thumbClass}
              style={{
                width: thumbSize,
                height: thumbSize,
                backgroundImage: 'url(' + thumbSrc + ')',
              }}
            ></div>
          )}
          <div>
            <h6 className={`fs-base mb-0${light ? ' text-light' : ''}`}>{name}</h6>
            <StarRating light={light ? 1 : 0} rating={rating} />
          </div>
        </div>
        {date && (
          <span
            className={`flex-shrink-0 fs-sm ${light ? 'text-light opacity-50' : 'text-secondary'}`}
          >
            {date}
          </span>
        )}
      </div>
      <div className={`pb-1 mb-3${light ? ' text-light opacity-70' : ''}`}>{children}</div>
      {likeCount ? (
        <div className="d-flex align-items-center">
          <button
            type="button"
            className={`btn-like${likeActive ? ' active' : ''}${light ? ' btn-light' : ''}${
              likeClick ? '' : ' pe-none'
            }`}
            onClick={likeClick}
          >
            <i className="fi-like"></i>
            <span>{likeCount ? `(${likeCount})` : '(0)'}</span>
          </button>
        </div>
      ) : null}
    </div>
  );
};
