import { intersection } from 'lodash';
import { geocodeByLatLng } from 'react-google-places-autocomplete';

import { autocompleteRequestConfig } from '@constants';

import { waitForGoogleMapsReady } from './constants';
import { fetchUserCoordinates } from './fetchUserCoordinates';

export const fetchCurrentLocation = async () =>
  waitForGoogleMapsReady
    .then(fetchUserCoordinates)
    .then(geocodeByLatLng)
    .then(
      (places) =>
        places.filter(
          (item) =>
            intersection(item.types, autocompleteRequestConfig.types).length ===
            autocompleteRequestConfig.types?.length
        )[0]
    );
