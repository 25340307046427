import Link from 'next/link';

import { ImageLoader } from './ImageLoader';

type IconBoxProps = { href: string; media?: string; title: string; align: string };

export const IconBox = ({ href, media, title, align }: IconBoxProps) => (
  <Link
    href={href}
    passHref
    className={`icon-box card card-body h-100 border-0 shadow-sm card-hover text-decoration-none text-${align}`}
  >
    <div className="mb-3 text-primary text-accent">
      {media && <ImageLoader placeholder={false} src={media} width={56} height={56} alt={title} />}
    </div>
    <h3 className="icon-box-title fst-italic font-serif mb-0 fw-bolder fs-base">{title}</h3>
  </Link>
);
