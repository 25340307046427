import { isClient } from '@constants';

const isGoogleMapsLoaded = () => isClient && window.google;
export const waitForGoogleMapsReady = new Promise((res) => {
  const timeoutFn = () => {
    if (isGoogleMapsLoaded()) {
      res(true);
    } else {
      setTimeout(timeoutFn, 100);
    }
  };

  timeoutFn();
});

export const userLocationStoreKey = 'user_location';

export type PlaceLocation = { place_id: string; formatted_address: string };
