import { Message } from '@twilio/conversations';
import { CSSProperties, useCallback, useEffect, useMemo } from 'react';
import { Stack } from 'react-bootstrap';
import { useSetState } from 'react-use';
import Lightbox from 'yet-another-react-lightbox';

import { StoreOwnerImage } from '@components';
import { useAuth } from '@hooks';

import { MessageBubble } from './MessageBubble';
import { ConversationEntity } from '../../../../fetchMyConversations';
import { useGalleryOpened } from '../../../hooks';

const incomingMessageStyles: CSSProperties = { left: 40, position: 'relative' };

type ConversationsMessagesProps = {
  messages: Message[];
  otherUser: ConversationEntity['otherUser'] | undefined;
  onLoad: () => void;
};

export const ConversationsMessages = ({
  messages,
  otherUser,
  onLoad,
}: ConversationsMessagesProps) => {
  const auth = useAuth();
  const [gallery, setGallery] = useSetState<{ images: string[]; index?: number }>({ images: [] });
  const { setIsOpened } = useGalleryOpened();
  const openGallery = useCallback(
    (images: string[], index: number) => setGallery({ images, index }),
    [setGallery]
  );

  useEffect(() => setIsOpened(!!gallery.images.length), [gallery.images.length, setIsOpened]);

  const renderedMessages = useMemo(
    () =>
      messages.map((message, idx) => {
        const prevMessageFromDifferentAuthor =
          messages[idx - 1]?.author && message.author !== messages[idx - 1].author;

        if (message.author === auth.user?.uid) {
          return (
            <MessageBubble
              key={message.sid}
              className={prevMessageFromDifferentAuthor ? 'mt-3' : ''}
              direction="outgoing"
              message={message}
              onImageClick={openGallery}
              onLoad={onLoad}
            />
          );
        }
        if (prevMessageFromDifferentAuthor) {
          return (
            <Stack
              key={message.sid}
              direction="horizontal"
              gap={2}
              className={`align-items-start mt-3`}
            >
              <StoreOwnerImage
                src={otherUser?.photoURL ?? null}
                size={32}
                rounded={1}
                noBorder={true}
                alt={otherUser?.firstName ?? 'storeowner image'}
              />
              <MessageBubble
                direction="incoming"
                message={message}
                onImageClick={openGallery}
                onLoad={onLoad}
              />
            </Stack>
          );
        }

        return (
          <MessageBubble
            key={message.sid}
            direction="incoming"
            message={message}
            onImageClick={openGallery}
            onLoad={onLoad}
            style={incomingMessageStyles}
          />
        );
      }),
    [auth.user?.uid, messages, openGallery, otherUser?.firstName, otherUser?.photoURL]
  );

  return (
    <div className="position-relative">
      <Stack direction="vertical" gap={1}>
        {renderedMessages}
      </Stack>
      <Lightbox
        open={!!gallery?.images.length}
        close={() => setGallery({ images: [], index: undefined })}
        index={gallery.index}
        slides={gallery.images.map((image) => ({ src: image }))}
        carousel={{
          finite: true,
          preload: 2,
          imageFit: 'contain',
        }}
      />
    </div>
  );
};
