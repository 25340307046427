import { useCallback } from 'react';

import { useAddPaymentMethod } from './useAddPaymentMethod';
import { AddCreditCardVM } from '../addCreditCardValidationSchema';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { createCard, isCreateCardError } from './createCard';

const ERROR_MESSAGE = `We are unable to process your credit card at this time. You card has not been charged. Our development team has been alerted to this issue, and we’ll fix it as quickly as possible. Thank you for your patience.`;

const getErrorMessage = (error: unknown) => {
  return (isCreateCardError(error) && error?.response?.data?.message) || ERROR_MESSAGE;
};

export const useAddCreditCard = () => {
  const addPaymentMethod = useAddPaymentMethod();
  const addCreditCard = useCallback(
    async (values: AddCreditCardVM) => {
      const result = await createCard({
        cvc: values.cvv,
        exp_month: values.expiryDate.slice(0, 2),
        exp_year: values.expiryDate.slice(-2),
        name: values.cardholderName,
        number: values.cardNumber.replace(/\s/gim, ''),
      });
      await addPaymentMethod.mutateAsync({ cardToken: result.data.id });
    },
    [addPaymentMethod]
  );

  return useMutation(addCreditCard, {
    onError: (error) => {
      toast.error(getErrorMessage(error), {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  });
};
