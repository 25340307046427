import { Col, Row } from 'react-bootstrap';

import { ImageLoader } from '@components/ImageLoader';

const featuredInItems = [
  {
    width: 171,
    height: 40,
    name: '100 Companies championing women',
    imageSrc: '/images/featured-in/companies-championing-women.png',
  },
  {
    width: 137,
    height: 26,
    name: 'Cosmopolitan',
    imageSrc: '/images/featured-in/cosmopolitan.svg',
  },
  {
    width: 148,
    height: 30,
    name: 'Good Housekeeping',
    imageSrc: '/images/featured-in/good-housekeeping.svg',
  },
  {
    width: 94,
    height: 24,
    name: 'Forbes',
    imageSrc: '/images/featured-in/forbes.svg',
  },
  {
    width: 142,
    height: 37,
    name: 'CBS Los Angeles',
    imageSrc: '/images/featured-in/cbs-los-angeles.svg',
  },
  {
    width: 125,
    height: 52.5,
    name: 'San Antonio Express-News',
    imageSrc: '/images/featured-in/san-antonio-express-news.svg',
  },
  {
    width: 86.5,
    height: 69,
    name: 'The Salt Lake Tribune',
    imageSrc: '/images/featured-in/the-salt-lake-tribune.png',
  },
];

export const FeaturedIn = () => {
  return (
    <Row className="justify-content-center gy-2 gx-xxl-4">
      {featuredInItems.map((item) => (
        <Col
          key={item.name}
          className="d-flex justify-content-center align-items-center"
          xs={6}
          md={4}
          lg={3}
          xl={'auto'}
        >
          <div
            className="position-relative"
            style={{
              width: item.width,
              height: item.height,
            }}
          >
            <ImageLoader
              src={item.imageSrc}
              fill
              style={{ objectFit: 'contain' }}
              alt={item.name}
            />
          </div>
        </Col>
      ))}
    </Row>
  );
};
