import { useRouter } from 'next/router';

import { EventName, trackEvent, useGetBaseEventPayload } from '@packages/event-tracking';
import { fbq } from '@packages/fbpixel';
import { ga4 } from '@packages/ga4';
import { categoryFilterSerializer } from '@screens/CategoryScreen/categoryFilterSerializer';

import { Header } from './Header';
import { StoreSearch } from './StoreSearch';

const useSearchStores = () => {
  const router = useRouter();

  const query = (router?.query.query ?? '') as string;
  const onSearch = (value: string) => {
    router.push(`/search/${categoryFilterSerializer.serialize({ query: [value] })}`);
  };

  return { query, onSearch };
};

export const HeaderWithSearch = ({ shouldTrackEvent = true }: { shouldTrackEvent?: boolean }) => {
  const { query, onSearch } = useSearchStores();
  const getBaseEventPayload = useGetBaseEventPayload();

  const handleSearch = async (searchTerm: string) => {
    const baseEventPayload = await getBaseEventPayload();

    if (shouldTrackEvent && baseEventPayload && searchTerm) {
      const payload = {
        'user behavior': {
          'search term': searchTerm,
        },
        ...baseEventPayload,
      };
      trackEvent(EventName.SearchStores, payload);
      fbq.searchEvent({ customProperties: payload, search_string: searchTerm });
      ga4.searchEvent({ customProperties: payload, search_string: searchTerm });
    }
    onSearch(searchTerm);
  };

  return <Header extra={<StoreSearch query={query} onSearch={handleSearch} />} />;
};
