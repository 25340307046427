import { useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';

import { UserType } from '@api/generated/graphql';
import { useCurrentScreen } from '@hooks';

import { useApplyDiscountCodeToInvoice } from './useApplyDiscountCodeToInvoice';
import {
  discountCodeErrorPubSub,
  discountCodeRemovePubSub,
  discountCodeSuccessPubSub,
} from '../useTrackInvoiceDetailsEvents';

type ErrorResponse = {
  response: {
    errors?: Array<{
      message?: string;
    }>;
  };
};
const isErrorResponse = (val: unknown): val is ErrorResponse =>
  !!val && typeof val === 'object' && 'response' in val;

type PromoCodeState = { value: string } & (
  | {
      type: 'applied' | 'pending';
    }
  | {
      type: 'invalid';
      errorMessage: string;
    }
);

export const ApplyPromoCodeInputGroup = ({
  invoiceId,
  appliedDiscountCode,
  disabled,
}: {
  disabled?: boolean;
  invoiceId?: number;
  appliedDiscountCode?: { value: string; id: number };
}) => {
  const applyPromoCode = useApplyDiscountCodeToInvoice();

  const [promoCodeState, setPromoCodeState] = useState<PromoCodeState>({
    type: appliedDiscountCode ? 'applied' : 'pending',
    value: appliedDiscountCode ? appliedDiscountCode.value : '',
  });

  const { screen: currentScreen } = useCurrentScreen();
  const removeAppliedCode = async () => {
    if (appliedDiscountCode && invoiceId) {
      await applyPromoCode.mutateAsync({
        orderId: invoiceId,
        discountCode: appliedDiscountCode.value,
        discountCodeId: appliedDiscountCode.id,
        appusertype: UserType.Buyer,
        removed: true,
      });
      discountCodeRemovePubSub.publish(appliedDiscountCode.value, currentScreen!);
      setPromoCodeState({ type: 'pending', value: '' });
    }
  };

  const handleChange = (newValue: string) => {
    setPromoCodeState({ type: 'pending', value: newValue.toUpperCase() });
  };

  const handleSubmit = async () => {
    if (invoiceId && promoCodeState.value.length) {
      try {
        await applyPromoCode.mutateAsync({
          discountCode: promoCodeState.value,
          orderId: invoiceId,
          appusertype: UserType.Buyer,
        } as any);
        discountCodeSuccessPubSub.publish(promoCodeState.value, currentScreen!);
        setPromoCodeState({ ...promoCodeState, type: 'applied' });
      } catch (error) {
        discountCodeErrorPubSub.publish(error as string, currentScreen!);

        const errMessage =
          (isErrorResponse(error) && error?.response?.errors?.[0]?.message) ||
          'This code is not valid.';
        setPromoCodeState({
          ...promoCodeState,
          type: 'invalid',
          errorMessage: errMessage,
        });
      }
    }
  };

  return (
    <div className="border-dark ms-0 my-2">
      <InputGroup>
        <Form.Control
          onChange={(e) => handleChange(e.target.value)}
          onKeyUp={(e) => (e.key === 'Enter' ? handleSubmit() : null)}
          value={promoCodeState.value}
          size="sm"
          isInvalid={promoCodeState.type === 'invalid'}
          placeholder="Discount Code"
          disabled={disabled || applyPromoCode.isLoading || promoCodeState.type === 'applied'}
        />
        <Button
          onClick={handleSubmit}
          variant="primary text-light"
          disabled={disabled || applyPromoCode.isLoading || promoCodeState.type === 'applied'}
        >
          Apply
        </Button>
      </InputGroup>
      {promoCodeState.type === 'invalid' ? (
        <p className="text-danger">{promoCodeState.errorMessage}</p>
      ) : null}
      {promoCodeState.type === 'applied' ? (
        <div className="my-2 d-flex gap-3">
          <p className="my-0 d-flex align-items-center text-success">
            <i className="fi-check-circle me-2" />
            Your code was successfully applied.
          </p>
          <Button onClick={removeAppliedCode} size="sm" variant="outline-primary">
            Remove
          </Button>
        </div>
      ) : null}
    </div>
  );
};
