import Link from 'next/link';
import { ReactNode, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { useHits } from 'react-instantsearch';
import { Grid, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useHeight } from '@components/ContactForm/GetInTouchForm/useHeight';
import { Store } from '@hooks';
import { useIsMediaBreakpoint } from '@hooks/useIsMediaBreakpoint';
import { adaptStoreToVM } from '@utils';

import { StoreCard } from './StoreCard';

type StoreCarouselProps = {
  carouselName?: string;
  title?: ReactNode;
  link?: {
    text: string;
    href: string;
  };
  isFrontPage?: boolean;
};

export const StoreCarousel = ({ carouselName, title, link, isFrontPage }: StoreCarouselProps) => {
  const isTablet = useIsMediaBreakpoint('md');
  const { hits: stores } = useHits<Store>();

  const storeCardRef = useRef<HTMLDivElement>(null);
  const height = useHeight(storeCardRef, [stores, isTablet]);

  return stores.length > 0 ? (
    <div className="my-3">
      {title && link?.href && (
        <Link href={link.href} passHref style={{ textDecoration: 'none' }}>
          <h2 className="text-dark mb-4 cursor-pointer text-center font-serif fw-normal">
            {title}
          </h2>
        </Link>
      )}
      <div
        className="position-relative pt-2 px-md-3 fancy-swiper"
        style={{ height: isTablet ? 'auto' : height * 2 + 35 }}
      >
        <Swiper
          modules={[Navigation, Grid]}
          grid={{
            rows: isTablet ? 1 : 2,
          }}
          spaceBetween={0}
          shortSwipes={true}
          navigation={{
            prevEl: `#prev-${carouselName}`,
            nextEl: `#next-${carouselName}`,
          }}
          breakpoints={{
            0: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 2,
              slidesPerGroup: 2,
            },
            1100: {
              slidesPerView: 3,
              slidesPerGroup: 3,
            },
          }}
          noSwiping={true}
          noSwipingClass="yarl__slide"
          className="h-100 px-1 px-md-0"
        >
          {stores.map(adaptStoreToVM).map((store, index) => (
            <SwiperSlide className="p-1 px-md-2 px-lg-4 h-100" key={index}>
              <StoreCard
                item={store}
                url={store.url}
                isFrontPage={isFrontPage}
                ref={index === 1 ? storeCardRef : null}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <Button id={`prev-${carouselName}`} variant="prev" className="mx-n2" />
        <Button id={`next-${carouselName}`} variant="next" className="me-n2" />
      </div>

      {link?.href && (
        <div className="d-flex justify-content-center mt-4">
          <Link href={link.href} passHref className="text-uppercase">
            {link.text}
          </Link>
        </div>
      )}
    </div>
  ) : null;
};
