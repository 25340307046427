import { EventName } from '@packages/event-tracking';

import { ConversationItem } from './ConversationItem';
import { ConversationsList } from './ConversationsList';
import { ConversationEntity } from '../../../fetchMyConversations';
import { MessagingAndInvoicingState } from '../../types';
import { InvoiceIcon } from '../InvoiceIcon';
import { invoiceStateByEntity, useInvoices } from '../MessagingAndInvoicingContent';

type MessagingAndInvoicingListProps = {
  toggleInvoicing: () => void;
  toggleMessagingDetails: (conversation: ConversationEntity) => void;
  messagingAndInvoicing: MessagingAndInvoicingState;
};
export const MessagingAndInvoicingList = ({
  toggleInvoicing,
  toggleMessagingDetails,
  messagingAndInvoicing,
}: MessagingAndInvoicingListProps) => {
  const invoices = useInvoices();

  const handleInvoiceIconClick = () => {
    PubSub.publish(EventName.InvoiceIconClick);
    toggleInvoicing();
  };

  return (
    <ConversationsList
      activeConversationId={
        messagingAndInvoicing.activeTab === 'messaging-details'
          ? messagingAndInvoicing.selectedConversation?.conversationId
          : undefined
      }
      onConversationClick={toggleMessagingDetails}
      prefix={
        invoices.data?.length ? (
          <ConversationItem
            tooltip="Orders"
            onClick={handleInvoiceIconClick}
            withNotificationBadge={
              invoices.data?.some(
                (invoice) => invoice.status && invoiceStateByEntity[invoice.status] === 'PENDING'
              ) ?? false
            }
            imageContainerSize={56}
            withBorder={false}
            activeNotificationBadgeColor="dark"
            color="warning"
            image={<InvoiceIcon width={27} height={27} fill="white" />}
          />
        ) : null
      }
    />
  );
};
