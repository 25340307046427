import { fetchUserQuery } from '@api';

export type UserRegisterState = 'fullyRegistered' | 'softRegistered' | 'new';

export const fetchUserRegisterState = async (token: string): Promise<UserRegisterState> => {
  const res = await fetchUserQuery(undefined, { Authorization: `Bearer ${token}` });
  if (res.me) {
    // isRegistrationComplete: null = mobile; true = wam fully registered; false = wam soft registered
    return res.me.isRegistrationComplete === false ? 'softRegistered' : 'fullyRegistered';
  }
  return 'new';
};
