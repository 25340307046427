import { Col, Container, Row } from 'react-bootstrap';

import { ImageLoader } from '@components';

const useHowItWorksItems = () => [
  {
    imageSrc: '/images/how-it-works/find.png',
    imageWidth: 120,
    imageHeight: 120,
    name: 'Find home-based businesses',
    description: `Home-based businesses you'll love are just next door. Discover them all right here.`,
  },
  {
    imageSrc: '/images/how-it-works/get-in-touch.png',
    imageWidth: 126,
    imageHeight: 120,
    name: 'Get In Touch',
    description: `Find something you can't live without? Use the contact button to get in touch with the seller directly.`,
  },
  {
    imageSrc: '/images/how-it-works/download-the-app.png',
    imageWidth: 96,
    imageHeight: 120,
    name: 'Download the app',
    description:
      'Get our app to enjoy the best of West Tenth. Track your communications with sellers and make purchases easily all at your fingertips.',
  },
  {
    imageSrc: '/images/how-it-works/pay-securely.png',
    imageWidth: 120,
    imageHeight: 120,
    name: 'Pay Securely',
    description:
      'It feels good to pay the talented women on our platform. It feels even better knowing your in-app payment is safe, secure, and simple with Stripe.',
  },
];

export const HowItWorks = () => {
  const items = useHowItWorksItems();
  return (
    <div className="py-5 my-2">
      <Container as="section" className="mt-n3 mt-lg-0">
        <h2 className="text-center mb-6 text-dark">
          It&apos;s easy. It&apos;s fun. It&apos;s feel-good.
        </h2>
        <Row>
          {items.map((item) => (
            <Col
              key={item.name}
              md={3}
              className="d-flex flex-md-column flex-row align-items-md-center align-items-start pb-2 pb-sm-3 px-sm-3 text-md-center text-start"
            >
              <div
                style={{ flexBasis: '48px', flexShrink: 0 }}
                className="align-self-center mb-3 me-3 me-md-0 position-relative d-flex justify-content-center mb-md-5"
              >
                <ImageLoader
                  width={item.imageWidth}
                  height={item.imageHeight}
                  src={item.imageSrc}
                  alt={item.name}
                />
              </div>
              <div>
                <h5 className="text-dark font-serif">{item.name}</h5>
                <p className="text-dark fs-sm mb-0">{item.description}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};
