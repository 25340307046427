export const waitForIsClient = new Promise((res) => {
  const timeoutFn = () => {
    if (typeof window !== 'undefined') {
      res(true);
    } else {
      setTimeout(timeoutFn, 100);
    }
  };

  timeoutFn();
});
