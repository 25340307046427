export const getCreditCardType = (creditCardNumber: string) => {
  const firstDigit = creditCardNumber.charAt(0);
  const firstTwoDigits = creditCardNumber.substring(0, 2);
  if (firstDigit === '4') {
    return 'visa';
  } else if (['34', '37'].includes(firstTwoDigits)) {
    return 'amex';
  } else if (['51', '52', '53', '54', '55'].includes(firstTwoDigits)) {
    return 'mastercard';
  } else if (['6011', '622', '64', '65'].includes(creditCardNumber.substring(0, 3))) {
    return 'discover';
  } else {
    return null;
  }
};

export const getCvvMask = (creditCardType: string | null) => {
  if (creditCardType === 'amex') {
    return '####';
  }
  return '###';
};

export const getCardNumberMask = (creditCardType: string | null) => {
  if (creditCardType === 'amex') {
    return '#### ###### #####';
  }

  return '#### #### #### ####';
};
