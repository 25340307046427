import { pick } from 'lodash/fp';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { usePreviousScreen } from '@hooks/usePreviousScreen';

import type { ScreenName } from './types';

export type BreadcrumbPayload = {
  'user behavior': {
    pageNumber?: number;
    'search term'?: string;
    categoryId?: number; // categoryId from browse,
    taxonomyId?: number; // taxonomyId from browse,
    'category name'?: string | null; // category.title from browse,
    'delivery method filters'?: string[]; // [array of applied delivery methods from browse or search],
    isFrom?: ScreenName | null; // referring source: HOME, BROWSE, SEARCH (Passes through to QuickShop Detail)
    distanceFilter?: string;
    categoryFilter?: string[];
    marketKey?: string;
  };
};

export const pickUserBehaviorFields = pick([
  'delivery method filters',
  'distanceFilter',
  'category name',
  'categoryId',
  'taxonomyId',
  'search term',
  'isFrom',
  'categoryFilter',
  'pageNumber',
]);

export const useBreadcrumbPayload = () => {
  const router = useRouter();
  const query = router?.query;

  const { previousScreen } = usePreviousScreen();

  return useMemo<BreadcrumbPayload>(
    () => ({
      'user behavior': {
        ...pickUserBehaviorFields(query),
        isFrom: (query.isFrom || previousScreen) as ScreenName | null | undefined,
      },
    }),
    [previousScreen, query]
  );
};
