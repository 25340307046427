import { Col, Row } from 'react-bootstrap';

export const About = () => {
  return (
    <Row className="my-0 my-md-4 py-4 py-md-5 py-lg-6">
      <Col xs={12} md={{ span: 10, offset: 1 }} xxl={{ span: 8, offset: 2 }}>
        {/* <h6 className="font-serif fst-italic">About thoughtful spaces</h6>
        <p className="text-dark">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Lobortis mattis aliquam faucibus purus in massa. Amet
          dictum sit amet justo donec enim. Pulvinar elementum integer enim neque volutpat ac
          tincidunt. Et pharetra pharetra massa massa ultricies mi. In metus vulputate eu
          scelerisque. Eleifend donec pretium vulputate sapien nec sagittis aliquam malesuada. Cras
          ornare arcu dui vivamus. Dictumst vestibulum rhoncus est pellentesque elit ullamcorper
          dignissim cras. Pulvinar pellentesque habitant morbi tristique senectus et. Rhoncus mattis
          rhoncus urna neque viverra justo nec ultrices. Suspendisse ultrices gravida dictum fusce
          ut.
        </p>
        <h6 className="fs-sm">Subheader</h6>
        <p className="text-dark">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Lobortis mattis aliquam faucibus purus in massa. Amet
          dictum sit amet justo donec enim. Pulvinar elementum integer enim neque volutpat ac
          tincidunt. Et pharetra pharetra massa massa ultricies mi. In metus vulputate eu
          scelerisque.
        </p>
        <h6 className="fs-sm">Subheader</h6>
        <p className="text-dark">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Lobortis mattis aliquam faucibus purus in massa. Amet
          dictum sit amet justo donec enim. Pulvinar elementum integer enim neque volutpat ac
          tincidunt. Et pharetra pharetra massa massa ultricies mi. In metus vulputate eu
          scelerisque.
        </p>
        <h6 className="fs-sm">Subheader</h6>
        <p className="text-dark">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Lobortis mattis aliquam faucibus purus in massa. Amet
          dictum sit amet justo donec enim. Pulvinar elementum integer enim neque volutpat ac
          tincidunt. Et pharetra pharetra massa massa ultricies mi. In metus vulputate eu
          scelerisque.
        </p> */}
      </Col>
    </Row>
  );
};
