import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';

import { ImageLoader } from '@components/ImageLoader';

export const StoreDetailsImageCarousel = ({
  images,
  size,
  objectFit = 'cover',
}: {
  objectFit?: 'cover' | 'contain';
  size: { width: number; height: number };
  images: Array<{ name: string; uri: string }>;
}) => {
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  return (
    <div className="position-relative">
      <span
        style={{ zIndex: 10, bottom: 10, right: 10 }}
        className="bg-black bg-opacity-25 rounded-1 px-1 py-0 text-white d-flex align-items-center position-absolute"
      >
        <i className="me-2 fi-image" />
        {activeImageIndex + 1}/{images.length}
      </span>
      <Swiper
        onSlideChange={(swiper: SwiperClass) => setActiveImageIndex(swiper.activeIndex)}
        modules={[Navigation]}
        autoHeight
        spaceBetween={16}
        slidesPerView={1}
        navigation={{
          prevEl: '#prev',
          nextEl: '#next',
        }}
      >
        {images?.map((item) => (
          <SwiperSlide key={item.name}>
            <ImageLoader
              width={size.width}
              height={size.height}
              className="rounded-3"
              style={{ objectFit, objectPosition: 'center' }}
              src={item.uri}
              alt={item.name}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <Button id="prev" variant="prev" className="ms-2" />
      <Button id="next" variant="next" className="me-2" />
    </div>
  );
};
