import { CSSProperties, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useAuth } from '@hooks';

type ForgotPasswordVM = { email: string };

export const ForgotPasswordForm = ({
  onToggle,
  style,
}: {
  onToggle?: () => void;
  style?: CSSProperties;
}) => {
  const [loading, setLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const { sendPasswordResetEmail } = useAuth();

  const methods = useForm<ForgotPasswordVM>({});
  const [error, setError] = useState('');

  const forgotPassword: SubmitHandler<ForgotPasswordVM> = async (values) => {
    try {
      setLoading(true);
      await sendPasswordResetEmail(values.email);
      methods.reset();
      setError('');
      setLoading(false);
      setIsSent(true);
    } catch {
      setLoading(false);
      setError('Cannot send an email with a link to reset password.');
    }
  };

  return (
    <>
      {isSent ? (
        <>
          <h4 className="mb-3 text-center">Reset link has been sent!</h4>
          <p className="fs-sm text-center">Please check your inbox to reset your password.</p>
          <Button
            type="submit"
            size="lg"
            variant={`primary rounded-pill w-100 d-flex justify-content-center align-items-center gap-2`}
            onClick={onToggle}
          >
            Back to Login
          </Button>
        </>
      ) : (
        <Form noValidate onSubmit={methods.handleSubmit(forgotPassword)}>
          <h4 className="mb-3 text-center">Reset Password</h4>
          <p className="fs-sm text-center">
            No worries! It happens to all of us. Enter your email address below to receive a link to
            reset your password.
          </p>
          <div className="mx-auto" style={style}>
            <Form.Group controlId="si-email" className="mb-3">
              <Form.Control
                size="lg"
                type="email"
                placeholder="Email"
                required
                {...methods.register('email')}
              />
            </Form.Group>
            {error && <span className="mb-3 mt-0 invalid-feedback d-block">{error}</span>}
            <Button
              type="submit"
              size="lg"
              disabled={loading}
              variant={`primary rounded-pill w-100 d-flex justify-content-center align-items-center gap-2`}
            >
              {loading && <Spinner animation="border" role="status" size="sm" />}
              Send Reset Link
            </Button>
            {onToggle ? (
              <div className="d-flex justify-content-center align-items-center mt-3">
                Have a West Tenth account?
                <Button variant="link text-brownsugar" className="px-2" onClick={onToggle}>
                  Sign in here
                  <i className="fi-arrow-long-right ms-2" />
                </Button>
              </div>
            ) : null}
          </div>
        </Form>
      )}
    </>
  );
};
