import { addDays, formatDate } from '@utils';

export const NO_TIMEFRAME = 'no timeframe specified';
export const CUSTOM_TIMEFRAME = 'custom';

export const availableTimeframeOptions = [
  {
    label: 'In a week',
    value: formatDate(addDays(7)),
  },
  {
    label: 'In two weeks',
    value: formatDate(addDays(14)),
  },
  {
    label: 'In a month',
    value: formatDate(addDays(30)),
  },
  {
    label: "I don't have one",
    value: NO_TIMEFRAME,
  },
];
