import { Conversation, Message } from '@twilio/conversations';
import { useCallback, useEffect, useState } from 'react';

import { useScrollToBottom } from './useScrollToBottom';
import { ConversationDetailsState } from '../types';

const conversationInitialState: ConversationDetailsState = {
  messages: [],
  loadingState: 'initializing',
};

export const useMessages = (conversationProxy?: Conversation, selectedConversationId?: number) => {
  const [ref, scrollToBottom] = useScrollToBottom();
  const [state, setState] = useState(conversationInitialState);

  useEffect(() => {
    setState({ loadingState: 'loading messages', messages: [] });
  }, [selectedConversationId]);

  const messageAdded = (message: Message, targetConversation: Conversation) => {
    if (targetConversation === conversationProxy) {
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, message],
      }));
      scrollToBottom('smooth');
    }
  };

  const loadMessagesFor = useCallback(
    async (thisConversation: Conversation) => {
      if (conversationProxy === thisConversation) {
        return thisConversation
          .getMessages(100)
          .then((messagePaginator) => {
            if (conversationProxy === thisConversation) {
              return {
                messages: messagePaginator.items,
                loadingState: 'ready',
              } as ConversationDetailsState;
            } else {
              return { loadingState: 'failed', messages: [] } as ConversationDetailsState;
            }
          })
          .catch((err) => {
            console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
            return { loadingState: 'failed', messages: [] } as ConversationDetailsState;
          });
      }
    },
    [conversationProxy]
  );

  const handleConversationChange = async (targetConversation: Conversation) => {
    if (targetConversation === conversationProxy) {
      const res = await loadMessagesFor(conversationProxy);
      if (res && targetConversation === conversationProxy) {
        setState(res);
        scrollToBottom('auto');
      }
    }
  };

  useEffect(() => {
    if (conversationProxy) {
      handleConversationChange(conversationProxy);

      const handler = (m: Message) => messageAdded(m, conversationProxy);
      conversationProxy.on('messageAdded', handler);
      return () => {
        conversationProxy.off('messageAdded', handler);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationProxy?.sid]);

  return [state, ref, scrollToBottom] as const;
};
