import PubSub from 'pubsub-js';
import { useEffect } from 'react';

import { useCurrentScreen } from '@hooks/useCurrentScreen';
import { ScreenName, trackMixpanelEvent } from '@packages/event-tracking';

import { DiscountCodeRemoveEventPayload, InvoiceEventName } from './types';

export const discountCodeRemovePubSub = {
  publish: (promoCode: string, screen: ScreenName) => {
    const payload: DiscountCodeRemoveEventPayload = {
      action: 'remove',
      component: 'Discount Code',
      element: 'button',
      eventType: 'Discount Code Event',
      isWeb: true,
      module: 'Invoice Detail',
      screen,
      promoCode,
    };
    PubSub.publish(InvoiceEventName.DiscountCodeRemove, payload);
  },
  subscribe: (fn: (data: DiscountCodeRemoveEventPayload) => void) =>
    PubSub.subscribe(InvoiceEventName.DiscountCodeRemove, (_, payload) => fn(payload)),
};

export const useTrackDiscountCodeRemove = () => {
  const { screen: currentScreen } = useCurrentScreen();
  useEffect(() => {
    const discountCodeRemoveUnsubscribeToken = discountCodeRemovePubSub.subscribe((data) => {
      trackMixpanelEvent('Discount Code Event', data);
    });

    return () => {
      PubSub.unsubscribe(discountCodeRemoveUnsubscribeToken);
    };
  }, [currentScreen]);
};
