import { zodResolver } from '@hookform/resolvers/zod';
import { CSSProperties, ReactNode } from 'react';
import { Button, Form, Spinner, Stack } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

import { UserSchema } from '@components/ContactForm/constants';
import { createPasswordSchema } from '@components/ContactForm/CreatePasswordForm';
import { UserInfoFormGroup } from '@components/ContactForm/UserInfoFormGroup';
import { InputField } from '@components/InputField';

import { useSignUp } from './useSignUp';

const signUpValidationSchema = z.intersection(
  createPasswordSchema,
  z.object({
    user: UserSchema,
  })
);

type SignUpVM = z.infer<typeof signUpValidationSchema>;

export const SignUpForm = ({
  onSuccess,
  onToggle,
  title,
  subTitle,
  style,
}: {
  onSuccess: () => void;
  onToggle: () => void;
  title?: ReactNode;
  subTitle?: string;
  style?: CSSProperties;
}) => {
  const methods = useForm<SignUpVM>({
    resolver: zodResolver(signUpValidationSchema),
    shouldFocusError: true,
  });
  const signUp = useSignUp(onSuccess);

  return (
    <FormProvider {...methods}>
      <Form
        noValidate
        onSubmit={methods.handleSubmit((vals) =>
          signUp.mutateAsync({ ...vals.user, password: vals.password })
        )}
      >
        {title && <h4 className="mb-3 text-center text-dark">{title}</h4>}
        {subTitle && <p className="fs-sm text-center">{subTitle}</p>}
        <Stack gap={3} className="mx-auto" style={style}>
          <UserInfoFormGroup noFieldDescription />
          <InputField type="password" name="password" label="Create a Password" />
          <InputField type="password" name="confirmPassword" label="Confirm Password" />
          <Button
            type="submit"
            size="lg"
            disabled={signUp.isLoading}
            variant={`primary text-light rounded-pill w-100 d-flex justify-content-center align-items-center gap-2`}
          >
            {signUp.isLoading && <Spinner animation="border" role="status" size="sm" />}
            Create Account
          </Button>
          <div className="d-flex justify-content-center align-items-center">
            Have a West Tenth account?
            <Button variant="link text-brownsugar" className="px-2" onClick={onToggle}>
              Sign in here
              <i className="fi-arrow-long-right ms-2" />
            </Button>
          </div>
        </Stack>
      </Form>
    </FormProvider>
  );
};
