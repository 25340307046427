import type { LatLng } from 'react-google-places-autocomplete/build/GooglePlacesAutocomplete.types';
import { create } from 'zustand';

import { isClient } from '@constants';

import { getCoordinatesByPlaceId } from './useCoordinatesByPlaceId';
import { useUserLocation, waitForGoogleMapsReady } from './useUserLocation';

const getCoordsFromStorage = () => {
  if (isClient) {
    const coords = localStorage.getItem('coords');
    return coords && coords !== 'undefined' ? (JSON.parse(coords) as LatLng) : undefined;
  }
};

const coordsByPlaceId: Record<string, LatLng | undefined> = {};
export const useUserCoordinates = create<{
  coords?: LatLng;
  setCoords: (newCoords?: LatLng, placeId?: string) => void;
}>((set) => ({
  coords: getCoordsFromStorage(),
  setCoords: (coords, placeId?: string) => {
    if (placeId) {
      coordsByPlaceId[placeId] = coords;
    }
    set({ coords });
  },
}));

useUserCoordinates.subscribe(({ coords }) => {
  if (coords) {
    localStorage.setItem('coords', JSON.stringify(coords));
  } else {
    localStorage.removeItem('coords');
  }
});

useUserLocation.subscribe(async ({ location }) => {
  await waitForGoogleMapsReady;
  const { setCoords } = useUserCoordinates.getState();
  if (location?.place_id) {
    const newCoords =
      coordsByPlaceId[location.place_id] || (await getCoordinatesByPlaceId(location.place_id));
    setCoords(newCoords, location.place_id);
  } else {
    setCoords();
  }
});
