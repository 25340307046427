import { useMutation, useQueryClient } from 'react-query';

import { useApiClient } from '@api/apiClient';
import { useCurrentScreen } from '@hooks';

import { addCreditCardPubSub } from '../../../useTrackInvoiceDetailsEvents';

export const useAddPaymentMethod = () => {
  const queryClient = useQueryClient();
  const { screen } = useCurrentScreen();
  const apiClient = useApiClient();
  return useMutation(
    ({ cardToken }: { cardToken: string }) =>
      apiClient.CreatePaymentMethod({
        cardToken,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(['invoices']);
        queryClient.refetchQueries(['creditCard']);
        addCreditCardPubSub.publish(screen!);
      },
    }
  );
};
