import { Pagination as BsPagination } from 'react-bootstrap';

import { DOTS, usePagination } from './usePagination';

type PaginationProps = {
  total: number;
  currentPage: number;
  pageSize: number;
  onPageChange: (page: number) => void;
  isFirstPage: boolean;
  isLastPage: boolean;
};

export const Pagination = ({
  total,
  currentPage,
  onPageChange,
  pageSize,
  isFirstPage,
  isLastPage,
}: PaginationProps) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount: total,
    siblingCount: 1,
    pageSize,
  });

  return (
    <BsPagination className="mb-1">
      <BsPagination.Item onClick={() => onPageChange(currentPage - 1)} disabled={isFirstPage}>
        Prev
      </BsPagination.Item>
      {paginationRange.map((pageNumber, idx) =>
        pageNumber === DOTS ? (
          <BsPagination.Ellipsis key={pageNumber + idx} />
        ) : (
          <BsPagination.Item
            key={pageNumber}
            onClick={() => onPageChange(pageNumber)}
            active={pageNumber === currentPage}
          >
            {pageNumber}
          </BsPagination.Item>
        )
      )}
      <BsPagination.Item onClick={() => onPageChange(currentPage + 1)} disabled={isLastPage}>
        Next
      </BsPagination.Item>
    </BsPagination>
  );
};
