import { Router } from 'next/router';
import NProgress from 'nprogress';

export const useShowSpinnerOnRouteChange = () => {
  // Bind NProgress to Next Router events (Page loading animation)
  Router.events.on('routeChangeStart', () => {
    NProgress.start();
  });

  Router.events.on('routeChangeComplete', () => NProgress.done());
  Router.events.on('routeChangeError', () => NProgress.done());
};
