import { ReactNode } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import { isSuperCategory, useSuperCategories } from '@api';
import { ImageLoader } from '@components';

export const ExploreTalents = ({
  itemsToDisplay,
  title,
}: {
  itemsToDisplay?: number;
  title?: ReactNode;
}) => {
  const { data: talents } = useSuperCategories();

  return (
    <div className="py-3">
      {title ? <h2 className="mb-5 text-dark">{title}</h2> : null}
      <Row>
        {talents?.categoryTaxonomy
          .filter(isSuperCategory)
          .slice(0, itemsToDisplay)
          .map((talent, idx) => (
            <Col xs={6} md={4} key={idx} className="p-2 p-md-2">
              <a href={`/browse/${talent.uriSlug}`} className="text-decoration-none">
                <Card className="rounded rounded-lg-0 shadow-sm border-0 h-100 overflow-hidden">
                  <div className="justify-content-center d-block">
                    {talent.image && (
                      <ImageLoader
                        src={talent.image}
                        width={415}
                        height={230}
                        alt={talent.title}
                        loading="lazy"
                      />
                    )}
                  </div>
                  <Card.Body className="p-3 text-center">
                    <h6 className="m-0 font-serif fw-bolder text-dark d-flex justify-content-center align-items-center">
                      {talent.icon && (
                        <span className="d-none d-md-inline text-accent me-3">
                          <ImageLoader
                            src={talent.icon}
                            width={24}
                            height={24}
                            alt={talent.title}
                          />
                        </span>
                      )}
                      {talent.title}
                    </h6>
                  </Card.Body>
                </Card>
              </a>
            </Col>
          ))}
      </Row>
    </div>
  );
};
