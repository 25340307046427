import Link from 'next/link';
import { Nav } from 'react-bootstrap';

import { isSuperCategory, useSuperCategories } from '@api';

import { ImageLoader } from '../ImageLoader';

export const CategoriesTopMenu = () => {
  const { data: superCategories } = useSuperCategories();
  const numSlicedLastCategories = -2;

  return (
    <Nav fill={true} className="bg-primary px-3 py-1 flex-nowrap">
      <Nav.Item>
        <Nav.Link href="/search" style={{ textDecoration: 'none' }} className="px-1">
          <div className="text-light cursor-pointer text-nowrap fs-sm">All</div>
        </Nav.Link>
      </Nav.Item>

      {superCategories?.categoryTaxonomy
        ?.slice(0, numSlicedLastCategories)
        .map((category, index, list) =>
          isSuperCategory(category) && category.categoryChildren?.length ? (
            <Nav.Item key={index} className="main-menu">
              <div className="sub-image">
                <ImageLoader
                  priority
                  src="/images/top-menu-image.png"
                  width={200}
                  height={154}
                  alt="top menu"
                />
              </div>
              <div className="d-inline-block position-relative px-1">
                <Nav.Link
                  href={`/browse/${category.uriSlug}`}
                  style={{ textDecoration: 'none' }}
                  className="px-0"
                >
                  <div className="text-light cursor-pointer text-nowrap fs-sm">
                    {category.title}
                  </div>
                </Nav.Link>
                <div className={`sub-menu${index + 1 === list.length ? ' last' : ''}`}>
                  <div className="text-light sub-menu-text pt-2 pb-4">
                    <div className="text-uppercase fs-sm mb-2">Categories</div>
                    {category.categoryChildren.map((subCategory, subIndex) => (
                      <Link
                        key={subIndex}
                        href={{
                          pathname: `/browse/category/${subCategory.uriSlug}`,
                          query: { parentCategory: category.taxonomyId },
                        }}
                        style={{ textDecoration: 'none' }}
                        className="text-light fs-sm d-block mb-1"
                      >
                        {subCategory.title}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </Nav.Item>
          ) : (
            <Nav.Item key={index}>
              <Nav.Link
                href={`/browse/category/${category.uriSlug}`}
                style={{ textDecoration: 'none' }}
                className="px-1"
              >
                <div className="text-light cursor-pointer text-nowrap fs-sm">{category.title}</div>
              </Nav.Link>
            </Nav.Item>
          )
        )}
    </Nav>
  );
};
