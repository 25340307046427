import { useQuery } from 'react-query';

import { useApiClient } from '@api/apiClient/getApiClient';
import { InvoiceDetailsQuery, UserType } from '@api/generated/graphql';
import { useAuth } from '@hooks';

export type InvoiceDetailsVM = NonNullable<NonNullable<InvoiceDetailsQuery['orderV3']>['data']>[0];
export const useInvoiceDetails = (input: { id?: number; invoiceHashId?: string }) => {
  const auth = useAuth();
  const apiClient = useApiClient();

  return useQuery(
    ['invoiceDetails', input, apiClient, auth.user?.uid],
    async () =>
      apiClient.InvoiceDetails({
        ...input,
        appusertype: UserType.Buyer,
      } as any),
    {
      select: (res) => res.orderV3?.data?.[0] as InvoiceDetailsVM,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: Boolean(auth.user && (input.id || input.invoiceHashId)),
      retry: false,
    }
  );
};
