import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { useApiClient } from '@api/apiClient';
import { UserType } from '@api/generated/graphql';
import { useCurrentScreen } from '@hooks/useCurrentScreen';

import { rejectInvoicePubSub } from '../../../useTrackInvoiceDetailsEvents';

export const useRejectInvoice = () => {
  const queryClient = useQueryClient();
  const { screen } = useCurrentScreen();
  const apiClient = useApiClient();
  return useMutation(
    ({ invoiceId }: { invoiceId: number }) =>
      apiClient.CancelOrder({ orderId: invoiceId, appusertype: UserType.Buyer }),
    {
      onSuccess: async () => {
        await queryClient.refetchQueries(['invoices']);
        await queryClient.refetchQueries(['invoiceDetails']);
        if (screen) {
          rejectInvoicePubSub.publish(screen);
        }
        toast.success('Successfully rejected order');
      },
    }
  );
};
