import { useContext } from 'react';
import { Stack } from 'react-bootstrap';

import { ConversationEntity } from '@packages/messaging-and-invoicing/fetchMyConversations';

import { ConversationDetails } from './ConversationDetails';
import { ConversationList } from './ConversationList';
import { InvoiceList } from './InvoicingContent';
import { useIsFullscreenMessagingAndInvoicing } from './useIsFullscreenMessagingAndInvoicing';
import { MessengerContext } from '../../MessengerContext';
import { MessagingAndInvoicingState } from '../../types';

type MessagingAndInvoicingContentProps = {
  onClose: () => void;
  messagingAndInvoicing: MessagingAndInvoicingState;
  toggleMessagingDetails: (conversation: ConversationEntity) => void;
};

export const MessagingAndInvoicingContent = ({
  onClose,
  messagingAndInvoicing,
  toggleMessagingDetails,
}: MessagingAndInvoicingContentProps) => {
  const { conversationsClient } = useContext(MessengerContext);

  const isFullScreen = useIsFullscreenMessagingAndInvoicing();
  return (
    <Stack
      direction="vertical"
      className={`bg-white position-${
        isFullScreen ? 'fixed' : 'absolute rounded-top'
      } shadow overflow-hidden`}
      style={{
        ...(isFullScreen
          ? {
              top: 0,
              left: 0,
              right: 0,
            }
          : {
              left: 'calc(100% + 6px)',
              height:
                messagingAndInvoicing.activeTab === 'messaging-details'
                  ? 'calc(80vh - 57px)'
                  : 'calc(100vh - 57px)',
              width: 400,
            }),
        bottom: 0,
        maxWidth: '100vw',
        zIndex: 100,
        transform: `translateY(${
          messagingAndInvoicing.visibilityState === 'expanded' ? '0' : '100vh'
        })`,
        transition: 'transform .3s cubic-bezier(.42,0,.58,1)',
      }}
    >
      {messagingAndInvoicing.activeTab === 'messaging-details' ? (
        <ConversationDetails
          conversationsClient={conversationsClient}
          onClose={onClose}
          messagingState={messagingAndInvoicing}
        />
      ) : null}
      {messagingAndInvoicing.activeTab === 'messaging' ? (
        <ConversationList onClose={onClose} onClick={toggleMessagingDetails} />
      ) : null}
      {messagingAndInvoicing.activeTab === 'invoicing' ? <InvoiceList onClose={onClose} /> : null}
    </Stack>
  );
};
