import { Accordion } from 'react-bootstrap';

export const PaymentMethodsSection = () => (
  <Accordion className="my-4">
    <Accordion.Item eventKey="0">
      <Accordion.Header>Payment Methods</Accordion.Header>
      <Accordion.Body>
        <p>
          Pay with confidence knowing that all transactions are handled safely and securely on the
          West Tenth app through Stripe payment processing.
        </p>
        <p>
          Once you receive an in-app order from the business, you can pay with any credit or debit
          card.
        </p>
        <p className="mb-0">
          Your payment method will be saved for future purchases in your account. You can edit or
          delete your payment methods under Payment Information in your Profile Settings on the app.
        </p>
      </Accordion.Body>
    </Accordion.Item>
  </Accordion>
);
