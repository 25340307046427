import {
  ForwardedRef,
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import { OffcanvasPlacement } from 'react-bootstrap/esm/Offcanvas';

import { useIsLaptopOrDesktop } from '@hooks/useIsLaptopOrDesktop';

export const ExpandOnMobileInner = (
  {
    title,
    onClose,
    children,
    placement = 'start',
    className,
  }: {
    className?: string;
    title: ReactNode;
    onClose?: () => void;
    children: ReactNode | ((onClose: () => void) => ReactNode);
    placement?: OffcanvasPlacement;
  },
  offcanvasContainer: ForwardedRef<unknown>
) => {
  const isDesktop = useIsLaptopOrDesktop();

  const [show, setShow] = useState(false);
  const handleClose = useCallback(() => {
    setShow(false);
    onClose?.();
  }, [onClose]);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (isDesktop && show) {
      handleClose();
    }
  }, [handleClose, isDesktop, show]);

  const isVertical = placement === 'bottom' || placement === 'top';

  const renderedChildren = useMemo(
    () => (typeof children === 'function' ? children(handleClose) : children),
    [children, handleClose]
  );

  const offcanvasProps = useMemo(
    () =>
      isDesktop
        ? {
            container: offcanvasContainer,
          }
        : { renderStaticNode: true },
    [isDesktop, offcanvasContainer]
  );

  return (
    <>
      <Button
        size="lg"
        variant="primary"
        className="w-100 rounded-0 fixed-bottom d-lg-none text-light text-truncate"
        onClick={handleShow}
      >
        {title}
      </Button>
      <Offcanvas
        {...offcanvasProps}
        placement={isDesktop ? 'top' : placement}
        show={isDesktop ? true : show}
        onHide={handleClose}
        backdrop={isDesktop ? false : true}
        scroll={true}
        autoFocus={isDesktop ? false : undefined}
        className={`${className} offcanvas-expand-lg${isVertical ? ' min-h-100' : ''}${
          !isDesktop && isVertical ? ' pb-md-0' : ''
        }`}
      >
        <Offcanvas.Header closeButton className="border-bottom pt-lg-3 px-lg-0 bg-white">
          <Offcanvas.Title as="h5" className="font-title display-3 overflow-hidden">
            {title}
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body className="p-0">{renderedChildren}</Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export const ExpandOnMobile = forwardRef(ExpandOnMobileInner);
