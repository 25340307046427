const getSlug = (name: string) => name.split(' ').map(encodeURIComponent).join('+');
const parseSlug = (slug: string) => slug.split('+').map(decodeURIComponent).join(' ');

type Serializer<T> = {
  serialize: (val: T) => string;
  deserialize: (str: string) => T;
};

type CategoryFilter = {
  query?: string[];
  page?: string[];
  fulfillments?: string[];
  categories?: string[];
  distance?: string[];
};

const valueDivider = ',';
const filterItemDivider = ';';
const nameValueDivider = '=';

export const categoryFilterSerializer: Serializer<CategoryFilter> = {
  serialize: (filter) =>
    Object.entries(filter)
      .filter(([_, values]) => values?.length)
      .map(
        ([itemName, values]) =>
          `${itemName}${nameValueDivider}${values.map(getSlug).join(valueDivider)}`
      )
      .join(filterItemDivider),

  deserialize: (query) =>
    query.split(filterItemDivider).reduce((prev, curr) => {
      const [itemName, values] = curr.split(nameValueDivider);
      if (values) {
        prev[itemName as keyof CategoryFilter] = values.split(valueDivider).map(parseSlug);
      }
      return prev;
    }, {} as CategoryFilter),
};
