import { useEffect } from 'react';

import { useCurrentScreen } from '@hooks/useCurrentScreen';
import { ScreenName, trackMixpanelEvent } from '@packages/event-tracking';

import { DiscountCodeErrorEventPayload, InvoiceEventName } from './types';

export const discountCodeErrorPubSub = {
  publish: (error: string, screen: ScreenName) => {
    const payload: DiscountCodeErrorEventPayload = {
      action: 'error',
      component: 'Discount Code',
      element: 'button',
      eventType: 'Discount Code Event',
      isWeb: true,
      module: 'Invoice Detail',
      screen,
      error,
    };
    PubSub.publish(InvoiceEventName.DiscountCodeSuccess, payload);
  },
  subscribe: (fn: (data: DiscountCodeErrorEventPayload) => void) =>
    PubSub.subscribe(InvoiceEventName.DiscountCodeError, (_, payload) => fn(payload)),
};

export const useTrackDiscountCodeError = () => {
  const { screen: currentScreen } = useCurrentScreen();
  useEffect(() => {
    const discountCodeErrorUnsubscribeToken = discountCodeErrorPubSub.subscribe((data) => {
      trackMixpanelEvent('Discount Code Event', data);
    });

    return () => {
      PubSub.unsubscribe(discountCodeErrorUnsubscribeToken);
    };
  }, [currentScreen]);
};
