import { identity } from 'lodash/fp';
import { useQuery } from 'react-query';

import { makeGqlCall } from './makeGqlCall';

const categoryTaxonomyQuery = /* GraphQL */ `
  {
    categoryTaxonomy {
      taxonomyId
      title
      family
      level
      index
      uriSlug
      metaTags
      description
      longDescription
      icon
      image
      categoryId
      categoryChildren {
        id
        icon
        title
        uriSlug
        meta
      }
      meta
    }
  }
`;

export type CategoryMeta = {
  metaDescription?: string;
  metaTags?: string;
  pageTitle?: string;
  shortDescription?: string;
  titleTag?: string;
};

export type SuperCategoryChildren = {
  id: number;
  icon?: string;
  title: string;
  uriSlug: string;
  meta?: CategoryMeta;
};

export type CategoryEntity = {
  family: 'category';
  categoryId: number;
  title: string;
  uriSlug: string;
  meta?: CategoryMeta;
};

export type SuperCategoryEntity = {
  taxonomyId: number;
  title: string;
  family: 'supercategory';
  level: number;
  index: number;
  uriSlug: string;
  metaTags: string;
  description: string;
  longDescription: string;
  icon?: string;
  image?: string;
  categoryId?: number;
  categoryChildren?: Array<SuperCategoryChildren>;
  meta?: CategoryMeta;
};

export const isCategory = (cat: CategoryVariant): cat is CategoryEntity =>
  cat.family === 'category';
export const isSuperCategory = (cat: CategoryVariant): cat is SuperCategoryEntity =>
  cat.family === 'supercategory';

export type CategoryVariant = SuperCategoryEntity | CategoryEntity;

export type FetchSuperCategoriesResponse = {
  categoryTaxonomy: Array<CategoryVariant>;
};
export const fetchSuperCategories =
  makeGqlCall<FetchSuperCategoriesResponse>(categoryTaxonomyQuery);

let cachedSuperCategories = fetchSuperCategories();

export const getSuperCategories = async <T = FetchSuperCategoriesResponse>(
  select: (res: FetchSuperCategoriesResponse) => T = identity
) => {
  if (!select(await cachedSuperCategories)) {
    cachedSuperCategories = fetchSuperCategories();
  }
  return select(await cachedSuperCategories);
};

export const useSuperCategories = () =>
  useQuery(['superCategories'], () => fetchSuperCategories(), {
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
