import Link, { LinkProps } from 'next/link';
import React from 'react';
import { Badge, Button } from 'react-bootstrap';
import Lightbox from 'yet-another-react-lightbox';
import Inline from 'yet-another-react-lightbox/plugins/inline';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';

import { useCurrentScreen } from '@hooks';
import { useIsMediaBreakpoint } from '@hooks/useIsMediaBreakpoint';
import { EventName, ImageCarouselScrollVarant, trackEvent } from '@packages/event-tracking';
import { badgeByVisualTag, calculatePreload, StoreVM } from '@utils';

import SlideImageAsNextJsImage from './SlideImageAsNextJsImage';
import { FulfillmentOptions } from '../FulfillmentOptions';
import { ImageLoader } from '../ImageLoader';
import { Price } from '../Price';

export type StoreCardProps = {
  item: StoreVM;
  direction?: 'horizontal' | 'vertical';
  url: LinkProps['href'];
  variant?: 'short';
  isFrontPage?: boolean;
};

const StoreCardInner = (
  { item, direction, url, variant, isFrontPage }: StoreCardProps,
  ref: React.ForwardedRef<HTMLDivElement> | null
) => {
  const classes = direction === 'horizontal' ? 'flex-row' : 'flex-column';
  const photos = (item.photos.main ? [item.photos.main] : []).concat(item.photos.others ?? []);
  const { screen } = useCurrentScreen();
  const preload: number = calculatePreload(photos.length);

  const isTablet = useIsMediaBreakpoint('md');

  return (
    <>
      <div
        className={`h-100 d-flex rounded-3 overflow-hidden text-decoration-none text-dark ${classes} ${
          isFrontPage ? '' : 'shadow-lg'
        }`}
        style={{ position: 'relative' }}
        ref={ref || null}
      >
        <div className="position-relative">
          <div className="fs-0 d-block">
            <Lightbox
              slides={photos.map((p) => ({
                src: p.uri,
                storefrontUri: typeof url === 'string' ? url : url.pathname,
              }))}
              on={{
                view: ({ index }) => {
                  index > 0 &&
                    trackEvent(EventName.ImageScroll, {
                      isWeb: true,
                      index,
                      storefrontId: item.id,
                      storefrontName: item.name,
                      screen,
                      type: ImageCarouselScrollVarant.Carousel,
                    });
                },
              }}
              inline={{
                style: {
                  aspectRatio: isFrontPage ? '1.4' : '1.46',
                },
              }}
              plugins={[Inline, Thumbnails]}
              carousel={{
                preload,
                padding: 0,
                imageFit: 'cover',
                finite: false,
              }}
              thumbnails={{
                vignette: false,
                height: 0.1,
                width: 0.1,
                border: 0,
                gap: 3,
                padding: 2,
                borderRadius: 50,
                position: 'bottom',
              }}
              render={{
                buttonPrev: () => null,
                buttonNext: () => null,
                thumbnail: () => {
                  return <></>;
                },
                slide: ({ slide, rect, offset }) =>
                  SlideImageAsNextJsImage({
                    slide,
                    rect,
                    linkTo: {
                      type: 'url',
                      url,
                    },
                    offset,
                  }),
              }}
              styles={{
                thumbnailsContainer: {
                  backgroundColor: 'rgba(255, 255, 255, .9)',
                  padding: 0,
                  paddingTop: 3,
                  display: isTablet ? 'block' : 'none',
                },
                thumbnail: {
                  backgroundColor: 'rgba(0, 0, 0, .3)',
                },
              }}
            />
          </div>
          <div
            className="d-none d-md-flex align-items-start gap-2"
            style={{ position: 'absolute', zIndex: 1, top: 10, left: 10 }}
          >
            {item.visualTags
              .map((tag) => badgeByVisualTag[tag])
              .map((badge) => (
                <Badge
                  key={badge.title}
                  bg={badge.color}
                  className="font-serif fst-italic fw-bolder"
                >
                  {badge.title}
                </Badge>
              ))}
          </div>
        </div>

        <Link
          href={url}
          as={typeof url === 'string' ? url : (url.pathname as string)}
          passHref
          style={{ all: 'unset' }}
          className="h-100"
        >
          <div
            className={`d-flex flex-column bg-white position-relative h-100 px-1 pb-1 p-md-2 p-md-3 ${
              direction === 'horizontal' ? 'pt-4' : ''
            }`}
            style={{ cursor: 'pointer' }}
          >
            <ImageLoader
              style={{
                width: isTablet ? 72 : 42,
                height: isTablet ? 72 : 42,
                objectFit: 'cover',
                objectPosition: 'center',
                marginTop: -85 + (isTablet ? 0 : 50),
              }}
              className="rounded-1 border border-white"
              src={item.ownerImageSrc ?? '/images/store-details/review-author-default-image.png'}
              alt={item.owner}
              width={72}
              height={72}
              loading="lazy"
              priority={false}
            />

            {isFrontPage && (
              <div className="d-md-none">
                <div className="pt-2 text-dark text-uppercase text-truncate fs-xxs">
                  {item.name}
                </div>
                <hr
                  className="border-primary opacity-25"
                  style={{ margin: '2px 0', borderWidth: 0.5 }}
                />
                <div className="text-truncate fs-xxs text-uppercase text-purple">
                  {item.location}
                </div>
              </div>
            )}
            <div
              className={`${
                isFrontPage ? 'd-none d-md-flex flex-column justify-content-between h-100' : ''
              }`}
            >
              <div>
                <div className="pt-2 overflow-hidden">
                  <div className="text-dark text-uppercase text-truncate">{item.name}</div>
                  <div className="text-dark font-serif text-truncate">By {item.owner}</div>
                </div>

                {variant === 'short' ? (
                  <p className="my-1 fs-sm text-secondary text-truncate text-break">
                    {item.description}
                  </p>
                ) : (
                  <>
                    <p
                      className="my-1 fs-sm text-secondary text-truncate text-truncate--2 text-break"
                      style={{ height: 42 }}
                    >
                      {item.description}
                    </p>
                    <p className="my-2 fs-sm d-flex align-items-center text-truncate">
                      {item.location}
                    </p>
                    <FulfillmentOptions
                      items={item.deliveryOptions}
                      geofenceRadius={item.geofenceRadius}
                      distanceTo={item.distanceTo}
                      isTruncated={true}
                      size={'sm'}
                    />
                  </>
                )}
              </div>

              <div>
                <hr className="my-2" />
                {variant === 'short' ? (
                  <div className="d-flex justify-content-between">
                    <p className="my-2 d-flex align-items-center fs-sm fw-bolder">
                      {item.location}
                    </p>
                    <span>
                      <Button size="sm" variant="primary" className="rounded-pill shadow text-dark">
                        Shop now
                      </Button>
                    </span>
                  </div>
                ) : (
                  <div className="d-flex flex-wrap justify-content-between align-items-center">
                    <span className="fs-xs mb-0 text-lavender text-uppercase text-decoration-none">
                      {item.category}
                    </span>
                    <div className="fs-sm text-dark">
                      <Price {...item.price} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export const StoreCard = React.forwardRef(StoreCardInner);
