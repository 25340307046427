import { Router } from 'next/router';
import { useEffect } from 'react';

import { ScreenName } from '@packages/event-tracking';

import { usePreviousScreen } from './usePreviousScreen';

export const useTrackPreviousScreen = (currentScreen: ScreenName) => {
  const { setPreviousScreen } = usePreviousScreen();
  useEffect(() => {
    const updatePrevScreen = () => {
      setPreviousScreen(currentScreen);
    };
    Router.events.on('routeChangeStart', updatePrevScreen);
    return () => Router.events.off('routeChangeStart', updatePrevScreen);
  }, [currentScreen, setPreviousScreen]);
};
