import axios from 'axios';

export const fetchUserCoordinates = () =>
  axios
    .post<{
      body: {
        latitude: number;
        longitude: number;
      };
    }>(
      process.env.NEXT_PUBLIC_IPSTACK_API_URL,
      {},
      {
        headers: {
          'x-api-key': process.env.NEXT_PUBLIC_IPSTACK_API_KEY,
        },
      }
    )
    .then(({ data }) => ({ lat: data.body.latitude, lng: data.body.longitude }));
