import { User } from '@firebase/auth';
import { Client as ConversationsClient } from '@twilio/conversations';
import axios from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { useLoggedInUser } from '@hooks/useAuth';

const fetchTwilioToken = async (user: User | null) => {
  try {
    if (!user) {
      return;
    }
    const token = await user.getIdToken(true);
    const result = await axios.get(`${process.env.NEXT_PUBLIC_BASE_API_URL}/twilio/conversations`, {
      headers: { Authorization: token },
    });

    return result.data?.token;
  } catch (err) {
    console.log('TwilioProvider: Failed to get Twilio token!' + err);
    throw err;
  }
};

export const useTwilio = () => {
  const auth = useLoggedInUser();

  const [twilioToken, setTwilioToken] = useState('');

  const fetchToken = useCallback(async () => {
    try {
      const token = await fetchTwilioToken(auth.user);
      setTwilioToken(token);
    } catch (error) {
      setTwilioToken('');
      alert(error);
    }
  }, [auth.user]);

  useEffect(() => {
    fetchToken();
  }, [fetchToken]);

  const conversationsClient = useMemo(
    () => (twilioToken ? new ConversationsClient(twilioToken) : null),
    [twilioToken]
  );

  useUpdateEffect(() => {
    conversationsClient?.updateToken(twilioToken);
  }, [twilioToken]);

  useUpdateEffect(() => {
    try {
      conversationsClient?.on('tokenAboutToExpire', fetchToken);
      conversationsClient?.on('tokenExpired', fetchToken);

      return () => {
        conversationsClient?.off('tokenAboutToExpire', fetchToken);
        conversationsClient?.off('tokenExpired', fetchToken);
      };
    } catch (e) {
      console.error('Twilio Setup failed');
    }
  }, [conversationsClient]);

  return {
    conversationsClient,
    twilioOnLogIn: fetchToken,
    shutdownTwilio: async () => conversationsClient?.shutdown(),
  };
};
