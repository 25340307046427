import { useCallback } from 'react';

import { useCurrentScreen } from '@hooks/useCurrentScreen';

import { moduleByScreen } from './trackEvent';
import { Module, ScreenName } from './types';
import { useGetUserDetailsPayload } from './useGetUserDetailsPayload';

export type EventTriggerElement = 'button' | 'image';

export type BaseEventPayload = {
  component: {
    module?: Module;
    screen?: ScreenName;
    component: string; // Name of the content block containing the CTA,
    element?: EventTriggerElement; // type of element used for the CTA (e.g. button, image),
    action: string;
    label: string; // caption of button or element used in CTA,
  };
};

export const useGetBaseEventPayload = () => {
  const { screen } = useCurrentScreen();

  const getUserDetailsPayload = useGetUserDetailsPayload();
  return useCallback(
    async ({
      element,
      component = '',
      label = '',
      action = 'click',
    }: {
      element?: EventTriggerElement;
      component?: string;
      label?: string;
      action?: string;
    } = {}): Promise<BaseEventPayload> => {
      const userDetails = await getUserDetailsPayload();

      return {
        ...(await userDetails),
        component: {
          module: screen ? moduleByScreen[screen] : undefined,
          screen,
          component,
          element,
          action,
          label,
        },
      };
    },
    [getUserDetailsPayload, screen]
  );
};
