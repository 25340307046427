import { CSSProperties, ForwardedRef, forwardRef, useState } from 'react';

type PasswordToggleProps = {
  size?: string;
  light?: boolean;
  className?: string;
  style?: CSSProperties;
  id?: string;
  placeholder?: string;
  required?: boolean;
};
export const PasswordToggleInner = (
  { size, light, className, style, ...props }: PasswordToggleProps,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const inputSize = size ? ` form-control-${size}` : '',
    isLight = light ? ' form-control-light' : '',
    extraClass = className ? ` ${className}` : '';

  const [show, setShow] = useState(false);

  return (
    <div className={`password-toggle${extraClass}`} style={style}>
      <input
        {...props}
        ref={ref}
        type={show ? 'text' : 'password'}
        className={`form-control${inputSize}${isLight}`}
      />
      <label className="password-toggle-btn" aria-label="Show/hide password">
        <input
          type="checkbox"
          className="password-toggle-check"
          checked={show}
          onChange={() => setShow(!show)}
        />
        <span className="password-toggle-indicator"></span>
      </label>
    </div>
  );
};

export const PasswordToggle = forwardRef(PasswordToggleInner);
