import { Fragment } from 'react';
import { Button, Stack } from 'react-bootstrap';

import { ConversationEntity } from '@packages/messaging-and-invoicing/fetchMyConversations';
import { useMyConversations } from '@packages/messaging-and-invoicing/useMyConversations';

import { ConversationListItem } from './ConversationListItem';

type ConversationListProps = {
  onClose: () => void;
  onClick: (conversation: ConversationEntity) => void;
};

export const ConversationList = ({ onClose, onClick }: ConversationListProps) => {
  const { data: conversations } = useMyConversations();

  return (
    <div className="h-100 d-flex flex-column">
      <div className="p-3 d-flex justify-content-between align-items-center">
        <p className="mb-0 text-dark fw-bold d-flex align-items-center">
          <i className="fi-chat-circle fs-5 me-2" />
          Messages
        </p>
        <Button className="btn btn-icon" variant="link" onClick={onClose}>
          <i className="fi-minus" />
        </Button>
      </div>
      <hr />
      <Stack direction="vertical" gap={3} className="flex-grow-1 overflow-auto p-3">
        {conversations?.map((conversation, idx) => (
          <Fragment key={conversation.conversationId}>
            {idx > 0 ? <hr /> : null}
            <ConversationListItem
              onClick={() => onClick(conversation)}
              avatar={conversation.otherUser.photoURL ?? ''}
              title={`${conversation.otherUser.firstName} ${conversation.otherUser.lastName}`}
              subtitle={conversation.storefrontJSON?.businessName}
              isActive={conversation.unReadMessageCountByUser > 0}
            />
          </Fragment>
        ))}
      </Stack>
    </div>
  );
};
