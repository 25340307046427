import mixpanel from 'mixpanel-browser';

import { Module, PayloadByEventName, ScreenName } from './types';

// Enabling the debug mode flag is useful during implementation,
// but it's recommended you remove it for production
mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, {
  debug: process.env.NODE_ENV !== 'production',
});

export const trackMixpanelEvent = async (eventName: string, properties?: object) => {
  if (properties && 'userId' in properties) {
    const userId = properties?.userId;

    if (typeof userId == 'string') {
      await mixpanel.identify(userId);
    }
  }

  mixpanel.track(eventName, properties);
};

export const moduleByScreen: Record<ScreenName, Module> = {
  [ScreenName.Search]: 'Search',
  [ScreenName.Browse]: 'Browse',
  [ScreenName.BrowseDetail]: 'Browse Detail',
  [ScreenName.Home]: 'Home',
  [ScreenName.QuickShopDetail]: 'Quick Shop',
  [ScreenName.ProfileManagement]: 'Profile Management',
  [ScreenName.BrowseMissing]: 'Browse Detail Missing',
  [ScreenName.BrowsePaused]: 'Browse Detail Paused',
  [ScreenName.Checkout]: 'Cart',
  [ScreenName.CheckoutConfirmed]: 'Cart',
  [ScreenName.CheckoutFromHashId]: 'Cart',
};

type TrackEvent = <T extends keyof PayloadByEventName>(
  eventName: T,
  properties?: PayloadByEventName[T]
) => void;

export const trackEvent: TrackEvent = trackMixpanelEvent;
