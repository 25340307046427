import { Client, Conversation } from '@twilio/conversations';
import { useEffect, useState } from 'react';

export const useConversationProxy = (
  conversationsClient: Client | null,
  conversationSid: string | undefined
) => {
  const [conversationProxy, setConversationProxy] = useState<Conversation | undefined>();

  useEffect(() => {
    (async () => {
      if (conversationSid && conversationsClient?.connectionState === 'connected') {
        const conversation = await conversationsClient.getConversationBySid(conversationSid);
        setConversationProxy(conversation);
      } else {
        setConversationProxy(undefined);
      }
    })();
  }, [conversationSid, conversationsClient, conversationsClient?.connectionState]);

  return conversationProxy;
};
