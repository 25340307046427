import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback } from 'react';
import { Button, Form, Spinner, Stack } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';

import { formatCurrency } from '@utils/formatCurrency';

import {
  AddCreditCardForm,
  addCreditCardFormValidationSchema,
  AddCreditCardVM,
  useAddCreditCard,
} from './AddCreditCardForm';
import { CreditCard } from './CreditCard';
import { useCreditCard, usePayInvoice, useRejectInvoice, useRemovePaymentMethod } from './hooks';
import { PoweredByStripeIcon } from './PoweredByStripeIcon';

export const PayInvoiceSection = ({
  invoiceTotal,
  invoiceId,
  onInvoiceRejected,
  onInvoicePaid,
}: {
  invoiceTotal: number;
  invoiceId?: number;
  onInvoiceRejected: () => void;
  onInvoicePaid: () => void;
}) => {
  const methods = useForm<AddCreditCardVM>({
    resolver: zodResolver(addCreditCardFormValidationSchema),
  });

  const { mutateAsync: payInvoice, isLoading: payInvoiceLoading } = usePayInvoice();
  const handlePayInvoice = async () => {
    if (invoiceId) {
      await payInvoice({ id: invoiceId });
      onInvoicePaid();
    }
  };
  const { mutateAsync: addCreditCard, isLoading: addCreditCardLoading } = useAddCreditCard();
  const createCreditCardAndPayInvoice = async (values: AddCreditCardVM) => {
    await addCreditCard(values);
    return await handlePayInvoice();
  };

  const { data: creditCard, isFetching: creditCardLoading } = useCreditCard();

  const rejectInvoiceMutation = useRejectInvoice();
  const rejectInvoice = useCallback(async () => {
    if (invoiceId && confirm('Are you sure?')) {
      await rejectInvoiceMutation.mutateAsync({ invoiceId });
      onInvoiceRejected();
    }
  }, [invoiceId, onInvoiceRejected, rejectInvoiceMutation]);
  const removePaymentMethod = useRemovePaymentMethod();
  const isLoading =
    rejectInvoiceMutation.isLoading ||
    payInvoiceLoading ||
    addCreditCardLoading ||
    creditCardLoading ||
    removePaymentMethod.isLoading;
  return (
    <FormProvider {...methods}>
      <Form className="h-100" onSubmit={methods.handleSubmit(createCreditCardAndPayInvoice)}>
        <Stack
          className="py-lg-5 pb-lg-3 px-lg-5 h-100 position-relative"
          gap={3}
          direction="vertical"
        >
          <div className="d-flex justify-content-between">
            <h6 className="mb-0 text-brownsugar font-serif">Pay Using:</h6>
            <PoweredByStripeIcon />
          </div>
          <div>
            {isLoading ? (
              <div
                className="d-flex flex-grow-1 position-absolute h-50 justify-content-center align-items-center bg-white bg-opacity-50"
                style={{ zIndex: 10, left: 0, right: 0, top: 0, bottom: 0 }}
              >
                <Spinner animation="border" role="status" />
              </div>
            ) : null}
            {creditCard ? (
              <Stack direction="vertical" gap={2}>
                <CreditCard
                  displayName={creditCard.name}
                  creditCardNumber={creditCard.number}
                  creditCardExpiry={creditCard.expiry}
                  brand={creditCard.brand}
                />
                <Button
                  variant="outline-accent"
                  onClick={() => removePaymentMethod.mutateAsync()}
                  disabled={removePaymentMethod.isLoading}
                >
                  Remove Card
                </Button>
              </Stack>
            ) : (
              <AddCreditCardForm />
            )}
          </div>

          <p className="fs-xs d-flex align-items-center mb-0 fw-bolder">
            <i className="fi-lock me-2" />
            Secure Payment
          </p>
          <div className="d-flex flex-grow-1 align-items-end justify-content-center">
            <div className="flex-grow-1">
              <div className="d-flex justify-content-end gap-3">
                <Button
                  disabled={!invoiceId || isLoading}
                  onClick={rejectInvoice}
                  variant="outline-primary"
                  className="rounded-pill "
                >
                  <i className="fi-x me-2" />
                  Decline
                </Button>
                <Button
                  disabled={!invoiceId || isLoading}
                  {...(creditCard
                    ? {
                        onClick: handlePayInvoice,
                      }
                    : { type: 'submit' })}
                  variant="primary"
                  className="rounded-pill w-50 text-light"
                >
                  Pay {formatCurrency(invoiceTotal / 100)}
                </Button>
              </div>
            </div>
          </div>
        </Stack>
      </Form>
    </FormProvider>
  );
};
