import { ScreenName } from '@packages/event-tracking';

// Base type for the component payload of 'Invoice Detail' event
export type InvoiceDetailComponentPayload = {
  module: 'Invoice Detail';
  isWeb: true;
  screen: ScreenName;
  element: 'button';
  action: 'press';
};

// Type for the attributes of 'Reject Invoice' event
export type RejectInvoiceAttributes = InvoiceDetailComponentPayload & {
  eventType: 'Reject invoice';
  component: 'Invoice';
  invoiceId: number;
  buyerName: string;
  businessName: string;
  sellerName: string;
  isDeposit: boolean;
  orderAmount: number;
  additionalCosts: number;
};

// Type for the attributes of 'Pay Invoice' event
export type PayInvoiceEventPayload = InvoiceDetailComponentPayload & {
  eventType: 'Pay Invoice';
  component: 'Invoice';
  buyerName: string;
  businessName: string;
  sellerName: string;
  isDeposit: boolean;
  orderAmount: number;
  additionalCosts: number;
  salesTax: number;
  paymentProcessingFee: number;
  marketplaceCommission: number;
  discountCodeUsed: string;
  discountCodeApplies: number;
};

// Type for the attributes of 'Add Credit Card' event
export type AddCreditCardEventPayload = InvoiceDetailComponentPayload & {
  eventType: 'Add Credit Card';
  component: 'Credit Card';
};

// Type for the attributes of 'Delete Credit Card' event
export type DeleteCreditCardEventPayload = InvoiceDetailComponentPayload & {
  eventType: 'Delete Credit Card';
  component: 'Credit Card';
};

type DiscountCodeEventBasePayload = Omit<InvoiceDetailComponentPayload, 'action'> & {
  eventType: 'Discount Code Event';
  component: 'Discount Code';
};

// Type for the attributes of 'Successfully applied a discount code' event
export type DiscountCodeSuccessEventPayload = DiscountCodeEventBasePayload & {
  promoCode: string;
  action: 'added';
};

// Type for the attributes of 'Unsuccessfully applied discount code' event
export type DiscountCodeErrorEventPayload = DiscountCodeEventBasePayload & {
  error: string;
  action: 'error';
};

// Type for the attributes of 'Remove a discount code' event
export type DiscountCodeRemoveEventPayload = DiscountCodeEventBasePayload & {
  promoCode: string;
  action: 'remove';
};

// Type for the attributes of all Discount code events
export type DiscountCodeEventPayload =
  | DiscountCodeSuccessEventPayload
  | DiscountCodeErrorEventPayload
  | DiscountCodeRemoveEventPayload;

export type InvoiceDetailsEventPayload =
  | DiscountCodeEventPayload
  | DeleteCreditCardEventPayload
  | AddCreditCardEventPayload
  | PayInvoiceEventPayload
  | RejectInvoiceAttributes;

export enum InvoiceEventName {
  PayInvoice = 'PayInvoice',
  RejectInvoice = 'RejectInvoice',
  AddCreditCard = 'AddCreditCard',
  DeleteCreditCard = 'DeleteCreditCard',
  DiscountCodeSuccess = 'DiscountCodeSuccess',
  DiscountCodeError = 'DiscountCodeError',
  DiscountCodeRemove = 'DiscountCodeRemove',
}

export type InvoiceEventPayloadByName = {
  [InvoiceEventName.PayInvoice]: PayInvoiceEventPayload;
  [InvoiceEventName.RejectInvoice]: RejectInvoiceAttributes;
  [InvoiceEventName.AddCreditCard]: AddCreditCardEventPayload;
  [InvoiceEventName.DeleteCreditCard]: DeleteCreditCardEventPayload;
  [InvoiceEventName.DiscountCodeSuccess]: DiscountCodeSuccessEventPayload;
  [InvoiceEventName.DiscountCodeError]: DiscountCodeErrorEventPayload;
  [InvoiceEventName.DiscountCodeRemove]: DiscountCodeRemoveEventPayload;
};
