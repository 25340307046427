import { makeGqlCall } from './makeGqlCall';

const getInTouchQuery = /* GraphQL */ `
  mutation ($order: GetInTouchInput!) {
    getInTouchV3(order: $order, appusertype: BUYER, version: V3, isWeb: true) {
      conversationId
    }
  }
`;

type GetInTouchResponse = {
  getInTouchV3: {
    conversationId: string;
  };
};

type GetInTouchVariables = {
  order: {
    storefrontId: number;
    note: string;
    isQuickShop?: boolean;
    deliveryMethod?: string;
    timeframe?: string;
  };
};

export const getInTouchMutation = makeGqlCall<GetInTouchResponse, GetInTouchVariables>(
  getInTouchQuery
);
