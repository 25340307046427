import { Button, Stack } from 'react-bootstrap';

import { InvoiceItem } from './components';
import { useInvoiceDetailsModal } from './components/InvoiceDetailsModal/useInvoiceDetailsModal';
import { invoiceStateByEntity } from '../../../../constants';
import { InvoiceVM, useInvoices } from '../../../../useInvoices';
import { invoiceIconClickPubSub } from '../../../useTrackInvoicingEvents';
import { InvoiceIcon } from '../../InvoiceIcon';

export const InvoiceList = ({ onClose }: { onClose: () => void }) => {
  const { data: invoices } = useInvoices();
  const invoiceDetailsState = useInvoiceDetailsModal();

  const openInvoiceDetails = async (invoice: InvoiceVM) => {
    invoiceIconClickPubSub.publish({
      businessName: invoice.storefront.businessName,
      buyerName: invoice.buyer.displayName ?? '',
      invoiceId: invoice.id,
      sellerName: invoice.storefront.owner.displayName ?? '',
    });
    invoiceDetailsState.open({ id: invoice.id });
  };

  const invoicesToBePaid = invoices?.filter(
    (invoice) => invoice.status && invoiceStateByEntity[invoice.status] === 'PENDING'
  );
  return (
    <div className="h-100 d-flex flex-column">
      <div className="p-3 d-flex justify-content-between align-items-center">
        <p className="mb-0 text-dark fw-bold d-flex align-items-center">
          <InvoiceIcon className="me-2" width={16} fill="#607581" height={16} />
          All Orders
        </p>
        <Button className="btn btn-icon" variant="link" onClick={onClose}>
          <i className="fi-minus" />
        </Button>
      </div>
      <hr />
      <Stack direction="vertical" gap={3} className="flex-grow-1 overflow-auto py-3">
        {invoicesToBePaid?.length ? (
          <div className="p-3 d-flex align-items-center bg-banana">
            <InvoiceIcon className="me-2" width={16} fill="#385262" height={16} />
            <span className="font-serif fw-bolder text-dark fst-italic">
              You have {invoicesToBePaid?.length} unpaid orders
            </span>
          </div>
        ) : null}
        {invoices?.map((item) =>
          item.status ? (
            <InvoiceItem
              onClick={() => openInvoiceDetails(item)}
              key={item.id}
              owner={item.storefront.owner}
              price={(item.totalToBePaid ?? 0) / 100}
              name={item.serviceName}
              state={invoiceStateByEntity[item.status]}
            />
          ) : null
        )}
      </Stack>
    </div>
  );
};
