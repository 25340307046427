import { ReactNode } from 'react';

import { formatCurrency } from '@utils/formatCurrency';

import type { InvoiceDetailsVM } from './useInvoiceDetails';

const getInvoiceAmount = (invoice: InvoiceDetailsVM) =>
  invoice.amount ??
  0 + (invoice.buyerPaysMarketplaceCommission ? (invoice.marketplaceCommission ?? 0) / 100 : 0);

export const InvoicePricing = ({
  invoice,
  applyPromoCodeInputGroup,
}: {
  invoice?: InvoiceDetailsVM;
  applyPromoCodeInputGroup?: ReactNode;
}) => {
  return (
    <>
      <div className="d-flex justify-content-between text-dark px-3">
        <span className="fs-sm">Order Amount:</span>
        <span className="fs-sm fw-bolder text-brownsugar">
          {invoice ? formatCurrency(getInvoiceAmount(invoice)) : '-'}
        </span>
      </div>
      {applyPromoCodeInputGroup ? (
        <div className="border border-warning rounded px-3 py-2">
          <p className="fw-bolder mb-0 font-serif fst-italic text-brownsugar">Discount Code:</p>
          {applyPromoCodeInputGroup}
        </div>
      ) : null}
      {invoice?.discountCode?.orderDiscountAmount ?? 0 > 0 ? (
        <div className="d-flex justify-content-between text-success px-3">
          <span className="fs-sm">Discount Amount:</span>
          <span className="fs-sm fw-bolder text-brownsugar">
            {formatCurrency(invoice?.discountCode?.orderDiscountAmount || 0)}
          </span>
        </div>
      ) : null}
      <div className="d-flex justify-content-between text-dark px-3">
        <span className="fs-sm">Delivery/Shipping:</span>
        <span className="fs-sm fw-bolder text-brownsugar">
          {formatCurrency(invoice?.additionalCost ?? 0)}
        </span>
      </div>
      <div className="d-flex justify-content-between text-dark px-3">
        <span className="fs-sm">Sales Tax:</span>
        <span className="fs-sm fw-bolder text-brownsugar">
          {formatCurrency(invoice?.salesTax ?? 0)}
        </span>
      </div>
      {invoice?.buyerPaysProcessingFee ? (
        <div className="d-flex justify-content-between text-dark px-3">
          <span className="fs-sm">Processing Fee:</span>
          <span className="fs-sm fw-bolder text-brownsugar">
            {formatCurrency((invoice?.paymentProcessingFee ?? 0) / 100)}
          </span>
        </div>
      ) : null}
      <hr className="border-secondary" />
      <div className="d-flex fw-bolder justify-content-between text-dark px-3">
        <h5 className="mb-0 font-serif text-dark">Total Cost:</h5>
        <h5 className="text-brownsugar mb-0">
          {formatCurrency((invoice?.totalToBePaid ?? 0) / 100)}
        </h5>
      </div>
      <hr className="border-secondary" />
    </>
  );
};
