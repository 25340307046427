import { UserType } from '../../api/fetchUser';
import { makeGqlCall } from '../../api/makeGqlCall';

const myConversationListQuery = /* GraphQL */ `
  query ($filter: FilterConversationListInput!) {
    myConversationList(filter: $filter) {
      pagination {
        total
        perPage
        currentPage
        maxPage
        from
        to
      }
      data {
        conversationId
        created_at
        twilioConversationSid
        lastMessage {
          messageIndex
          twilioMessageSid
          hasMedia
          body
          sentByUserId
          created_at
        }
        otherUser {
          lastName
          firstName
          phoneNumber
          conversationUserType
          uid
          zip
          city
          streetAddress
          photoURL
          displayName
          email
        }
        storefrontJSON {
          businessName
          uriSlug
        }
        unReadMessageCountByUser
      }
    }
  }
`;

type MyConversationsVariables = {
  filter: {
    otherUserId?: string;
    conversationId?: number;
    conversationUserType: UserType;
  };
};

export type ConversationEntity = {
  conversationId: number;
  created_at: string;
  twilioConversationSid?: string;
  unReadMessageCountByUser: number;

  lastMessage?: {
    created_at: string;
    messageIndex: number;
    sentByUserId: string;
    hasMedia: boolean;
    body?: string;
    twilioMessageSid: string;
  };
  otherUser: {
    conversationUserType: UserType;
    uid: string;
    zip?: string;
    city?: string;
    streetAddress?: string;
    phoneNumber?: string;
    photoURL?: string;
    displayName?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
  };
  storefrontJSON?: {
    id: number;
    businessName: string;
    uriSlug: string;
  };
};

type MyConversationsResponse = {
  myConversationList: {
    pagination?: {
      total: number;
      perPage: number;
      currentPage: number;
      maxPage: number;
      from: number;
      to: number;
    };
    data: Array<ConversationEntity>;
  } | null;
};

export const fetchMyConversations = makeGqlCall<MyConversationsResponse, MyConversationsVariables>(
  myConversationListQuery
);
