import { Form } from 'react-bootstrap';
import { Controller, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';

export const phoneNumberFormat = '+1(###) ###-####';

export const normalizePhoneNumber = (phoneNmber: string | undefined) =>
  (phoneNmber || '')
    .replace('+1', '') // Get rid of any +1 in the phone number if it's there
    .replace(/[^\d]/g, ''); // Get rid of any non-digit values e.g. -, ., (, )

export const PhoneNumberField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  name,
  label,
  isInvalid,
}: UseControllerProps<TFieldValues, TName> & { label: string; isInvalid: boolean }) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { onChange, name, value } }) => (
      <Form.Control
        type="phone"
        as={PatternFormat}
        isInvalid={isInvalid}
        format={phoneNumberFormat}
        mask="_"
        placeholder={label}
        onChange={onChange}
        name={name}
        value={value}
      />
    )}
  />
);
