import { makeGqlCall } from './makeGqlCall';

const registerSharedLeadQuery = /* GraphQL */ `
  mutation ($contactId: Int!) {
    registerSharedLead(contactId: $contactId) {
      success
    }
  }
`;

type RegisterSharedLeadResponse = {
  registerSharedLead: {
    success: boolean;
  };
};

type RegisterSharedLeadVariables = {
  contactId: number;
};

export const registerSharedLeadMutation = makeGqlCall<
  RegisterSharedLeadResponse,
  RegisterSharedLeadVariables
>(registerSharedLeadQuery);
