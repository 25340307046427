import { defaultStoreOwnerImageSrc } from '@constants';

import { ImageLoader } from './ImageLoader';

export const StoreOwnerImage = ({
  size,
  src,
  alt,
  rounded = 3,
  noBorder = false,
  border = 'warning',
}: {
  size: number;
  src?: string | null;
  alt?: string;
  rounded?: 1 | 2 | 3 | 'circle';
  noBorder?: boolean;
  border?: string;
}) => (
  <div
    style={{ width: size, height: size }}
    className={`position-relative flex-shrink-0 rounded-${rounded} ${
      noBorder ? '' : `border border-2 border-${border}`
    } overflow-hidden`}
  >
    <ImageLoader
      fill
      style={{ objectFit: 'cover', objectPosition: 'center' }}
      src={src ?? defaultStoreOwnerImageSrc}
      alt={alt ?? ''}
    />
  </div>
);
