import { useHits, useInstantSearch } from 'react-instantsearch';
import { z } from 'zod';

import { validationMessages } from '@constants';

export const DeliveryMethodSchema = z.union(
  [
    z.literal('Pick Up'),
    z.literal('Shipping'),
    z.literal('Local Delivery'),
    z.literal('Onsite'),
    z.literal('Virtual Service'),
  ],
  {
    required_error: validationMessages.required,
  }
);
export type Fullfillment = z.infer<typeof DeliveryMethodSchema>;

export const visualTagValidationSchema = z.union([
  z.literal('sale'),
  z.literal('trending'),
  z.literal('black-owned'),
]);
export type VisualTag = z.infer<typeof visualTagValidationSchema>;
export type PriceType = 'PER_PRODUCT' | 'PER_HOUR' | 'VARIES' | 'FIXED';

export type CatalogItem = {
  storefrontCardURL: Array<string>;
  name: string;
  price: number;
  description: string;
  catalogId: number;
  lightboxZoomURL: Array<string>;
  rawurl: Array<string>;
  priceType: 'FIXED' | 'PER_HOUR';
  pricingDescription: string;
  storefrontId: string;
  allowSelectQuantity: boolean;
  uriSlug: string;
};

export type StoreCategory = { title: string; id: number; uriSlug: string };

export type Store = {
  id: number;
  created_at: string;
  updated_at: string;
  priceType: PriceType;
  businessName: string;
  status: 'ACTIVE' | 'ACTIVE_NO_PAYMENT';
  tagline: string;
  description: string;
  paused?: boolean;
  priceLow: number;
  priceHigh: number;
  city: string;
  state: string;
  country: string;
  detail: string;
  zipCode: string;
  deletedAt: string | null;
  testingFlag: string | null;
  categoryId: number;
  userId: string;
  primaryImage: string;
  dynamicLink: string;
  geofenceRadius: number;
  fulfillmentJSON?: Record<
    Fullfillment,
    {
      active: boolean;
      message?: string;
    }
  >;
  instaUserId: null | string;
  categoryJSON: Record<string, boolean>;
  categoryList: string[];
  catalogSortOrder: number[];
  visualTags: VisualTag[];
  adminCategoryJSON: {};
  approvalDate: string;
  startPauseDate: null | string;
  endPauseDate: null | string;
  lastInstaTokenUpdate: null | string;
  photos: {
    main?: {
      name: string;
      size: number;
      uri: string;
    };
    others?: Array<{
      name: string;
      size: number;
      uri: string;
    }>;
  };
  owner: {
    displayName: string;
    firstName: string;
    photoUrl: null | string;
    uid: string;
  };
  category?: {
    active: boolean;
    icon: string;
    id: number;
    title: string;
  };
  categories: {
    lvl0: string[];
    lvl1: string[];
  };
  date_timestamp: number;
  fulfillmentOptions: Fullfillment[];
  _rankingInfo: {
    matchedGeoLocation: {
      distance: number;
    };
  };
  catalog: Array<CatalogItem>;
  statistics: {
    statistics: {
      avgReview: number | null;
      countReview: number | null;
    };
  };
  primaryCategory: string;
  objectID: string;
  uriSlug: string;
  storefrontPricing: {
    suffix: string;
    priceRange: string;
    prefix: string;
  };
  storefrontCategoryUriSlugs?: Array<StoreCategory>;
};

export const useStores = () => {
  const { hits } = useHits<Store>();
  const { status } = useInstantSearch();
  return { stores: hits, loading: status === 'loading' || status === 'stalled' };
};
