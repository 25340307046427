import { Client } from '@twilio/conversations';
import { createContext, ReactNode } from 'react';
import { useQueryClient } from 'react-query';
import { useUnmount, useUpdateEffect } from 'react-use';

import { useHandleNewConversationsAdded } from './useHandleNewConversationsJoined';
import { useTwilio } from '../useTwilio';

type MessengerContext = { conversationsClient: Client | null };
export const MessengerContext = createContext<MessengerContext>({ conversationsClient: null });

export const MessengerContextProvider = ({ children }: { children: ReactNode }) => {
  const { shutdownTwilio, conversationsClient } = useTwilio();
  useUnmount(() => {
    shutdownTwilio();
  });
  const queryClient = useQueryClient();

  useUpdateEffect(() => {
    const invalidateConversations = () => queryClient.refetchQueries('myConversations');
    conversationsClient?.on('messageAdded', invalidateConversations);

    return () => {
      conversationsClient?.off('messageAdded', invalidateConversations);
    };
  }, [conversationsClient]);

  useHandleNewConversationsAdded(conversationsClient);

  return (
    <MessengerContext.Provider value={{ conversationsClient }}>
      {children}
    </MessengerContext.Provider>
  );
};
