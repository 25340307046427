import axios from 'axios';
import { useQuery } from 'react-query';

import { getCoordinatesByPlaceId } from '@hooks/useCoordinatesByPlaceId';
import { useUserLocation } from '@hooks/useUserLocation';
import { metersToMiles } from '@utils/miles';

export const formatDistance = (miles: number) => {
  return `${miles} mile${miles > 1 ? 's' : ''}`;
};

/**
 * placeId is optional so that we can handle the scenario when the user has cleared
 *  or otherwise blocked their location. The API will then attempt to determine the
 *  user's location based upon their IP address
 * NOTE: distance value could be different depending on the system calculating the distance
 */
export const fetchDistanceToStore = async (storeId: number, placeId?: string | undefined) => {
  const coords = placeId ? await getCoordinatesByPlaceId(placeId) : { lat: '', lng: '' };
  const formattedCoords = coords ? `${coords.lat},${coords.lng}` : '';
  const res = await axios.get<{ hits?: Array<{ _rankingInfo?: { distance: number } }> }>(
    `${process.env.NEXT_PUBLIC_FETCH_STORE_API_URL}/${storeId}/${formattedCoords}/distance`
  );
  const distanceInMeters = res.data.hits?.[0]?._rankingInfo?.distance;
  return distanceInMeters && metersToMiles(distanceInMeters);
};

export const useDistanceToStore = (storeId: number) => {
  const { location } = useUserLocation();

  return useQuery(
    ['distance', storeId, location?.place_id],
    async () => await fetchDistanceToStore(storeId, location?.place_id),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const DistanceToStore = ({ distanceTo }: { distanceTo: number | undefined }) => {
  return <>{typeof distanceTo === 'number' ? formatDistance(distanceTo) : null}</>;
};
