import Link from 'next/link';
import PubSub from 'pubsub-js';
import { ReactNode } from 'react';
import { Button, Col, Container, Nav, Offcanvas, Row } from 'react-bootstrap';
import { useWindowScroll } from 'react-use';

import { Circle } from '@components/Circle';
import { ClientOnly } from '@components/ClientOnly';
import { CategoriesAllMenu } from '@components/Header/CategoriesAllMenu';
import { useAuth } from '@hooks';
import { useCategoriesSidebarVisibilityState } from '@hooks/useCategoriesSidebarVisibilityState';
import { useIsMediaBreakpoint } from '@hooks/useIsMediaBreakpoint';
import { useMenuVisibilityState } from '@hooks/useMenuVisibilityState';
import { EventName } from '@packages/event-tracking';
import { invoiceStateByEntity } from '@packages/messaging-and-invoicing/constants';
import { InvoiceIcon } from '@packages/messaging-and-invoicing/MessagingAndInvoicing/components/InvoiceIcon';
import { useMessagingAndInvoicing } from '@packages/messaging-and-invoicing/MessagingAndInvoicing/hooks';
import { useInvoices } from '@packages/messaging-and-invoicing/useInvoices';
import { useMyConversations } from '@packages/messaging-and-invoicing/useMyConversations';
import { useCartOrder } from '@screens/CheckoutScreen/useCartOrder';

import { AuthNav } from './AuthNav';
import { CategoriesTopMenu } from './CategoriesTopMenu';
import { ImageLoader } from '../ImageLoader';

type HeaderProps = {
  extra?: ReactNode;
  checkoutDisabled?: boolean;
};

export const toggleMessagingButton = 'toggle-messaging-button';

export const Header = ({ extra, checkoutDisabled }: HeaderProps) => {
  const { y: scrollFromTop } = useWindowScroll();

  const { setIsMenuVisible } = useMenuVisibilityState();
  const { isCategoriesSidebarVisible, setIsCategoriesSidebarVisible } =
    useCategoriesSidebarVisibilityState();

  const { data: invoices } = useInvoices();
  const invoicesToBePaid = invoices?.filter(
    (invoice) => invoice.status && invoiceStateByEntity[invoice.status] === 'PENDING'
  );

  const { data: conversations = [] } = useMyConversations();
  const unreadMessages = conversations.filter((c) => c.unReadMessageCountByUser > 0);

  const checkoutAvailable = useCartOrder().orderItems.length > 0 && !checkoutDisabled;

  const messagingAndInvoicing = useMessagingAndInvoicing();
  const openMessaging = () => messagingAndInvoicing.toggleMessaging();

  const auth = useAuth();

  const isTablet = useIsMediaBreakpoint('md');

  return (
    <>
      <div
        className="page-header fixed-top border-bottom bg-light px-md-2"
        style={{ ...(isTablet ? { marginLeft: '86px' } : {}) }}
      >
        <Container className="py-2 py-xl-3">
          <Row className="align-items-center gy-3">
            <Col xs={7} md={5} xl={4} xxl={{ span: 4, offset: 1 }} className="text-xl-center">
              <div className="d-flex d-xl-block align-items-center">
                <div className="menu-button d-md-none me-3" onClick={() => setIsMenuVisible(true)}>
                  <div className="line" />
                  <div className="line" />
                  <div className="line" />
                </div>

                <Link href="/" passHref>
                  <ImageLoader
                    priority
                    src="/images/logo-text.svg"
                    width={300}
                    height={50}
                    alt="wtenth logo"
                  />
                </Link>
              </div>
            </Col>
            <Col xs={5} md={7} xl={4} className="d-flex justify-content-end">
              <Nav className="align-items-center">
                <ClientOnly>
                  <Nav.Item className="d-none d-md-block">
                    <AuthNav />
                  </Nav.Item>
                </ClientOnly>

                <Nav.Item>
                  <Link
                    target="_blank"
                    href="https://www.westtenth.com/sellwithus"
                    className="btn btn-primary btn-sm rounded-4 d-none d-md-block me-2"
                  >
                    Start Selling
                  </Link>
                </Nav.Item>

                <ClientOnly>
                  {auth.user ? (
                    <>
                      <Nav.Item>
                        <Button
                          variant="link"
                          className={`btn-icon btn-sm position-relative primary-icon`}
                          onMouseUp={openMessaging}
                          disabled={!conversations.length}
                          id={toggleMessagingButton}
                        >
                          <i className="fi-chat-circle fs-5" style={{ pointerEvents: 'none' }} />
                          {unreadMessages.length > 0 ? (
                            <Circle
                              radius={4.5}
                              className="bg-primary border-white border position-absolute"
                              style={{
                                right: -1,
                                top: 2.5,
                              }}
                            />
                          ) : null}
                        </Button>
                      </Nav.Item>
                      <Nav.Item>
                        <Button
                          variant="link"
                          className={`btn-icon btn-sm position-relative primary-icon`}
                          disabled={!invoices?.length}
                          onClick={() => messagingAndInvoicing.toggleInvoicing()}
                        >
                          <InvoiceIcon width={20} height={20} />
                          {invoicesToBePaid?.length ? (
                            <Circle
                              radius={4.5}
                              className="bg-primary border-white border position-absolute"
                              style={{
                                right: -1,
                                top: 2.5,
                              }}
                            />
                          ) : null}
                        </Button>
                      </Nav.Item>
                    </>
                  ) : null}

                  <Nav.Item>
                    <Nav.Link
                      disabled={!checkoutAvailable}
                      href="/order/checkout"
                      className={`position-relative p-0 ${
                        checkoutAvailable ? 'text-primary' : 'text-secondary opacity-50'
                      }`}
                    >
                      <Button variant="link" className={`btn-icon btn-sm primary-icon`}>
                        <span className="position-relative">
                          <i className="fi-cart fs-4 opacity-100" />
                          {checkoutAvailable ? (
                            <Circle
                              radius={4.5}
                              className="bg-primary border-white border position-absolute"
                              style={{
                                right: -1,
                                top: 2.5,
                              }}
                            />
                          ) : null}
                        </span>
                      </Button>
                    </Nav.Link>
                  </Nav.Item>
                </ClientOnly>
              </Nav>
            </Col>
            <Col lg={12} xl={{ span: 4, order: 'first' }} xxl={{ span: 3 }}>
              {extra}
            </Col>
          </Row>
        </Container>

        <div
          className={`d-none ${
            scrollFromTop > 100 ? 'd-xl-block' : ''
          } position-absolute w-100 mx-n2`}
          style={{ overflowX: 'clip' }}
        >
          <CategoriesTopMenu />
        </div>

        <Offcanvas
          id="offcanvas-navbar"
          aria-labelledby="offcanvas-navbar-label"
          placement="start"
          show={isCategoriesSidebarVisible}
          onHide={() => setIsCategoriesSidebarVisible(false)}
        >
          <Offcanvas.Header closeButton className="border-bottom bg-primary">
            <Offcanvas.Title
              as="h6"
              id="offcanvasNavbarLabel"
              className="text-uppercase"
              style={{ color: '#DDFF7B' }}
            >
              Browse by Category
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="bg-primary pt-1">
            <CategoriesAllMenu />
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};
