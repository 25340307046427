import { pick } from 'lodash/fp';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { useSessionStorage } from 'react-use';

const useRouterQuery = (fields: string[]) => {
  const router = useRouter();

  return useMemo(() => {
    if (typeof window !== 'undefined') {
      return pick(fields, router?.query);
    }
    return {};
  }, [fields, router?.query]);
};

const useParseQueryAndSaveToSessionStorage = (name: string, fields: string[]) => {
  const valueFromQuery = useRouterQuery(fields);
  const [valueFromStorage, saveToStorage] = useSessionStorage(name, {});

  const value = useMemo(() => {
    if (typeof window !== 'undefined') {
      return {
        ...valueFromStorage,
        ...valueFromQuery,
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueFromQuery]);

  return [value, saveToStorage] as const;
};

const utmVariables = ['utm_campaign', 'utm_source', 'utm_medium', 'utm_term', 'utm_content'];
export const useUtmVariables = () => useParseQueryAndSaveToSessionStorage('utm', utmVariables);

const attributionVariables = ['sscid'];
export const useAttributionVariables = () =>
  useParseQueryAndSaveToSessionStorage('attribution', attributionVariables);

export const useSaveQueryStringVariables = () => {
  const [utmVariables, saveUtmVariables] = useUtmVariables();
  useEffect(() => {
    if (typeof window !== 'undefined' && utmVariables) {
      saveUtmVariables(utmVariables);
    }
  }, [saveUtmVariables, utmVariables]);

  const [attributionVariables, saveAttributionVariables] = useAttributionVariables();
  useEffect(() => {
    if (typeof window !== 'undefined' && attributionVariables) {
      saveAttributionVariables(attributionVariables);
    }
  }, [attributionVariables, saveAttributionVariables]);
};
