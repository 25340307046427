import { Store } from '@hooks';

export const formatStoreReviewStatistics = ({
  statistics,
}: Pick<Store, 'statistics'>['statistics']) =>
  statistics.countReview
    ? `${statistics.avgReview?.toFixed(1) ?? 0} (${statistics.countReview ?? 0} review${
        statistics.countReview === 1 ? '' : 's'
      })`
    : null;
