import moment from 'moment';
import { Stack } from 'react-bootstrap';

import { StoreOwnerImage } from '@components/StoreOwnerImage';
import { InvoiceState } from '@packages/messaging-and-invoicing/constants';
import { truncateAndAddEllipsis } from '@utils/truncateAndAddEllipsis';

import { ApplyPromoCodeInputGroup } from './ApplyPromoCodeInputGroup';
import { InvoicePricing } from './InvoicePricing';
import { InvoiceDetailsVM } from './useInvoiceDetails';

type InvoiceDetailsProps = {
  invoiceDetails: InvoiceDetailsVM | undefined;
  state: InvoiceState | null;
};
export const InvoiceDetails = ({ invoiceDetails, state }: InvoiceDetailsProps) => {
  const invoiceNotes = invoiceDetails?.notes.map((note) => note.body).join('\n');

  const applyPromoCodeInputGroup = (
    <ApplyPromoCodeInputGroup
      invoiceId={invoiceDetails?.id}
      appliedDiscountCode={
        invoiceDetails?.discountCode
          ? {
              id: invoiceDetails.discountCode.discountCodeId,
              value: invoiceDetails.discountCode.code,
            }
          : undefined
      }
    />
  );

  return (
    <>
      <Stack direction="horizontal" gap={3} className="align-items-start pb-3">
        <StoreOwnerImage
          rounded={3}
          noBorder
          src={invoiceDetails?.storefront.owner.photoURL ?? null}
          size={50}
          alt={invoiceDetails?.storefront.owner?.displayName ?? 'storeowner image'}
        />
        <Stack direction="vertical" className="align-items-start">
          <h5 className="mb-0 text-dark fw-bolder">
            {invoiceDetails?.storefront.owner.displayName ?? '-'}
          </h5>
          <span className="fs-sm text-brownsugar">
            {truncateAndAddEllipsis(invoiceDetails?.storefront?.businessName ?? '')}
          </span>
        </Stack>
      </Stack>
      <hr className="border-secondary" />
      <Stack direction="vertical" gap={2} className="pt-3">
        <h5 className="text-brownsugar font-serif mb-0 lh-1">{invoiceDetails?.serviceName}</h5>
        {invoiceNotes ? <p className="fs-sm mb-0">{invoiceNotes}</p> : null}
      </Stack>
      <div className="flex-grow-1 overflow-auto py-3">
        <Stack direction="vertical" gap={2}>
          <div className="d-flex justify-content-between text-dark px-3">
            <span className="fs-sm">Order Created:</span>
            <span className="fs-sm fw-bolder text-brownsugar">
              {invoiceDetails?.created_at
                ? moment(invoiceDetails.created_at).format('DD MMM YYYY')
                : '-'}
            </span>
          </div>
          <div className="d-flex justify-content-between text-dark px-3">
            <span className="fs-sm">Due Date:</span>
            <span className="fs-sm fw-bolder text-brownsugar">
              {invoiceDetails?.selectedDate
                ? moment(invoiceDetails.selectedDate).format('DD MMM YYYY')
                : '-'}
            </span>
          </div>
          <InvoicePricing
            invoice={invoiceDetails}
            applyPromoCodeInputGroup={state === 'PENDING' ? applyPromoCodeInputGroup : undefined}
          />
          {invoiceDetails?.pricingDetail ? (
            <>
              <div className="py-0">
                <p className="fs-sm mb-0">{invoiceDetails?.pricingDetail}</p>
              </div>
            </>
          ) : null}
        </Stack>
      </div>
    </>
  );
};
