import { Client } from '@twilio/conversations';
import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';

import { ConversationDetailsFooter } from './ConversationDetailsFooter';
import { ConversationDetailsHeader } from './ConversationDetailsHeader';
import { ConversationsMessages } from './ConversationsMessages';
import { useReadMessagesOnExpandedConversation } from './hooks';
import { useMessages } from './hooks/useMessages';
import { useConversationProxy } from './useConversationProxy';
import { useFocus } from './useFocus';
import { MessagingState } from '../../../types';
import { useIsFullscreenMessagingAndInvoicing } from '../useIsFullscreenMessagingAndInvoicing';

export const ConversationDetails = ({
  messagingState,
  onClose,
  conversationsClient,
}: {
  messagingState: MessagingState;
  onClose: () => void;
  conversationsClient: Client | null;
}) => {
  const conversationProxy = useConversationProxy(
    conversationsClient,
    messagingState.selectedConversation?.twilioConversationSid
  );
  const [state, ref, scrollToBottom] = useMessages(
    conversationProxy,
    messagingState.selectedConversation?.conversationId
  );

  useReadMessagesOnExpandedConversation(
    messagingState.visibilityState === 'expanded',
    state.messages,
    messagingState.selectedConversation?.conversationId
  );
  const [inputRef, focusInput] = useFocus();
  useEffect(() => {
    if (messagingState.visibilityState === 'expanded' && state.messages.length) {
      scrollToBottom('auto');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    messagingState.selectedConversation?.conversationId,
    messagingState.visibilityState,
    state.messages,
  ]);
  const isFullscreen = useIsFullscreenMessagingAndInvoicing();
  useEffect(() => {
    if (messagingState.visibilityState === 'expanded' && !isFullscreen) {
      focusInput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagingState.selectedConversation?.conversationId, messagingState.visibilityState]);

  const sendMessage = async (message: string, files: File[] = []) => {
    if (!conversationProxy) {
      return;
    }

    const requests: Promise<number | null>[] = [];

    if (message) {
      requests.push(conversationProxy.sendMessage(message));
    }

    if (files.length > 0) {
      const messageBuilder = conversationProxy.prepareMessage();
      for (const file of files) {
        messageBuilder.addMedia({
          contentType: file.type,
          filename: file.name,
          media: file,
        });
      }
      requests.push(messageBuilder.build().send());
    }

    return Promise.all(requests);
  };

  return (
    <div className="h-100 d-flex flex-column">
      <div className="p-2 d-flex justify-content-between">
        <ConversationDetailsHeader
          otherUser={messagingState.selectedConversation?.otherUser}
          onClose={onClose}
          store={messagingState.selectedConversation?.storefrontJSON}
        />
      </div>
      <hr />
      <div ref={ref} className="p-2 flex-grow-1 overflow-auto">
        {state.loadingState === 'ready' ? (
          <ConversationsMessages
            messages={state.messages}
            otherUser={messagingState.selectedConversation?.otherUser}
            onLoad={() => scrollToBottom('auto')}
          />
        ) : (
          <div className="d-flex flex-grow-1 h-100 justify-content-center align-items-center">
            <Spinner animation="border" role="status" />
          </div>
        )}
      </div>
      <div className="p-2">
        <ConversationDetailsFooter
          hasAttachments={true}
          onSubmit={sendMessage}
          submitButtonClassNames="btn-icon"
          submitButtonText={<i className="fi-send text-primary" />}
          inputRef={inputRef}
          disabled={state.loadingState !== 'ready'}
          placeholder={`Message ${messagingState.selectedConversation?.otherUser?.firstName ?? ''}`}
        />
      </div>
    </div>
  );
};
