import PubSub from 'pubsub-js';
import { useEffect } from 'react';

import { EventName, trackEvent } from '@packages/event-tracking';

import { PayloadByEventName } from './../../event-tracking/types';

export const invoiceIconClickPubSub = {
  publish: (payload: Omit<PayloadByEventName[EventName.InvoiceClick], 'isWeb'>) =>
    PubSub.publish(EventName.InvoiceClick, { ...payload, isWeb: true }),

  subscribe: (fn: (payload: PayloadByEventName[EventName.InvoiceClick]) => void) =>
    PubSub.subscribe(EventName.InvoiceClick, (_message, payload) => fn(payload)),
};

export const useTrackInvoicingEvents = () => {
  useEffect(() => {
    const invoiceIconClickUnsubscribeToken = PubSub.subscribe(EventName.InvoiceIconClick, () => {
      trackEvent(EventName.InvoiceIconClick, { isWeb: true });
    });

    const invoiceClickUnsubscribeToken = invoiceIconClickPubSub.subscribe((payload) =>
      trackEvent(EventName.InvoiceClick, payload)
    );

    return () => {
      PubSub.unsubscribe(invoiceIconClickUnsubscribeToken);
      PubSub.unsubscribe(invoiceClickUnsubscribeToken);
    };
  }, []);
};
